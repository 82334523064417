import React, { useState } from "react";
import Layout from "../views";
import { CreditModal } from "../components/Credits";
import { CreditsBgImage } from "../images";
import { CreditCalc, CreditTerms } from "../components/Credits";
import { SelectCalc } from "../components/Select";

const Credits = ({ termCredits, termDeposit, main, ...props }) => {
  const [depositVal, setDepositVal] = useState({
    value: termDeposit[Object.keys(termDeposit)[0]].id,
    label: termDeposit[Object.keys(termDeposit)[0]].title,
  });
  const [activeTab, setTab] = useState("tab1");
  const [showCredit, setShowModal] = useState(null);
  const closeModal = () => setShowModal(null);
  const { title } = props;
  const [termPriceCredit, setTermPriceCredit] = useState({
    credit: termCredits[Object.keys(termDeposit)[0]].id,
    loan_amount: termCredits[Object.keys(termDeposit)[0]].min_credit,
    term: termCredits[Object.keys(termDeposit)[0]].term,
  });

  const showModal = (show) => setShowModal(show);
  const getTermPriceCredit = (vals) => setTermPriceCredit(vals);
  
  return (
    <Layout {...props} className={"main--bg"}>
      <section className="credits">
        <div className="container">
          <div className="credits-intro">
            <div className="credits-intro__left">
              <h1 className="credits-heading">{title}</h1>
              {main ? (
                <div className="credits-intro__row">
                  <div className="credits-intro__col">
                    <div className="credits-intro__value">до 600 000 ₽</div>
                    <div className="credits-intro__label">
                      Максимальная сумма займа
                    </div>
                  </div>
                  <div className="credits-intro__col">
                    <div className="credits-intro__value">
                      до 36 мес.
                    </div>
                    <div className="credits-intro__label">Срок кредита</div>
                  </div>
                  <div className="credits-intro__col">
                    <div className="credits-intro__value">
                      от 8.5%
                    </div>
                    <div className="credits-intro__label">
                      Процентная ставка
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="credits-intro__right">
              <img className="credits-intro__img" src={CreditsBgImage} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="credcalc">
        <div className="container">
          <div className="credcalc__tabs">
            <div
              onClick={() => setTab("tab1")}
              className={`tab ${activeTab === "tab1" ? "active" : ""}`}
            >
              ЗАЙМЫ
            </div>
            <div
              onClick={() => setTab("tab2")}
              className={`tab ${activeTab === "tab2" ? "active" : ""}`}
            >
              ВКЛАДЫ
            </div>
          </div>
          <div className="creditcalc__content">
            <div
              className={`tab-content ${activeTab === "tab1" ? "show" : ""}`}
            >
              <CreditCalc
                main={main}
                getTermPriceCredit={getTermPriceCredit}
                termCredits={termCredits}
                setShowModal={showModal}
              />
            </div>
            <div
              className={`tab-content ${activeTab === "tab2" ? "show" : ""}`}
            >
              <form className="credcalc-row">
                <div className="credcalc-form form">
                  <div className="form-group">
                    <div className="form-label">Программа вклада</div>
                    <SelectCalc
                      onChange={(value) => setDepositVal(value)}
                      data={termDeposit}
                      value={depositVal}
                    />
                  </div>
                </div>
              </form>
              <CreditTerms
                main={main}
                data={termDeposit}
                selectVal={depositVal.value}
                link={termDeposit[depositVal.value].file}
                showModal={() => setShowModal(true)}
                isSecondTab={true}
                title=""
              />
            </div>
          </div>
        </div>
      </section>
      <CreditModal
        termPriceCredit={termPriceCredit}
        isShow={showCredit !== null}
        handleClose={closeModal}
      />
    </Layout>
  );
};

export default Credits;
