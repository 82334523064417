import React from "react";
import { Formik } from "formik";
import { useStoreon } from "storeon/react";
import {
  Button,
  Form,
  ClearField,
  PasswordField,
  OverlayTrigger,
  Badge,
  Tooltip
} from "@garpix/component-system";

import Errors from "../../views/Errors";
import { PhoneField, EmailField } from "../../views/Fields";
import { SelectDistrict } from "../../components/Select";
import { PartnershipApplicationSchema } from "../../schema";

import api from "../../api";
import { Captcha } from "../Captcha";

const PartnerRegistration = () => {
  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  const { dispatch, page } = useStoreon("page");
  const { site_configuration } = page.data.page;
  const { policy_slug } = site_configuration;
  const sendRequest = (params, { setSubmitting, setErrors }) => {
    const newParams = { ...params, district: params.district.value };

    api
      .createPartnershipApplication(newParams)
      .then(res => {
        dispatch("modal/toggle", { modal: "thank" });
      })
      .catch(error => {
        if (!error.response) return false;
        const { data } = error.response;
        setSubmitting(false);
        setErrors(data);
      });
  };

  return (
    <Formik
      initialValues={{
        fio: "",
        name: "",
        phone: "",
        email: "",
        district: "",
        about: "",
        recaptcha: "",
        password: "",
        password2: "",
        partner_farm: false,
        is_policies: true
      }}
      validationSchema={PartnershipApplicationSchema}
      onSubmit={sendRequest}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue
      }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate className="form">
            <div className="form-group">
              <ClearField
                label="ФИО руководителя *"
                name="fio"
                onChange={setFieldValue}
                value={values.fio}
              />
              {errors.fio && touched.fio ? (
                <Errors errors={errors.fio} />
              ) : null}
            </div>
            <div className="form-group">
              <ClearField
                label="Наименование компании *"
                name="name"
                onChange={setFieldValue}
                value={values.name}
              />
              {errors.name && touched.name ? (
                <Errors errors={errors.name} />
              ) : null}
            </div>
            <div className="form-group">
              <PhoneField
                label="Номер телефона компании *"
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="form-group">
              <EmailField
                label="Email компании *"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="form-group form-group--top">
              <SelectDistrict
                name="disctrict"
                placeholder="Выберите муниципальное образование"
                value={values.district}
                onChange={value => {
                  setFieldValue("district", value);
                }}
              />
              {errors.district && touched.district ? (
                <Errors errors={errors.district} />
              ) : null}
            </div>

            <div className="form-group">
              <ClearField
                label="Краткая информация о компании"
                name="about"
                onChange={setFieldValue}
                value={values.about}
              />
              {errors.about && touched.about ? (
                <Errors errors={errors.about} />
              ) : null}
            </div>
            <div className="form-group">
              <PasswordField
                label="Введите пароль"
                autoComplete="off"
                name="password"
                onChange={handleChange}
                value={values.password}
              />
              {errors.password && touched.password ? (
                <Errors errors={errors.password} />
              ) : null}
            </div>

            <div className="form-group">
              <PasswordField
                label="Повторите пароль"
                autoComplete="off"
                name="password2"
                onChange={handleChange}
                value={values.password2}
              />
              {errors.password2 && touched.password2 ? (
                <Errors errors={errors.password2} />
              ) : null}
            </div>
            <Captcha
              name={"partner"}
              handleValue={value => {
                setFieldValue("recaptcha", value);
              }}
            />
            <div className="form-end">
              <div className="form-group">
                <div className="form-checkbox">
                  <Form.Check
                    custom
                    inline
                    type={"checkbox"}
                    id={`partner_farm`}
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      name="partner_farm"
                      value={values.partner_farm}
                      checked={values.partner_farm}
                      onChange={handleChange}
                    />
                    <Form.Check.Label>
                      <span>Являюсь фермером</span>
                    </Form.Check.Label>
                  </Form.Check>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        При выборе этого параметра ваш аккаунт будет определён
                        как фермерский, и ваши товары будут размещаться ТОЛЬКО в
                        разделе фермерские хозяйства
                      </Tooltip>
                    }
                  >
                    <Badge pill variant="primary">
                      ?
                    </Badge>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="form-group">
                <div className="form-checkbox">
                  <Form.Check custom inline type={"checkbox"} id={`policies`}>
                    <Form.Check.Input
                      type={"checkbox"}
                      name="is_policies"
                      value={values.is_policies}
                      checked={values.is_policies}
                      onChange={handleChange}
                    />
                    <Form.Check.Label>
                      <span>
                        Согласен с условиями{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={policy_slug}
                        >
                          политики конфиденциальности
                        </a>
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
                {errors.is_policies && touched.is_policies ? (
                  <Errors errors={errors.is_policies} />
                ) : null}
              </div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  type={"submit"}
                  className="btn--full"
                >
                  Подать заявку
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PartnerRegistration;
