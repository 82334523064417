import React from 'react';
import { Form } from '@garpix/component-system';
import Errors from '../Errors';
import { PhoneInput } from './PhoneInput';


const PhoneField = React.memo(({ error, touched, value, setFieldValue, name = "phone", label = "", htmlFor = '' }) => {
  const handleClear = () => setFieldValue(name, '');
  return (

    <>
      <div className="inputlabel">
        <Form.Label htmlFor={htmlFor} className='inputlabel__label'>{label}</Form.Label>
        <div className="inputlabel__wrapper">
          <PhoneInput
            className="inputlabel__input form-input"
            value={value}
            setFieldValue={setFieldValue}
            name={name}
          />
          <button
            type="button"
            className={`inputlabel__clear ${value ? 'show' : ''}`}
            onClick={handleClear}
          ></button>
        </div>
      </div>
      {error && touched ? (
        <Errors errors={error} />
      ) : null}
    </>
  )
})

export {
  PhoneField
}