import React from 'react';
import { TitleSection } from '../TitleSection';
import { CategoryBtnMore } from './CategoryBtnMore';
import { CategoryList } from './CategoryList';
import { CategoryProductList } from './CategoryProductList';
import './category_section.scss';

const CategorySection = ({ data, cat_url, categoryList,  title, limit, md }) => {
    return (
        <>
            <TitleSection cat_url={cat_url} title={title} />
            {categoryList && <CategoryList cat_url={cat_url} data={categoryList} />}
            <CategoryProductList data={data} limit={limit} md={md} />
            <CategoryBtnMore cat_url={cat_url} />
        </>
    )
}

export { CategorySection };

