import siteConfigurationSerializer from "./site_configuration";
import blogSerializer from './blog';
import breadcrumbsSerializer from "./breadcrumbs";


const postPageSerializer = page => {
  const { init_state } = page
  const { page_info, read_also } = init_state;
  return {
    ...init_state,
    ...page_info,
    read_also: read_also.map(blogSerializer),
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(page_info)
  }
}

export default postPageSerializer;
