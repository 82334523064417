import React from 'react';
import LogoImage from './logo_eco.svg';


const LogoEco = React.memo(() => {
  return (
    <img width="130" height="43" src={LogoImage} alt={"Логотип"} />
  )
})

export default LogoEco;