import React from "react";
import Layout from "../views";
import ServiceList from "../components/ServiceList";
import emptyImg from "../images/empty.png";
import api from "../api";

const ServicesParther = ({
  content,
  image_thumb,
  partner_site,
  email,
  address,
  offers,
  ...props
}) => {
  const { title, phone } = props;
  const imageSrc = image_thumb ? image_thumb : emptyImg;

  return (
    <Layout {...props}>
      <section className="catalog">
        <div className="container">
          <div className="manuf">
            <div className="manuf-imgwrap">
              <img alt="" className="manuf-img" src={imageSrc} />
            </div>
            <div className="manuf-content">
              <h1 className="manuf-name">
                {partner_site ? (
                  <a
                    href={partner_site}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </a>
                ) : (
                  `${title}`
                )}
              </h1>
              <div
                className="manuf-desc"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className="manuf-row">
                <div className="manuf-addr">
                  <div className="manuf-label">Адрес</div>
                  <div className="manuf-value">{address}</div>
                </div>
              </div>
              <div className="manuf-group">
                <div className="manuf-col10">
                  <div className="manuf-label">Контакты</div>
                  <div className="manuf-value">
                    <div className="manuf-row">
                      {partner_site ? (
                        <div className="manuf-cont">
                          <div className="manuf-value">
                            <a
                              href={partner_site}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {partner_site}
                            </a>
                          </div>
                        </div>
                      ) : null}
                      {email ? (
                        <div className="manuf-cont">
                          <div className="manuf-value">{email}</div>
                        </div>
                      ) : null}

                      <div className="manuf-cont">
                        <div className="manuf-value">{phone}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {offers && offers.length > 0 ? (
        <ServiceList
          filterObj={{
            partner: props.id
          }}
          data={offers}
          isAddress={false}
          title={"Предложения партнера"}
          api={api.getOffers}
        />
      ) : null}
    </Layout>
  );
};

export default ServicesParther;
