import React from "react";
import CKEditor from 'ckeditor4-react';
import { useStoreon } from 'storeon/react';
import { Form, Button } from "@garpix/component-system";
import { Formik } from "formik";
import ImageUpload from "../../views/ImageUpload";
import Errors from "../../views/Errors";
import {
  SelectGroup,
  SelectTypes,
} from '../Select';
import { ProductSchema } from '../../schema';
import { CK_EDITOR_CONFIG, RESPONSE_STATUS } from '../../const';
import api from "../../api";

const AddOrUpdateProduct = ({
  createCallback = () => { },
  updateCallback = () => { },
  cancelCallback = () => { },
  product = {
    title: '',
    price: '',
    type_obj: null,
    type: null,
    group: null,
    group_obj: null,
    image: '',
    unit: '',
    content: '',
  },
  producer,
}) => {
  const { page } = useStoreon('page');
  const current_page = page.data.page;
  const { help_slug } = current_page.site_configuration;

  const handleSubmitErrors = ({ error, setErrors, setSubmitting }) => {
    setSubmitting(false);
    if (error.response) {
      const status = error.response.status;
      if (status === RESPONSE_STATUS.error404 || status === RESPONSE_STATUS.error500) {
        setErrors({ "submit": "На сервере произошла ошибка. Пожалуйста, попробуйте позже." })
      } else {
        setErrors(error.response.data)
      }
    }
  }

  const handleSubmit = (params, { setSubmitting, setErrors }) => {
    const { id } = product;
    if (id) {
      api.updateProduct(id, params)
        .then(res => {
          setSubmitting(false);
          updateCallback(res);
        })
        .catch(error => {
          handleSubmitErrors({ error, setErrors, setSubmitting })
        })
    } else {
      api.createProduct(params)
        .then(res => {
          setSubmitting(false);
          createCallback();
        })
        .catch(error => {
          handleSubmitErrors({ error, setErrors, setSubmitting })
        })
    }
  }

  return (
    <Formik
      validationSchema={ProductSchema}
      onSubmit={handleSubmit}
      initialValues={{
        title: product.title,
        price: product.price,
        image: product.image_url,
        // image_list: '',
        unit: product.unit,
        content: product.content,
        group: product.group_obj ? {
          value: product.group_obj.id,
          label: product.group_obj.title
        } : undefined,
        type: product.type_obj ? {
          value: product.type_obj.id,
          label: product.type_obj.title
        } : undefined,
        producer: producer,
        view_product: 0
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        errors,
        setFieldValue
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="addprod">
            <div className="addprod-row">
              <div className="addprod-col12">
                <div className="addprod-help">
                  <a
                    className={`tabs__link tabs__link_help`}
                    href={`/${help_slug}`}
                    target='_blank' rel="noopener noreferrer">Помощь</a>
                </div>
              </div>
            </div>
            <div className="addprod-row">
              <div className="addprod-left">
                <div className="form-group">
                  <div className="form__label">Название продукта</div>
                  <input
                    className="form-input"
                    type="text"
                    onChange={handleChange}
                    value={values.title}
                    name={"title"}
                  />
                  {errors.title && touched.title ? (
                    <Errors errors={errors.title} />
                  ) : null}
                </div>


                <div className="form-row">
                  <div className="form-col">
                    <div className="form-group">
                      <div className="form__label">Группа</div>
                      <SelectGroup
                        onChange={value => {
                          setFieldValue('group', value)
                          setFieldValue('type', null)
                        }}
                        value={values.group}
                        name="group"
                      />
                      {errors.group && touched.group ? (
                        <Errors errors={errors.group} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <div className="form__label">Тип</div>
                      <SelectTypes
                        onChange={value => setFieldValue('type', value)}
                        value={values.type}
                        name="type"
                        isDisabled={!values.group}
                        cacheUniqs={[values.group]}
                        filters={values.group ? {
                          group: values.group.value
                        } : {}}
                      />
                      {errors.type && touched.type ? (
                        <Errors errors={errors.type} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-col">
                    <div className="form-group">
                      <div className="form__label">Стоимость (руб)</div>
                      <input
                        className="form-input"
                        type="number"
                        onChange={handleChange}
                        value={values.price}
                        name={"price"}
                      />
                      {errors.price && touched.price ? (
                        <Errors errors={errors.price} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <div className="form__label">Цена за (вес, кол-во, объем)</div>
                      <input
                        className="form-input"
                        type="text"
                        onChange={handleChange}
                        value={values.unit}
                        name={"unit"}
                      />
                      {errors.unit && touched.unit ? (
                        <Errors errors={errors.unit} />
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
              <div className="addprod-right">
                <ImageUpload setFieldValue={(file) => setFieldValue("image", file)}>
                  {({
                    preview,
                    onSelectFile,
                    selectedFile,
                    isDragActive,
                    getRootProps
                  }) => (
                    <div {...getRootProps()} className="addprod-image">
                      <div className="addprod-image__wrap">
                        {selectedFile || (product && product.image_thumb) ? (
                          <div className={"addprod-image__img"} style={{ backgroundImage: `url(${selectedFile ? preview : product.image_thumb})` }} />
                        ) : null}
                        {selectedFile || (product && product.image_thumb) ? <></> : (
                          <div className="addprod-image__filetext"><div>Файл не выбран</div></div>
                        )}
                        <label
                          className="addprod-image__label"
                          htmlFor='image'>
                          {selectedFile || (product && product.image_thumb) ? 'Изменить файл' : 'Добавить файл'}
                        </label>
                        <input
                          id='image'
                          className="addprod-image__file"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          // accept="image/*"
                          name={"image"} onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue("image", file);
                            onSelectFile(file)
                          }} />
                          <div className="pd-2 mb-3 text-center">
                            <Errors errors={errors.image} />
                          </div>
                      </div>
                    </div>
                  )}
                </ImageUpload>
              </div>
            </div>
            <div className="addprod-row">
              <div className="addprod-col12">
                <div className="form-group">
                  <div className="form__label">Описание</div>
                  <CKEditor
                    data={values.content}
                    onChange={e => setFieldValue('content', e.editor.getData())}
                    config={CK_EDITOR_CONFIG}
                  />
                  {errors.content && touched.content ? (
                    <Errors errors={errors.content} />
                  ) : null}
                </div>
              </div>
            </div>
            {errors.submit ? (
              <Errors errors={errors.submit} />
            ) : null}
            <div className="addprod-btm">
              <Button type={"button"} onClick={cancelCallback} className="addprod-cancel btn--gray">Отмена</Button>
              <Button type={"submit"} disabled={isSubmitting} className="addprod-save">Сохранить</Button>
            </div>
          </div>
        </Form>
      )
      }
    </Formik >
  )
}

export default AddOrUpdateProduct;
