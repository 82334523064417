import siteConfigurationSerializer from "./site_configuration";
import breadcrumbsSerializer from "./breadcrumbs";
import {normalize} from "../../utils"

const creditSerializer = (credit) => {
  const { init_state } = credit;
  const { term_credit, term_deposit } = init_state;
  const { page_info } = init_state;
  const pageInfo = page_info;
  const termCredits = normalize(term_credit)
  const termDeposit = normalize(term_deposit)
  
  return {
    ...init_state,
    termCredits,
    termDeposit,
    ...pageInfo,
    site_configuration: siteConfigurationSerializer(
      init_state.site_configuration
    ),
    breadcrumbs: breadcrumbsSerializer(page_info),
  };
};

export default creditSerializer;
