import { createStoreon } from "storeon";
import { persistState } from "@storeon/localstorage";
import { storeonDevtools } from "storeon/devtools";
import { page } from "@garpix/fetcher";
import api from "../api";

const cart = store => {
  store.on("@init", () => ({ cart: {} }));
  store.on("cart/update", ({ cart }, obj) => {
    const { product } = obj;
    const { id } = product;
    const newCart = { ...cart };
    newCart[id] = obj;
    return {
      cart: { ...newCart }
    };
  });
  store.on("cart/update-items", ({ cart }, obj) => {
    const newCart = { ...cart };
    Object.keys(obj).map(key => {
      newCart[key].product = { ...newCart[key].product, ...obj[key] };
    });

    return {
      cart: {
        ...newCart
      }
    };
  });
  store.on("cart/remove", ({ cart }, { id }) => {
    const newCart = { ...cart };
    delete newCart[id];
    return {
      cart: { ...newCart }
    };
  });
  store.on("cart/removeList", ({ cart }, { ids }) => {
    const newCart = { ...cart };
    ids.forEach(id => delete newCart[id]);
    return {
      cart: { ...newCart }
    };
  });
  store.on("cart/clear", () => ({ cart: {} }));
};

const wishlist = store => {
  store.on("@init", () => ({ wishlist: {} }));
  store.on("wishlist/update", ({ wishlist }, obj) => {
    const { product } = obj;
    const { id } = product;
    const newWishlist = { ...wishlist };
    newWishlist[id] = obj;
    return {
      wishlist: { ...newWishlist }
    };
  });
  store.on("wishlist/remove", ({ wishlist }, { id }) => {
    const newWishlist = { ...wishlist };
    delete newWishlist[id];
    return {
      wishlist: { ...newWishlist }
    };
  });
};

const user = store => {
  store.on("@init", () => ({ currentUser: null }));
  store.on("user/save", ({ currentUser }, user) => ({
    currentUser: { ...currentUser, ...user }
  }));
  store.on("user/get", async ({}, { callback } = {}) => {
    try {
      const token = api.getAuthToken();
      if (token === null) {
        throw new Error();
      }
      const data = await api.getCurrentUser();
      store.dispatch("user/save", {
        ...data,
        status: true
      });
      if (callback) callback();
    } catch (e) {
      store.dispatch("user/save", { status: false });
      if (callback) callback();
      // setTimeout(() => {
      //   store.dispatch('modal/toggle', { modal: 'login' });
      // }, 300)
    }
  });
};

const modal = store => {
  store.on("@init", () => ({ showModal: null }));
  store.on("modal/toggle", ({}, { modal, ...params }) => ({
    showModal: modal,
    ...params
  }));
  store.on("modal/close", () => ({ showModal: null }));
};

const createShop = store => {
  store.on("@init", () => ({ isCreateShop: false }));
  store.on("shop/toggle", ({}, { isCreateShop, shop }) => ({
    isCreateShop,
    shop
  }));
};

console.log(process.env.NODE_ENV, "NODE_ENV");

const storeonParams = [user, cart, wishlist, modal, page, createShop];

console.log(process.env.REACT_APP_SERVER);

if (process.env.REACT_APP_SERVER === undefined) {
  storeonParams.push(
    persistState(["cart", "wishlist"], { key: "__gxprofsoyuz" })
  );
}

if (process.env.NODE_ENV === "development") {
  storeonParams.push(storeonDevtools);
}

export const store = createStoreon(storeonParams);
