import React from "react";
import { Modal } from "@garpix/component-system";
import ImageDelete from "../Account/ImageDelete";

const ImageShopDelete = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Удалить файл</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageDelete />
      </Modal.Body>
    </>
  );
};

export default ImageShopDelete;
