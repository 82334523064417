import React, { useState } from "react";
import { FetcherList, dataStates } from "@garpix/fetcher";
import { Button, Container } from "@garpix/component-system";
import { preventDefault } from "../../utils";
import { Link } from "../../views/Link";
import SelectDistrict from "../Select/SelectDistrict";

const List = ({
  data = [],
  title = "",
  api,
  filterObj,
  isDistrict = false,
  isAddress = true
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [district, setDistrict] = useState(null);

  return (
    <section className="servlist servlist--bgwhite">
      <Container>
        <h2 className="servlist-heading">{title}</h2>
        <div className="btabs">
          {/* На данный момент подкатегории не актуальны */}
          {false ? (
            <div className="btabs__wrap">
              <ul className="btabs__list nav">
                {data.map((item, index) => {
                  return (
                    <li key={item.id} className="btabs__item">
                      <a
                        onClick={preventDefault(() => {
                          setActiveTab(index);
                          setDistrict(null);
                        })}
                        className={`btabs__link ${index === activeTab ? "active show" : ""
                          }`}
                        href={`#tb-${item.id}`}
                        role="tab"
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          <div className="btabs__content">
            {data.map((item, index) => {
              const { id, relatedData } = item;
              return (
                <FetcherList
                  key={id}
                  api={api}
                  isHistoryPush={false}
                  isScrollTop={false}
                  enableReinitialize={true}
                  initData={relatedData}
                  initFilter={{
                    category: id,
                    // obj: obj.id,
                    ...filterObj,
                    district:
                      district && index === activeTab
                        ? district.value
                        : undefined
                  }}
                >
                  {({ count, results = [], showMore, isNext, status }) => {
                    return (
                      <div
                        className={`btabs__pane ${index === activeTab ? "show active" : ""
                          }`}
                        id={`tb-${id}`}
                        role="tabpanel"
                      >
                        <div className="table-wrap">
                          <div className="table-list__wrap">
                            {isDistrict ? (
                              <div className={"table-wrap__filter"}>
                                <SelectDistrict
                                  value={district}
                                  placeholder="Выберите муниципальное образование"
                                  name="district"
                                  onChange={value => {
                                    setDistrict(value);
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                          {results.length === 0 &&
                            status === dataStates.loaded ? (
                            <p>Список пуст</p>
                          ) : null}
                          {results.length > 0 ? (
                            <table className="table servlist-table">
                              <tbody>
                                <tr>
                                  <th>
                                    <span className="servlist-table__tname">
                                      Наименование
                                    </span>
                                  </th>
                                  <th>
                                    {isAddress ? (
                                      <span className="servlist-table__taddr">
                                        Адрес
                                      </span>
                                    ) : (
                                      <span className="servlist-table__tinfo">
                                        Описание
                                      </span>
                                    )}
                                  </th>
                                  <th>
                                    <span className="servlist-table__tsale">
                                      Скидка
                                    </span>
                                  </th>
                                </tr>
                                {results.map(element => {
                                  return (
                                    <tr key={element.id}>
                                      <td>
                                        {element.slug ? (
                                          <Link
                                            target_blank={element.target_blank}
                                            to={element.slug}
                                            className="servlist-table__name"
                                          >
                                            {element.title}
                                          </Link>
                                        ) : (
                                          <span className="servlist-table__name">
                                            {element.title}
                                          </span>
                                        )}
                                      </td>
                                      {isAddress ? (
                                        <td>{element.address}</td>
                                      ) : (
                                        <td
                                          dangerouslySetInnerHTML={{
                                            __html: element.description
                                          }}
                                        ></td>
                                      )}
                                      <td>
                                        <strong>{element.value}</strong>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : null}
                        </div>
                        {isNext ? (
                          <Button onClick={showMore} className="btn loadmore">
                            <span>ПОКАЗАТЬ ЕЩЁ {count - results.length}</span>
                          </Button>
                        ) : null}
                      </div>
                    );
                  }}
                </FetcherList>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default List;
