import React from 'react';
import {
  Accordion,
  Card,
  Container,
} from '@garpix/component-system';

import selectIcon from '../../images/select.svg';

const HelpList = ({ data = [] }) => {

  return (
    <Container>
      <h1 className='services-heading'>Часто задаваемые вопросы</h1>
      <div>
        {data.map(item => {
          const question_answer = item.question_answer;
          return (
            <div key={`section-${item.id}`}>
              <h2 className='product-heading'>{item.title}</h2>
              <Accordion>
                {question_answer.map((elem, idx) => {
                  return (
                    <Card key={`question-${elem.id}`}>
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey={elem.id}>
                        {elem.question}
                        <img
                          width={15}
                          height={15}
                          className='accordion-icon'
                          src={selectIcon} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={elem.id}>
                        <Card.Body>{elem.answer}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default HelpList;