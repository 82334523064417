import React from 'react';
import { Container, Button } from '@garpix/component-system';
import { FetcherList } from '@garpix/fetcher';
import Paginate from '../views/Paginate';
import Layout from '../views';
import Post from '../views/Blog';
import api from '../api';


const NewsPage = ({ blog_posts, ...props }) => {
  const { title } = props;
  return (
    <Layout {...props}>
      <FetcherList
        api={api.getNews}
        initData={blog_posts}
      >
        {({
          count,
          results = [],
          activePage,
          loadData,
          isNext,
          showMore
        }) => (
            <section className="news">
              <Container>
                <h2 className="news-heading">{title}</h2>
                <div className="news-items">
                  {results.map(item => <Post key={item.id} {...item} />)}
                </div>
                <div className="news-bottom">
                  {isNext ? (
                    <Button onClick={showMore} className="btn loadmore">
                      <span>ПОКАЗАТЬ ЕЩЁ</span>
                    </Button>
                  ) : null}
                  <Paginate count={count} activePage={activePage} />
                </div>
              </Container>
            </section>
          )}
      </FetcherList>
    </Layout>
  )
}

export default NewsPage;