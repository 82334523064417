import React from "react";
import { FetcherList, dataStates } from "@garpix/fetcher";
import { Button } from "@garpix/component-system";
import Paginate from "../../views/Paginate";
import Product from "../Product";

import api from "../../api";

const ShopGoodsAndServices = ({ shop_id }) => {
  return (
    <FetcherList
      api={api.getShopProducts}
      initFilter={{
        shop_id: shop_id
      }}
    >
      {({
        count,
        results = [],
        activePage,
        showMore,
        status,
        isNext,
        filterParams
      }) => {
        return (
          <div className="catalog-items">
            {status === dataStates.loaded && results.length > 0 ? (
              <>
                {results.map(item => (
                  <Product key={item.id} product={item} size="catalog" />
                ))}
                <div className={"catalog-bottom"}>
                  {isNext ? (
                    <Button onClick={showMore} className="btn loadmore">
                      <span>ПОКАЗАТЬ ЕЩЁ</span>
                    </Button>
                  ) : null}
                  <Paginate
                    count={count}
                    activePage={activePage}
                    params={filterParams}
                  />
                </div>
              </>
            ) : null}
            {status === dataStates.loaded && results.length === 0 ? (
              <div className="catalog-item">Пока нет товаров и услуг.</div>
            ) : null}
          </div>
        );
      }}
    </FetcherList>
  );
};

export default ShopGoodsAndServices;
