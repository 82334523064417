import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { dataStates } from "@garpix/fetcher";

import { Modal, Button } from "@garpix/component-system";
import ServiceTable from "../../views/ServiceTable";
import AddOrUpdateService from "../Account/AddOrUpdateService";
import PaginateLk from "../../views/Paginate/PaginateLk";

import api from "../../api";

const EditorServices = ({
  results,
  status,
  loadData,
  deleteElement,
  updateElement,
  isNext,
  showMore,
  count,
  filterParams,
  activePage
}) => {
  const { currentUser } = useStoreon("currentUser");
  const [producer, setProducer] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [selectObj, setSelectObj] = useState(undefined);

  const handleClose = () => setShowModal(null);
  useEffect(() => {
    if (currentUser && currentUser.producer) {
      setProducer(currentUser.producer);
    }
  }, []);

  const showAddProduct = () => {
    setSelectObj(undefined);
    setShowModal("add-product");
  };

  const showDeletedModal = (index, id) => {
    setSelectObj({ index, id });
    setShowModal("delete-product");
  };

  const showEditModal = (index, product) => {
    setShowModal("update-product");
    setSelectObj({ index, ...product });
  };

  const createCallback = () => {
    handleClose();
    loadData(1, {});
  };

  const updateCallback = params => {
    const { index } = selectObj;
    updateElement(index, params);
    handleClose();
  };

  const handleDeleted = () => {
    const { index, id } = selectObj;
    deleteElement(index);
    api.deleteProduct(id);
    handleClose();
  };
  return (
    <>
      <div className="table-wrap">
        {results.length === 0 && status === dataStates.loaded ? (
          <p>Список пуст</p>
        ) : null}

        <ServiceTable
          status={status}
          showDeletedModal={showDeletedModal}
          showEditModal={showEditModal}
          showAddProduct={showAddProduct}
          results={results}
        />
      </div>
      {/* {isNext ? (
        <div className="catalog-bottom">
          <Button className="btn loadmore" onClick={showMore}><span>ПОКАЗАТЬ ЕЩЁ</span></Button>
        </div>
      ) : null} */}
      <PaginateLk
        loadData={loadData}
        limit={6}
        count={count}
        activePage={activePage}
        params={filterParams}
      />
      {showModal === "add-product" ? (
        <Modal
          centered={true}
          size={"xl"}
          show={!!showModal}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление товара или услуги</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddOrUpdateService
              cancelCallback={handleClose}
              createCallback={createCallback}
              producer={producer.id}
              product={selectObj}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {showModal === "update-product" ? (
        <Modal
          centered={true}
          size={"xl"}
          show={!!showModal}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Редактирование товара или услуги</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddOrUpdateService
              cancelCallback={handleClose}
              updateCallback={updateCallback}
              producer={producer.id}
              product={selectObj}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {showModal === "delete-product" ? (
        <Modal
          centered={true}
          size={"sm"}
          show={!!showModal}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление товара или услуги</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"modal-center"}>
              <div className={"modal-text"}>
                Вы точно хотите удалить услугу/товар?
              </div>
              <div className={"modal-end"}>
                <div className={"modal-btns"}>
                  <Button onClick={handleClose} className={"btn--gray"}>
                    Отменить
                  </Button>
                  <Button onClick={handleDeleted} className={"btn--red"}>
                    Удалить
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default EditorServices;
