import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  VKShareButton,
  TelegramShareButton
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { fb, tg, vk, cp } from "../../images";

import { SHARE_URL } from "../../api";
import { preventDefault } from "../../utils";

const ShopShare = () => {
  const [isCopied, setCopied] = useState(false);
  const location = useLocation();

  const copyLink = () => setCopied(true);

  useEffect(() => {
    let timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
    } else clearTimeout(timeout);
  }, [isCopied]);

  return (
    <div className="shopage-share">
      <div className="shopage-share__wrap">
        <div className="shopage-share__label">Поделиться:</div>

        <CopyToClipboard
          text={`${SHARE_URL}${location.pathname}`}
          onCopy={copyLink}
        >
          <a
            href="#"
            onClick={preventDefault(() => {})}
            className="shopage-share__item"
          >
            <img
              src={cp}
              className="shopage-gallery__img"
              alt=""
              title="Копировать ссылку"
            />
          </a>
        </CopyToClipboard>

        <VKShareButton
          className="shopage-share__item"
          url={`${SHARE_URL}${location.pathname}`}
        >
          <img
            src={vk}
            className="shopage-gallery__img"
            alt="Поделиться во Vkontakte"
            title="Поделиться во Vkontakte"
          />
        </VKShareButton>
        <FacebookShareButton
          className="shopage-share__item"
          url={`${SHARE_URL}${location.pathname}`}
        >
          <img
            src={fb}
            className="shopage-gallery__img"
            alt="Поделиться в Facebook"
            title="Поделиться в Facebook"
          />
        </FacebookShareButton>
        <TelegramShareButton
          className="shopage-share__item"
          url={`${SHARE_URL}${location.pathname}`}
        >
          <img
            src={tg}
            className="shopage-gallery__img"
            alt="Поделиться в Telegram"
            title="Поделиться в Telegram"
          />
        </TelegramShareButton>
      </div>
      {isCopied ? (
        <div className="shopage-share__save-link">
          Ссылка успешно скопирована
        </div>
      ) : null}
    </div>
  );
};

export default ShopShare;
