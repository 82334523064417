import React from "react";
import {Link} from "react-router-dom";


const BigBlock = ({ data = [], title = '' }) => {
  return (
    <section className="servblocks">
      <div className="container">
        <h2 className="servblocks-heading">{title}</h2>
        <div className="servblocks-items">
          {data.map(cat => {
            return cat.relatedData.results.map(item => {
              return (
                <Link key={item.id} to={item.slug} className="servblocks-item">
                  <div className="servblocks-item__imgwrap">
                    <img className="servblocks-item__img" src={item.image_thumb} alt="" />
                  </div>
                  <div className="servblocks-item__name">{item.title}</div>
                  <div className="servblocks-item__text" dangerouslySetInnerHTML={{ __html: item.description }} />
                </Link>
              )
            })
          })}
        </div>
      </div>
    </section>
  )
}

export default BigBlock;