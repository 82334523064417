import BaseApi from "@garpix/base-api";
import { setCookie, getCookie, removeCookie } from "../utils";
import dayjs from "../utils/dayjs";
import {
  mainPageSerializer,
  catalogPageSerializer,
  productPageSerializer,
  productSerializer,
  pageSerializer,
  accountSerializer,
  userSerializer,
  servicesSerializer,
  partnerSerializer,
  postPageSerializer,
  newsPageSerializer,
  blogSerializer,
  offerPartnerSerializer,
  partnerServiceSerializer,
  creditSerializer,
  servicesListSerializer,
  categoryPageSerializer,
  shopSerializer,
} from "./serializers";

if (process.env.NODE_ENV !== "server") {
  import("axios-progress-bar").then(({ loadProgressBar }) => {
    loadProgressBar();
  });
}

const PAGE_SIZE = 15;

const PAGES = {
  1: { serializer: mainPageSerializer }, // главная
  2: { serializer: pageSerializer }, // Категория продукта
  3: { serializer: productPageSerializer }, // продукт
  4: { serializer: catalogPageSerializer }, // Каталог
  5: { serializer: pageSerializer },
  6: { serializer: pageSerializer },
  7: { serializer: accountSerializer }, // ЛК
  8: { serializer: pageSerializer }, // корзина
  9: { serializer: pageSerializer },
  10: { serializer: pageSerializer },
  11: { serializer: pageSerializer },
  12: { serializer: pageSerializer },
  13: { serializer: pageSerializer },
  14: { serializer: creditSerializer },
  15: { serializer: pageSerializer },
  16: { serializer: newsPageSerializer },
  17: { serializer: postPageSerializer }, // Пост блога/новость
  18: { serializer: pageSerializer },
  19: { serializer: pageSerializer },
  20: { serializer: servicesListSerializer }, // список услуг
  21: { serializer: servicesSerializer }, // услуга
  22: { serializer: partnerSerializer }, // парнер
  23: { serializer: pageSerializer }, // LP парнера
  24: { serializer: shopSerializer }, // shop-detail
  25: { serializer: pageSerializer },
  26: { serializer: categoryPageSerializer },
  27: { serializer: categoryPageSerializer },  
};

class Api extends BaseApi {
  AUTH_TOKEN_KEY = "auth_token";

  getLanguage = () => {
    return "ru";
  };

  axiosOverride = axios => {
    const Token = this.getAuthToken();
    const language = this.getLanguage();
    axios.defaults.headers.common["Authorization"] = `Token ${Token}`;
    axios.defaults.headers.common["Accept-Language"] = language;
    return axios;
  };

  getAuthToken = () => {
    return getCookie(this.AUTH_TOKEN_KEY);
  };

  setAuthToken = token => {
    setCookie(this.AUTH_TOKEN_KEY, token, 365);
    return true;
  };

  removeAuthToken = () => {
    try {
      removeCookie(this.AUTH_TOKEN_KEY);
      return true;
    } catch {
      return false; // no key
    }
  };

  getPage = async (params, queryParams = {}, axiosParams = {}) => {
    const slug = params[0];
    const res = await this.get(
      `/content/page${slug === "" ? "/" : slug}`,
      queryParams,
      axiosParams
    );
    const page = res.data;
    const pageType = page.type;
    const { serializer } = PAGES[pageType];
    return { pageType, page: serializer(page) };
  };

  sendConfirm = async ({ key }) => {
    const res = await this.post("/confirm/", { activation_key: key });
    return res.data;
  };

  login = async params => {
    const res = await this.post("/auth/", params);
    const data = res.data;
    this.setAuthToken(data.token);
    return data;
  };

  logout = async () => {
    const res = await this.post("/user/logout/");
    this.removeAuthToken();
    return res;
  };

  // getCaptcha = async () => {
  //   const res = await this.post('/captcha/')
  //   return res.data;
  // }

  emailChange = async params => {
    const res = await this.post("/user/change_email/", params);
    return res.data;
  };

  resendConfirmEmail = async () => {
    const res = await this.post("/user/resend_confirm_email/");
    return res.data;
  };

  // loginSocialUser = async (params) => {
  //     const res = await this.post('/social-auth/convert-token/', params);
  //     const data = res.data;
  //     const token = data.access_token;
  //     this.setAuthToken(token);
  //     return data;
  // }

  setPassword = async params => {
    const res = await this.post("/user/set_password/", params);
    return res.data;
  };

  restorePassword = async params => {
    const res = await this.post("/user/restore_password/", params);
    return res.data;
  };

  restorePasswordSetPassword = async params => {
    const res = await this.post("/user/restore_password_set_password/", params);
    return res.data;
  };

  userCheckPassword = async params => {
    const res = await this.post("/user/check_password/", params);
    return res.data;
  };

  createUser = async params => {
    const res = await this.post("/user/", params);
    return res.data;
  };

  patchImageModalParams = newParams => {
    if (newParams.image === null) {
      return newParams;
    } else {
      const fd = new FormData();
      Object.keys(newParams).map(key => {
        fd.append(key, newParams[key]);
      });
      return fd;
    }
  };

  updateUser = async (id, params) => {
    const newParams = { ...params };
    if (typeof newParams.image === "string") {
      delete newParams.image;
    }
    const resParams = this.patchImageModalParams(newParams);
    const res = await this.patch(`/user/${id}/`, resParams);
    return res.data;
  };

  updateProducer = async (id, params) => {
    const newParams = { ...params };
    if (typeof newParams.image === "string") {
      delete newParams.image;
    }
    const resParams = this.patchImageModalParams(newParams);
    const res = await this.patch(`/producers/producer/${id}/`, resParams);
    return res.data;
  };

  deleteUser = async id => {
    const res = await this.delete(`/user/${id}/`, {});
    return res.data;
  };

  getCurrentUser = async () => {
    const res = await this.get("/user/current/");
    return userSerializer(res.data);
  };

  getSearch = async ({ q, page }) => {
    const res = await this.get("/content/search/", { q, page });
    return res.data;
  };

  getNews = async ({ page }) => {
    const res = await this.get("/content/blog_post/", { page });
    const data = res.data;
    data.results = data.results.map(blogSerializer);
    return data;
  };

  getProducers = async ({ page = 1, search, district } = {}) => {
    const res = await this.get("/producers/producer/", {
      page,
      search,
      district
    });
    const data = res.data;
    return data;
  };

  getDistricts = async ({ page = 1, search } = {}) => {
    const res = await this.get("/producers/district/", {
      page_size: 99,
      page,
      search
    });
    const data = res.data;
    return data;
  };

  getFermersProducts = async ({
    page,
    producer__district,
    producer,
    group,
    type,
  } = {}) => {
    const res = await this.get("/producers/product/", {
      page,
      producer__district,
      producer,
      group,
      type,
      view_product : 0,
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  getProducts = async ({
    page,
    producer__district,
    producer,
    group,
    type,
    discount,
    order,
    category,
    type_product = 'product',
  } = {}) => {
    const res = await this.get("/producers/product/", {
      page,
      producer__district,
      producer,
      group,
      type,
      view_product : 1,
      discount,
      order,
      category,
      type_product,
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  getServices = async ({
    page,
    producer__district,
    producer,
    group,
    type,
    discount,
    order,
    category,
    type_product = 'service',
  } = {}) => {
    const res = await this.get("/producers/product/", {
      page,
      producer__district,
      producer,
      group,
      type,
      view_product : 1,
      discount,
      order,
      category,
      type_product,
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  getShopProducts = async ({ shop_id }) => {
    const res = await this.get("/producers/product/shop_products/", {
      shop_id
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  getUserProducts = async ({ page, group, shop, type }) => {
    const res = await this.get("/producers/product/producer_products/", {
      page,
      group,
      shop,
      type
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  getUserServices = async ({ ...params }) => {
    const res = await this.get("/producers/product/producer_service/", {
      page_size: 6,
      ...params
    });
    const data = res.data;
    data.results = data.results.map(productSerializer);
    return data;
  };

  productParamsToFormData = params => {
    const newParams = { ...params };
    if (typeof newParams.image !== "object") {
      delete newParams.image;
    }
    const fieldsKeys = ["type", "group", "shop"];

    fieldsKeys.forEach(key => {
      if (newParams[key]) {
        newParams[key] = newParams[key].value;
      } else newParams[key] = "";
    });
    delete newParams.section_type;
    newParams.price = Number(newParams.price).toFixed(2);
    const fd = new FormData();
    Object.keys(newParams).map(key => {
      fd.append(key, newParams[key]);
    });
    fd.append("is_active", true);
    fd.append("seo_title", params.title);
    fd.append("seo_description", params.title);
    return fd;
  };

  createProduct = async params => {
    const fd = this.productParamsToFormData(params);
    const res = await this.post("/producers/product/", fd);
    return res.data;
  };

  updateProduct = async (id, params) => {
    const fd = this.productParamsToFormData(params);
    const res = await this.patch(`/producers/product/${id}/`, fd);
    return res.data;
  };

  deleteProduct = async id => {
    const res = await this.delete(`/producers/product/${id}/`, {});
    return res.data;
  };

  getProductGroups = async ({ page, search } = {}) => {
    const res = await this.get("/producers/product_group/", {
      page,
      search
    });
    return res.data;
  };

  getProductTypes = async ({ page, search, group }) => {
    const res = await this.get("/producers/product_type/", {
      page,
      search,
      group
    });
    return res.data;
  };

  getPartners = async ({ district, category, obj, page } = {}) => {
    const res = await this.get("/services/partner/", {
      partner_category: category,
      service: obj,
      page,
      district
    });
    res.data.results = res.data.results.map(partnerServiceSerializer);
    return res.data;
  };

  getOffers = async ({ category, obj, page }) => {
    const res = await this.get("/services/offer/", {
      offer_category: category,
      // partner: obj,
      page
    });
    res.data.results = res.data.results.map(offerPartnerSerializer);
    return res.data;
  };

  createLoanApplication = async params => {
    const fd = new FormData();
    Object.keys(params).map(key => {
      fd.append(key, params[key]);
    });
    const res = await this.post("/content/loan_application/", fd);
    return res.data;
  };

  createPartnershipApplication = async params => {
    const res = await this.post("/content/partnership_application/", params);
    return res.data;
  };

  getViewedProducts = async () => {
    const res = await this.get("/producers/viewed_products/", {});
    const data = res.data;
    data.results = data.results.map(item => productSerializer(item.product));
    return data;
  };

  checkActiveProducts = async ({ products = [] }) => {
    const res = await this.post("/producers/product/check_active_products/", {
      products
    });
    return res.data;
  };

  createOrder = async ({ cart, phone }) => {
    const products = [];
    Object.values(cart).map(item => {
      const { product, params } = item;
      products.push({
        id: product.id,
        qty: params.count
      });
    });
    const res = await this.post("/order/create", { products, phone });
    return res.data;
  };

  orderSearchParams = search => {
    const date = dayjs(search, "DD.MM.YYYY");
    const isDate = date.isValid();
    if (isDate) {
      return {
        created_at__gte: date.format("YYYY-MM-DDT00:00:00"),
        ordering: "created_at"
      };
    }
    return {
      search
    };
  };

  getOrderProducer = async ({ page, search } = {}) => {
    const params = this.orderSearchParams(search);
    const res = await this.get("/order/producer", {
      page,
      page_size: 99,
      ...params
    });
    return res.data;
  };

  getOrderUser = async ({ page, search } = {}) => {
    const params = this.orderSearchParams(search);
    const res = await this.get("/order/user", {
      page,
      page_size: 99,
      ...params
    });
    return res.data;
  };

  payOrder = async ({ id }) => {
    const status = "payed";
    const res = await this.patch(`/order/update/${id}`, { status });
    return res.data;
  };

  cancelOrder = async ({ id }) => {
    const status = "canceled";
    const res = await this.patch(`/order/update/${id}`, { status });
    return res.data;
  };

  createExtraContact = async () => {
    const params = {
      phone: "",
      contact_name: "",
      email: ""
    };
    const res = await this.post("/extra_contact/", params);
    return res.data;
  };

  updateExtraContact = async (id, params) => {
    const res = await this.patch(`/extra_contact/${id}/`, params);
    return res.data;
  };

  deleteExtraContact = async id => {
    const res = await this.delete(`/extra_contact/${id}/`, {});
    return res.data;
  };

  getCalcValue = async params => {
    const res = await this.get("/content/credit/calculate/", params);

    return res.data;
  };

  getShops = async params => {
    const res = await this.get("/producers/shop/", params);
    return res.data;
  };

  shopParamsToFormData = params => {
    const newParams = { ...params };
    if (newParams.district) {
      newParams.district = newParams.district.value;
    }
    if (newParams.service) {
      newParams.service = newParams.service.value;
    }
    if (newParams.category) {
      newParams.category = newParams.category.value;
    }
    const fd = new FormData();
    if (newParams.image_list !== null) {
      for (let i = 0; i < newParams.image_list.length; i++) {
        if (!newParams.image_list[i].id) {
          fd.append(`image_list`, newParams.image_list[i]);
        }
      }
      delete newParams.image_list;
    }
    delete newParams.shopimage_set;
    Object.keys(newParams).map(key => {
      if (typeof newParams[key] === "string") {
        const value = newParams[key].trim();
        newParams[key] = value;
      }
      fd.append(key, newParams[key]);
    });

    fd.append("is_active", true);
    fd.append("seo_title", params.title);
    if (params.content) {
      fd.append("seo_description", params.content);
    }
    return fd;
  };

  createProducerShop = async params => {
    const fd = this.shopParamsToFormData(params);
    const res = await this.post("/producers/shop/", fd);
    return res.data;
  };

  updateProducerShop = async (id, params) => {
    const fd = this.shopParamsToFormData(params);
    const res = await this.patch(`/producers/shop/${id}/`, fd);
    return res.data;
  };

  deleteProducerShop = async id => {
    const res = await this.delete(`/producers/shop/shop_delete/${id}/`);
    return res.data;
  };

  deleteShopImage = async id => {
    const res = await this.delete(`/producers/shop/image/${id}/`);
    return res.data;
  };

  getShopAddresses = async ({ shop_id }) => {
    const res = await this.get(`/producers/address/address/${shop_id}/`);
    return res.data;
  };

  addShopAddress = async ({ shop, address }) => {
    const res = await this.post("/producers/address/", { shop, address });
    return res.data;
  };

  updateShopAddress = async ({ id, address }) => {
    const res = await this.patch(`/producers/address/${id}/`, { address });
    return res.data;
  };

  deleteShopAddress = async ({ id }) => {
    const res = await this.delete(`/producers/address/${id}`);
    return res.data;
  };

  getShopProducer = async ({ page, search, ...filters }) => {
    const res = await this.get("/producers/shop/producer_shop/", {
      page,
      search,
      ...filters
    });
    return res.data;
  };

  createShopReview = async values => {
    const res = await this.post("/producers/review/", { ...values });
    return res.data;
  };

  getShopReviews = async ({ shop_id, ...filters }) => {
    const res = await this.get(`/producers/review/review/${shop_id}/`, {
      ...filters
    });
    return res.data;
  };

  createShopReviewReply = async ({ review, text }) => {
    const res = await this.post("/producers/response/", { review, text });
    return res.data;
  };

  getShopAddresses = async ({ shop_id, ...filters }) => {
    const res = await this.get(`/producers/address/address/${shop_id}/`, {
      ...filters
    });
    return res.data;
  };

  getServicesList = async ({ page, search }) => {
    const res = await this.get("/services/service/", { page, search });
    return res.data;
  };

  getServicesCategories = async ({ page, search }) => {
    const res = await this.get("/services/partner-category/", {
      page,
      search
    });
    return res.data;
  };
}

let MAIN_URL;
let SHARE_URL;
MAIN_URL = process.env.REACT_APP_API_URL;
SHARE_URL = process.env.REACT_APP_API_URL_SHARE;
console.log(MAIN_URL, "MAIN_URL");

const api = new Api(`${MAIN_URL}`);
export default api;
export { PAGE_SIZE, SHARE_URL };
