import React from 'react';
import { Link } from "react-router-dom";


const MenuItem = React.memo(({ target_blank, url, title }) => {
  return (
    <div className="header-menu__item">
      {target_blank ? (
        <a rel="noopener noreferrer" target="_blank" className="header-menu__link" href={url}>{title}</a>
      ) : (
        <Link className="header-menu__link" to={url}>{title}</Link>
      )}
    </div>
  )
})

export {
  MenuItem
}