import React from "react";
import { Range, getTrackBackground } from "react-range";

export default function RangeSlider({ values, min, max, step, getRangeVals }) {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={(values) => getRangeVals(values)}
      renderTrack={({ props, children }) => {
        return (
          <div
            {...props}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "3px",
              width: "100%",
              backgroundColor: "#ccc",
              background: getTrackBackground({
                values: values,
                colors: ["#548BF4", "#fff"],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
        );
      }}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "30px",
            width: "30px",
            backgroundColor: "#548BF4",
            borderRadius: "30px",
            border: "2px solid #fff",
            boxShadow: "-4px 6px 9px 0px rgba(102, 158, 255, 0.19)",
          }}
        />
      )}
    />
  );
}
