import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Button } from "@garpix/component-system";

export default function CreditTerms({
  data,
  selectVal,
  title,
  link,
  showModal,
  isSecondTab,
}) {
  if (data) {
    return (
      <section className="credlists">
        {title && <h1 className="credlists-heading">{title}</h1>}
        <div className="credlists-items">
          {
            data[selectVal].terms.map((term, i) => (
              <div key={term.title + i} className="credlists-item">
                <div className="credlists-item__label">{term.title}</div>
                <div className="credlists-item__value">
                  {ReactHtmlParser(term.content)}
                </div>
              </div>
            ))
          }
        </div>
  
        <div className="credlists-btm">
          {!isSecondTab && (
            <Button onClick={() => showModal()}>Отправить заявку</Button>
          )}
          {link && (
            <a href={link} download className="btn btn--link">
              Подробные условия
            </a>
          )}
        </div>
      </section>
    );
  }
  return <></>
}
