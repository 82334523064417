import React, { useState } from 'react';
import { FetcherList } from '@garpix/fetcher';
import api from "../../api";
import { OrderItem } from "./OrderItem";
import { Alert } from "@garpix/component-system";
import { OrderDetail } from "./OrderDetail";
import { OrdersSearch } from './OrdersSearch';


const MemberOrders = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  return (
    <FetcherList
      api={api.getOrderUser}
      isHistoryPush={false}
      isScrollTop={false}
    >
      {({ status, results = [], count, loadData }) => {
        const currentOrder = results.length > 0 && activeOrder ? activeOrder : results[0];
        return (
          <div className="container">
            <div className="orders">
              <div className="orders-left">
                <OrdersSearch callback={loadData} />
                <div className="orders-list">
                  <div className="orders-list__items">
                    {status === 'loaded' && count === 0 ? <Alert variant={"light"}>Список пуст</Alert> : null}
                    {results.map(item => <OrderItem key={item.id} item={item} currentOrder={currentOrder} setActiveOrder={setActiveOrder} />)}
                  </div>
                </div>
              </div>
              <div className="orders-right">
                <OrderDetail currentOrder={currentOrder} />
              </div>
            </div>
          </div>
        )
      }}
    </FetcherList>
  )
}

export default MemberOrders;