import React from 'react';
import { ManufImage } from '../../images';
import { getLegalFormName } from '../../utils';

const ManufValue = ({ contact_name, phone, email }) => {
  return (
    <div className="manuf-value">
      <div className="manuf-row">
        <div className="manuf-cont">
          <div className="manuf-value">{contact_name}</div>
        </div>
        <div className="manuf-cont">
          <div className="manuf-value">{phone}</div>
        </div>
        <div className="manuf-cont">
          <div className="manuf-value">{email}</div>
        </div>
      </div>
    </div>
  )
}

const Producer = ({
  producer
}) => {
  const {
    title,
    image_thumb,
    content,
    inn = '',
    address = '',
    phone = '',
    contact_name = '',
    email = '',
    legal_form,
    contacts = []
  } = producer
  return (
    <div className="manuf">
      <div className="manuf-imgwrap">
        <img className="manuf-img" src={image_thumb ? image_thumb : ManufImage} alt="" />
      </div>
      <div className="manuf-content">
        <h1 className="manuf-name">{getLegalFormName(legal_form)} «{title}»</h1>
        <div className="manuf-desc" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="manuf-row">
          <div className="manuf-inn">
            <div className="manuf-label">ИНН</div>
            <div className="manuf-value">{inn}</div>
          </div>
          <div className="manuf-addr">
            <div className="manuf-label">Юридический адрес</div>
            <div className="manuf-value">{address}</div>
          </div>
        </div>
        <div className="manuf-group">
          <div className="manuf-col10">
            <div className="manuf-label">Контакты</div>
            <ManufValue contact_name={contact_name} phone={phone} email={email} />
            {contacts.map(item => <ManufValue {...item} key={item.id} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Producer;