import React from "react";
import { Container } from "@garpix/component-system";
import { FetcherList } from "@garpix/fetcher";
import { Formik } from "formik";
import api from "../../api";
import { SelectGroup, SelectTypes } from "../Select";
import EditorProducts from "./EditorProducts";
import { isObject } from "../../utils";

const UserProducts = () => {
  return (
    <FetcherList
      api={api.getUserProducts}
      isHistoryPush={false}
      isScrollTop={false}
    >
      {({
        count,
        results = [],
        activePage,
        loadData,
        showMore,
        isNext,
        deleteElement,
        updateElement,
        filterParams
      }) => {
        const submit = params => {
          const filterParams = {};
          Object.keys(params).map(key => {
            const value = params[key];
            let resultValue = "";
            if (isObject(value) && value !== null) {
              resultValue = value.value;
            }
            filterParams[key] = resultValue;
          });
          loadData(1, filterParams);
        };

        return (
          <Container>
            <div className="cabinet-cat">
              <div className="catalog-filters">
                <Formik
                  initialValues={{
                    group: "",
                    type: ""
                  }}
                  onSubmit={submit}
                >
                  {({ submitForm, values, setFieldValue, handleReset }) => {
                    return (
                      <form className="catalog-filters__row">
                        <div className="catalog-filters__col">
                          <div className="form__label">Выберите продукт</div>
                          <SelectGroup
                            placeholder="Все продукты"
                            onChange={value => {
                              setFieldValue("group", value);
                              setFieldValue("type", "");
                              submitForm();
                            }}
                            value={values.group}
                          />
                        </div>
                        <div className="catalog-filters__col">
                          <div className="form__label">Тип продукта</div>
                          <SelectTypes
                            onChange={value => {
                              setFieldValue("type", value);
                              submitForm();
                            }}
                            value={values.type}
                            cacheUniqs={[values.group]}
                            filters={
                              values.group
                                ? {
                                    group: values.group.value
                                  }
                                : {}
                            }
                          />
                        </div>
                        <div className="catalog-filters__collast">
                          <button
                            type="reset"
                            onClick={() => {
                              submit({});
                              handleReset();
                            }}
                            className="catalog-filters__reset"
                          >
                            Сбросить
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
              <h2 className="catalog-heading">
                Каталог продуктов <span>{count}</span>
              </h2>
              <EditorProducts
                count={count}
                filterParams={filterParams}
                results={results}
                activePage={activePage}
                loadData={loadData}
                showMore={showMore}
                isNext={isNext}
                deleteElement={deleteElement}
                updateElement={updateElement}
              />
            </div>
          </Container>
        );
      }}
    </FetcherList>
  );
};

export default UserProducts;
