const baseCustomStyles = {
  control: base => ({
    ...base,
    borderColor: '#B5CFE8'
  }),
  indicatorSeparator: base => ({
    display: 'none'
  }),
  placeholder: base => ({
    ...base,
    color: '#13293D',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px'
  })
}

export default baseCustomStyles