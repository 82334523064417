import React from 'react'

const PartnerBlock = ({
  children,
  partnerImg = null,
  blockHeading = '',
  logo = null
}) => {
  return (
    <div className="partner-block">
      <div className="partner-block__row">
        <div className="partner-block__left">
          <div className="partner-block__heading">{blockHeading}</div>
          {logo ? (
            <div className="partner-block__logo">
              <img src={logo} alt='' />
            </div>
          ) : null}
        </div>
        <div className="partner-block__right">
          {children}
        </div>
      </div>
      {partnerImg ? (
        <div className="partner-block__full">
          <img className={'partner-block__image'} src={partnerImg} />
        </div>
      ) : null}
    </div>
  )
}

export default PartnerBlock;
