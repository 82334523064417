import React from 'react';
import { Form } from '@garpix/component-system';
import { Formik } from "formik";
import {
  SelectGroup,
  SelectTypes,
  SelectDistrict,
  SelectProducer
} from '../Select';
import { isObject } from '../../utils';


const Filters = ({
  handleSubmit,
  producer,
  district,
  initParams
}) => {
  const submit = params => {
    const filterParams = {};
    Object.keys(params).map(key => {
      const value = params[key];
      if (isObject(value) && value !== null) {
        const label_key = `${key}_label`;
        filterParams[key] = value.value ? value.value : '';
        filterParams[label_key] = value.label ? value.label : '';
      } else {
        filterParams[key] = value
      }
      return key
    })
    if (producer) {
      filterParams.producer = producer.id;
    }
    handleSubmit(1, { ...filterParams})
  }
  const {
    group,
    group_label,
    producer__district,
    producer__district_label,
    type,
    type_label,
  } = initParams;

  return (
    <div className="catalog-filters">
      <Formik
        enableReinitialize={true}
        onSubmit={submit}
        initialValues={{
          producer__district: producer__district !== undefined ? {
            value: producer__district,
            label: producer__district_label
          } : district ? {
            value: district.id,
            label: district.title
          } : '',
          producer: producer ? {
            value: producer.id,
            label: producer.title
          } : initParams.producer ? {
            value: initParams.producer,
            label: initParams.producer_label
          } : '',
          group: group ? {
            value: group,
            label: group_label
          } : '',
          type: type ? {
            value: type,
            label: type_label
          } : ''
        }}
      >
        {({
          values,
          setFieldValue,
          submitForm,
        }) => {
          return (
            <Form className="catalog-filters__row">
              <div className="catalog-filters__col">
                <div className="form__label">Район</div>
                <SelectDistrict
                  onChange={value => {
                    setFieldValue('producer__district', value);
                    setFieldValue('producer', '')
                    submitForm();
                  }}
                  name="producer__district"
                  value={values.producer__district}
                />
              </div>
              <div className="catalog-filters__col">
                <div className="form__label">Производитель</div>
                <SelectProducer
                  onChange={value => {
                    setFieldValue('producer', value);
                    submitForm();
                  }}
                  value={values.producer}
                  cacheUniqs={[values.producer__district]}
                  isDisabled={!!producer}
                  filters={values.producer__district ? {
                    district: values.producer__district.value
                  } : {}}
                />
              </div>
              <div className="catalog-filters__col">
                <div className="form__label">Группа</div>
                <SelectGroup
                  onChange={value => {
                    setFieldValue('group', value);
                    setFieldValue('type', '')
                    submitForm();
                  }}
                  value={values.group}
                  name="group"
                />
              </div>
              <div className="catalog-filters__col">
                <div className="form__label">Тип</div>
                <SelectTypes
                  onChange={value => {
                    setFieldValue('type', value);
                    submitForm();
                  }}
                  value={values.type}
                  name="type"
                  cacheUniqs={[values.group]}
                  filters={values.group ? {
                    group: values.group.value
                  } : {}}
                />
              </div>
              <div className="catalog-filters__collast">
                <div onClick={() => submit({})} className="catalog-filters__reset">Сбросить</div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Filters;
