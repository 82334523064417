import React, { useState } from 'react';
import { Formik } from 'formik';
import { useStoreon } from 'storeon/react'
import { Form, ClearField, PasswordField } from '@garpix/component-system';
import { RegistrationStep1Schema } from '../../schema';
import { withRouter } from "react-router-dom";
import api from "../../api";
import Errors from '../../views/Errors';
import { PhoneField, UnionTicket } from '../../views/Fields';
import { Captcha } from "../Captcha";

const MEMBER_TYPE = 'member';
const MEMBER2_TYPE = 'member2';
// const PARTNER_TYPE = 'partner';
const SUCCESS = 'success';

const Registration = ({ history }) => {
  const { dispatch, page } = useStoreon('page');
  const [typeReg, setTypeReg] = useState(MEMBER_TYPE);

  const { site_configuration } = page.data.page;
  const { policy_slug } = site_configuration;
  const { pageType } = page.data;

  const send = (params, { setErrors, setSubmitting }) => {
    let resParams = {};
    if (typeReg === MEMBER_TYPE) {
      resParams = {
        number: params.number
      }
    } else {
      params.username = params.email;
      resParams = params
    }
    api.createUser(resParams)
      .then(response => {
        setTypeReg(SUCCESS);
      })
      .catch(error => {
        if (!error.response) return false;
        const { data } = error.response;
        setSubmitting(false);
        if (!data.number && typeReg === MEMBER_TYPE) {
          setTypeReg(MEMBER2_TYPE);
        }
        setErrors(data)
      })
  }

  if (typeReg === SUCCESS) {
    return (
      <div className="form-end">
        <div className="form-group">
          <p>Регистрация прошла успешно</p>
        </div>
      </div>
    )
  }

  const wantToBePartner = () => {
    if (pageType === 23) {
      dispatch('modal/toggle', { modal: 'registration-partner' });
    } else {
      dispatch('modal/close');
      history.push('/promo-dlya-partnerov');
    }
  }

  return (
    <div className="forms">
      <div className="form-step1">
        <Formik
          validationSchema={RegistrationStep1Schema}
          onSubmit={send}
          initialValues={{
            first_name: '',
            last_name: '',
            middle_name: '',
            number: '',
            phone: '',
            email: '',
            password: '',
            is_policies: false,
            recaptcha: ''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isSubmitting,
            setFieldValue,
            errors,
          }) => (
            <>
              <div className="form-group">
                <button
                  type='button'
                  onClick={wantToBePartner}
                  className="btn btn--full btn--border">Хочу стать партнёром</button>
              </div>
              <div
                className="form-member"
                style={{ display: typeReg === MEMBER_TYPE ? 'block' : 'none' }}>
                <div className="form-steps"><span>Шаг 1/2</span></div>
                <Form onSubmit={handleSubmit} noValidate className="form">
                  <div className="form-group">
                    <ClearField
                      label="Фамилия *"
                      name="last_name"
                      onChange={setFieldValue}
                      value={values.last_name}
                    />
                    {errors.last_name && touched.last_name ? (
                      <Errors errors={errors.last_name} />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <ClearField
                      label="Имя *"
                      name="first_name"
                      onChange={setFieldValue}
                      value={values.first_name}
                    />
                    {errors.first_name && touched.first_name ? (
                      <Errors errors={errors.first_name} />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <ClearField
                      label="Отчество"
                      name="middle_name"
                      onChange={setFieldValue}
                      value={values.middle_name}
                    />
                    {errors.middle_name && touched.middle_name ? (
                      <Errors errors={errors.middle_name} />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <UnionTicket
                      error={errors.number}
                      touched={touched.number}
                      value={values.number}
                      label="№ профсоюзного билета *"
                      name="number"
                      setFieldValue={setFieldValue}
                    />
                    <div className="inputlabel__text">16-ти значный код с вашего электронного профсоюзного билета</div>
                  </div>
                  <div className="form-end">
                    <div className="form-group">
                      <div className="form-checkbox">
                        <Form.Check
                          custom
                          inline
                          type={'checkbox'}
                          id={`is_policies`}>
                          <Form.Check.Input
                            type={'checkbox'}
                            name="is_policies"
                            value={values.is_policies}
                            onChange={handleChange}
                          />
                          <Form.Check.Label>
                            <span>
                              Согласен с условиями{" "}
                              <a target="_blank" rel="noopener noreferrer" href={policy_slug}>политики конфиденциальности</a>
                            </span>
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                      {errors.is_policies && touched.is_policies ? (
                        <Errors errors={errors.is_policies} />
                      ) : null}
                    </div>
                    <div className="form-group">
                      <button disabled={isSubmitting} className="btn btn--full">Далее</button>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="form-step2" style={{ display: typeReg === MEMBER2_TYPE ? 'block' : 'none' }}>
                <div onClick={() => setTypeReg(MEMBER_TYPE)} className="form-back js-stepPrev">Назад</div>
                <div className="form-confirm">
                  <span>{values.first_name} {values.last_name} {values.middle_name}</span>
                </div>
                <div className="form-confirm"><span>{values.number}</span></div>
                <div className="form-steps"><span>Шаг 2/2</span></div>
                <Form onSubmit={handleSubmit} noValidate className="form">
                  <div className="form-group">
                    <PhoneField
                      label="Номер телефона *"
                      value={values.phone}
                      error={errors.phone}
                      touched={touched.phone}
                      setFieldValue={setFieldValue}
                    />
                    {/* <ClearField
                        label="Номер телефона *"
                        name="phone"
                        onChange={setFieldValue}
                        value={values.phone}
                      /> */}
                    {/* {errors.phone && touched.phone ? (
                        <Errors errors={errors.phone} />
                      ) : null} */}
                  </div>
                  <div className="form-group">
                    <ClearField
                      label="Email *"
                      name="email"
                      onChange={setFieldValue}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <Errors errors={errors.email} />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <PasswordField
                      label="Введите пароль *"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    {errors.password && touched.password ? (
                      <Errors errors={errors.password} />
                    ) : null}
                    <div className="inputlabel__text">Ваш пароль должен быть не менее 8 символов в длину и содержать буквы, цифры и специальные символы. Не может содержать пробелы.</div>
                  </div>
                  <div className="form-group">
                    <Captcha
                      name={'reg'}
                      handleValue={value => {
                        setFieldValue('recaptcha', value);
                      }}
                    />
                  </div>
                  <div className="form-end">
                    <div className="form-group">
                      <button disabled={isSubmitting} className="btn btn--full">Зарегистрироваться</button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div >
  )
}

export default withRouter(Registration);
