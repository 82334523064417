import React from "react";
import { defaultImage } from "../../images";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player/youtube";

const ShopGallery = ({ data = [], video_page = "", youtube_id = "" }) => {
  const getVideoThumb = youtube_id =>
    `https://img.youtube.com/vi/${youtube_id}/default.jpg`;

  const customRenderThumb = children => {
    const arr = [];

    children.map((item, i) => {
      if (item !== null) {
        if (i === 0) {
          const src = getVideoThumb(youtube_id);
          arr.push(src);
        } else {
          item.map((el, i) => arr.push(el.props.src));
        }
      }
    });

    return (
      arr.length > 0 &&
      arr.map((thumb, i) => {
        return (
          <img
            src={thumb}
            key={`img-${i}`}
            className="shopage-gallery__img"
            alt=""
          />
        );
      })
    );
  };

  const isShow = (data && data.length > 0) || (video_page && video_page !== "");

  return (
    <div className="shopage-gallery">
      {isShow ? (
        <Carousel
          renderThumbs={customRenderThumb}
          swipeable={true}
          thumbWidth={103}
        >
          {video_page && video_page !== "" ? (
            <ReactPlayer width="100%" url={video_page} />
          ) : null}
          {data.map((item, idx) => {
            return (
              <img
                key={`big-${idx}`}
                src={item.image_url}
                className="shopage-gallery__img"
                alt=""
              />
            );
          })}
        </Carousel>
      ) : (
        <img src={defaultImage} className="shopage-gallery__img" alt="" />
      )}
    </div>
  );
};

export default ShopGallery;
