import React from "react";
import { useStoreon } from "storeon/react";
import { Formik } from "formik";
import { Form, Button } from "@garpix/component-system";
import Errors from "../../views/Errors";
import api from "../../api";

const ImageDelete = ({ handleClose }) => {
  const { imageId, dispatch } = useStoreon("imageId");

  const handleDelete = ({ setSubmitting, setErrors }) => {
    if (imageId) {
      api
        .deleteShopImage(imageId)
        .then(res => {
          dispatch("modal/toggle", { modal: null });
        })
        .catch(err => {
          setSubmitting(false);
          setErrors({
            non_field: "На сервере произошла ошибка. Попробуйте позже"
          });
        });
    } else {
      dispatch("modal/toggle", { modal: null });
    }
  };

  return (
    <Formik onSubmit={handleDelete}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form--login">
          <div className="modal-text">
            Вы действительно хотите удалить этот файл? Отменить это действие
            будет невозможно
          </div>
          <div className="form-group">
            {errors.non_field ? <Errors errors={errors.non_field} /> : null}
          </div>

          <div className="modal-end">
            {/* <Button
              onClick={handleClose}
              className="modal-btn"
              data-dismiss="modal"
            >
              Отменить
            </Button> */}
            <input
              style={{ marginLeft: "auto" }}
              disabled={isSubmitting}
              className="btn modal-btn btn--red"
              type="submit"
              value="Удалить"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImageDelete;
