import React from 'react';
import { Form } from "@garpix/component-system"
import { Formik } from "formik"

const DiscountFilter = ({ label, handleSubmit, initParams }) => {
    const submit = ({ discount }) => {
        handleSubmit(1, { ...initParams, 'discount': discount })
    }
    return (
        <Formik
            onSubmit={submit}
            initialValues={
                {
                    discount: false,
                }
            }
        >
            {
                ({ values, setFieldValue, submitForm }) => (
                    <Form.Check
                        custom
                        name="discount"
                        type={'checkbox'}
                        id={`disc`}
                        label={label}
                        onChange={() => {
                            setFieldValue('discount', !values.discount)
                            submitForm()
                        }
                        }
                    />
                )
            }
        </Formik>
    )
}

export { DiscountFilter }