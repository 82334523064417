import React from 'react';
import Select from 'react-select';
import baseCustomStyles from "./style";

const customStyles = {
  ...baseCustomStyles,
  valueContainer: (base) => ({
    ...base,
    marginTop: '24px',
    paddingLeft: '14px',
  })
}

const SelectCustom = (props) => {
  return (
    <Select
      placeholder="Выберите значение"
      styles={customStyles}
      {...props}
    />
  )
}

export default SelectCustom;
