const siteConfigurationSerializer = ({
  page_type_search,
  page_type_cart,
  page_type_account,
  page_type_blog,
  page_type_policy,
  page_type_catalog,
  page_type_credit_coop,
  logo_text,
  page_type_help_lp
}) => {
  return {
    credit_coop_slug: page_type_credit_coop,
    search_slug: page_type_search,
    cart_slug: page_type_cart,
    account_slug: page_type_account,
    blog_slug: page_type_blog,
    policy_slug: page_type_policy,
    catalog_slug: page_type_catalog,
    logo_text,
    help_slug: page_type_help_lp
  }
}

export default siteConfigurationSerializer
