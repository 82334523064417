import React from 'react';
import { Link } from 'react-router-dom';

const CategoryList = ({ data, cat_url }) => {
    return (
        <>
            <div className="category-list">
                {data && data.map(cat => {
                    return (
                        <div key={cat.id} className="category-list__item">
                            <Link to={`/${cat_url}?category=${cat.slug}`}>
                                <img className="category-list__img" src={cat.icon} alt={cat.title} />
                                <p className="mt-1 category-list__title">{cat.title}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export { CategoryList };
