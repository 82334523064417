import React from 'react';


const User = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.9995" cy="16" r="16" fill="#C4C8CB" fillOpacity="0.1"/>
      <g clipPath="url(#clip0)">
        <path d="M15.9534 23.0528C19.3656 23.0528 22.1317 19.3834 22.1317 14.8569C22.1317 10.3305 19.3656 6.66113 15.9534 6.66113C12.5412 6.66113 9.77515 10.3305 9.77515 14.8569C9.77515 19.3834 12.5412 23.0528 15.9534 23.0528Z" fill="#16324F"/>
        <path d="M15.8553 22.5127C10.6822 22.5127 6.07301 23.7104 2.98389 25.5656C5.85236 29.4639 10.5841 32.0001 15.9533 32.0001C21.2735 32.0001 25.9807 29.4874 28.8492 25.6595C25.7846 23.7338 21.1019 22.5127 15.8553 22.5127Z" fill="#16324F"/>
        <path d="M16.9096 26.4575H15.0218C13.7224 26.4575 12.6682 25.4477 12.6682 24.203V19.0601C12.6682 17.8155 13.7224 16.8057 15.0218 16.8057H16.9096C18.209 16.8057 19.2633 17.8155 19.2633 19.0601V24.203C19.2633 25.4477 18.209 26.4575 16.9096 26.4575Z" fill="#16324F"/>
        <path d="M10.7558 15.3261C10.9519 16.6412 10.6332 17.7684 10.0693 17.8624C9.50544 17.9563 8.89252 16.97 8.7209 15.6549C8.52476 14.3398 8.84348 13.2126 9.40737 13.1187C9.97126 13.0012 10.5842 14.011 10.7558 15.3261Z" fill="#16324F"/>
        <path d="M21.1755 15.3269C20.9794 16.642 21.2981 17.7692 21.862 17.8632C22.4259 17.9571 23.0388 16.9708 23.2104 15.6557C23.4065 14.3406 23.0878 13.2134 22.5239 13.1195C21.96 13.0255 21.3471 14.0119 21.1755 15.3269Z" fill="#16324F"/>
        <path d="M9.99574 14.5281C9.7996 13.213 9.57895 11.8979 9.38282 10.5828C9.30927 10.1131 9.23572 9.5965 9.40733 9.15031C9.57895 8.70412 10.1183 8.35186 10.5841 8.51625C10.388 7.60038 11.4422 6.91936 12.3739 6.63755C13.9184 6.12091 15.5611 5.86259 17.1792 6.07394C18.7973 6.2853 20.3664 6.96632 21.4696 8.11703C21.8129 8.49277 22.1071 8.89199 22.2787 9.36166C22.5974 10.254 22.4013 11.2404 22.1806 12.1797C22.009 12.9077 21.8619 13.6592 21.6903 14.3872C21.6413 14.5516 21.6167 14.7394 21.4942 14.8569C21.298 15.0682 20.9793 15.0682 20.6851 15.0682C17.6205 15.0682 14.5559 15.0917 11.4913 15.0917C10.9274 15.0917 10.1674 14.9508 10.0938 14.4107" fill="#16324F"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="2.98389" y="6" width="25.8653" height="26" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default User;