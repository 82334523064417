import React from 'react';
import LogoImage from './logo.svg';


const Logo = React.memo(() => {
  return (
    <img width="314" height="43" src={LogoImage} alt={"Логотип"} />
  )
})

export default Logo;