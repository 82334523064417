import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlaceholderImage } from '../../images';
import { getPriceWithoutDiscount } from '../../utils/getPriceWithoutDiscount';
import './category_product_list.scss'

const CategoryProductList = ({ data, md }) => {
    return (
        <>
            <Row xs={2} sm={2} md={md ?? 4}>
                {data && data.map((product) => {
                    return (
                        <Col key={product.id} className="mb-3">
                            <div className="product-block">
                                <div className="img-block">
                                    {product.discount && product.discount !== '0' && <div className="img-block__discount">-{product.discount}%</div>}
                                    <Link to={product.slug}><img className="img-block__img" src={product?.image_thumb || PlaceholderImage} alt={product.title} /></Link>
                                </div>
                                <Link to={product.slug}><h4 className="product-block__title">{product.title}</h4></Link>
                                <p className="product-block__price">
                                    {product.discount && product.discount !== '0' && <span className="product-block__price_old">
                                        {getPriceWithoutDiscount(product.price, product.discount)} руб
                                    </span>}
                                    <span className="product-block__price_current">{product.price} руб</span>
                                </p>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export { CategoryProductList };

