import React from "react";
import { Link as RouterLink } from "react-router-dom";


const Link = ({ target_blank, children, to, ...props }) => {
  if(target_blank) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={to} {...props}>{children}</a>
    )
  } else {
    return (
      <RouterLink to={to} {...props}>{children}</RouterLink>
    )
  }
}

export {
  Link
}