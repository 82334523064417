import React from "react";
import Rating from "react-rating";
import { Button } from "@garpix/component-system";
import { StarEmpty, StarFull } from "./RaitingStars";
import dayjs from "../../utils/dayjs";

const ReviewItem = ({ review, userType, handleClickReply }) => {
  const { star, response, created_at, text, user, id } = review;
  const { first_name, last_name, producer, type } = user;

  return (
    <div key={id} className="shopage-reviews-item">
      <div className="shopage-reviews-item__top">
        <div className="shopage-reviews-item__top-left">
          <div className="shopage-reviews-item__name">
            {type && type === "normal" ? (
              <>
                {first_name ? first_name : ""} {last_name ? last_name : ""}
              </>
            ) : (
              <>{producer ? producer.title : ""}</>
            )}
          </div>
          <div className="shopage-rating__stars">
            <Rating
              emptySymbol={<StarEmpty />}
              fullSymbol={<StarFull />}
              initialRating={star}
              readonly
            />
          </div>
        </div>
        <div className="shopage-reviews-item__date">
          {dayjs(created_at).format("DD.MM.YYYY")}
        </div>
      </div>
      <div className="shopage-reviews-item__text">{text}</div>
      {userType && userType === "producer" && response.length === 0 ? (
        <div className="shopage-reviews-item__reply">
          <Button
            onClick={() => {
              handleClickReply({ id });
            }}
            className="btn btn--link"
          >
            Ответить
          </Button>
        </div>
      ) : null}
      {response && response.length > 0 ? (
        <>
          {response.map(elem => {
            return (
              <div key={elem.id} className="shopage-reviews__reply">
                <div className="shopage-reviews-item__name">
                  Ответ от представителя торговой площадки
                </div>
                <div className="shopage-reviews-item__text">{elem.text}</div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default ReviewItem;
