import React from "react";
import ImageUpload from '../ImageUpload';

const styleHiddenInput = {
  position: "absolute",
  top: 0,
  right: 0,
  margin: 0,
  padding: 0,
  opacity: 0,
  fontSize: 100
};

const styleField = { display: "inline-block", position: "relative", overflow: "hidden" };

const FileUpload = ({
  placeholder,
  setFieldValue,
  name,
  accept
}) => {
  return (
    <ImageUpload>
      {({
        onSelectFile,
        selectedFile
      }) => {
        return (
          <div
            className="jq-file form-file changed"
            style={styleField}
          >
            <div className="jq-file__name">{placeholder}</div>
            <div className="jq-file__browse">{selectedFile ? `${selectedFile.name}` : 'Прикрепить файл'}</div>
            <input
              accept={accept}
              className="form-file"
              type="file"
              name={name}
              style={styleHiddenInput}
              onChange={(event) => {
                const file = event.currentTarget.files[0];
                onSelectFile(file)
                setFieldValue(name, file)
              }}
            />
          </div>
        )
      }}
    </ImageUpload>
  )
}

export default FileUpload;
