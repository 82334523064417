import React from 'react';
import Pagination from 'react-paginating';
import { Link } from 'react-router-dom';
import { PAGE_SIZE } from '../../api'
import qs from 'query-string';

const Paginate = ({
  activePage,
  count,
  params = {}
}) => {

  return (
    <Pagination
      total={count}
      limit={PAGE_SIZE}
      pageCount={5}
      currentPage={activePage}>
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        // totalPages,
        // getPageItemProps
      }) => (
        <div className="pagi">
          {hasPreviousPage ? (
            <Link className="pagi-link pagi-prev" to={`?${qs.stringify({ ...params, page: previousPage, })}`} />
          ) : null}
          {pages.length > 1 ? (
            <>{pages.map(page => <Link
              key={page}
              className={`pagi-link ${currentPage === page ? 'pagi-link--active' : ''}`}
              to={`?${qs.stringify({ ...params, page: page, })}`}>{page}</Link>)}
            </>
          ) : null}
          {hasNextPage ? (
            <Link className="pagi-link pagi-next" to={`?${qs.stringify({ ...params, page: nextPage })}`} />
          ) : null}
        </div>
      )}
    </Pagination>
  )
}

export default Paginate;