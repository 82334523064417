import React from 'react';
import Layout from '../views';
import HelpList from '../components/HelpList';

const FAQPage = (props) => {
  const { help_info } = props;

  return (
    <Layout {...props}>
      <HelpList data={help_info} />
    </Layout>
  )
}
export default FAQPage;