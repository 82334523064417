import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStoreon } from 'storeon/react'
import { Combine, } from './pages'
import { PATHS } from './const';
import Modals from './components/Modals';


function App() {
  const { dispatch } = useStoreon()

  useEffect(() => {
    dispatch('user/get', {
      callback: () => { }
    });
  })

  return (
    <>
      <Switch>
        <Route path={PATHS.ALL.path} render={(props) => <Combine {...props} {...PATHS.ALL} />} />
      </Switch>
      <Modals />
    </>
  );
}

export default App;
