import React from 'react';


const Garpix = () => {
  return (
    <svg width="79" height="15" viewBox="0 0 79 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4205 0.311279L13.76 14.6956H17.6666L18.495 12.4351H24.4674L25.2958 14.6956H29.2024L23.5266 0.311279H19.4205ZM19.6302 9.34806L21.1079 5.18939C21.2869 4.72505 21.4096 4.225 21.4863 3.73004C21.563 4.225 21.6806 4.72505 21.8647 5.18939L23.3424 9.34806H19.6302Z" fill="white" />
      <path d="M73.74 7.21005L69.2911 0.311279H64.2341L68.8819 7.21005L63.9322 14.6956H68.3709L71.3008 9.87363L74.2257 14.6956H78.6947L73.74 7.21005Z" fill="white" />
      <path d="M50.9394 0.311279H45.238V14.7109H49.0424V10.1899H50.9292C54.4881 10.1746 57.2084 9.04699 57.2084 5.25063C57.2033 1.45427 54.4983 0.311279 50.9394 0.311279ZM51.1235 6.89367H49.0372V3.59737H51.1184C52.2791 3.59737 53.3069 3.94435 53.3069 5.24041C53.3069 6.5314 52.2842 6.89367 51.1235 6.89367Z" fill="white" />
      <path d="M9.15802 11.6391C8.84103 11.7156 8.50863 11.7463 8.1763 11.7463C6.52977 11.7463 3.90662 11.0676 3.90662 7.5162C3.90662 4.56176 6.11046 3.35754 8.43196 3.35754C9.87903 3.35754 11.3312 3.76575 12.4153 4.50052V0.90317C10.9989 0.316371 9.49042 9.11001e-06 7.95126 9.11001e-06C3.92198 -0.00509351 0 2.13291 0 7.49578C0 12.7209 3.89128 14.9967 7.99731 14.9967C9.64383 14.9813 11.2545 14.665 12.7783 14.0322V6.78649H9.15802V11.6391Z" fill="white" />
      <path d="M39.8179 9.47048C41.4031 8.8377 42.5792 7.54165 42.5792 5.14852C42.5792 1.35217 39.5929 0.301025 36.663 0.301025H30.5831V14.6853H34.4028V10.0164H36.0493L39.4907 14.7006H43.7911L39.8179 9.47048ZM36.8317 6.92426H34.4028V3.59732H36.8164C37.9055 3.59732 38.6418 4.15351 38.6418 5.27098C38.6418 6.38338 37.9209 6.92426 36.8317 6.92426Z" fill="white" />
      <path d="M58.9827 14.6956H62.8023V0.311279H58.9827V14.6956Z" fill="white" />
      <path d="M73.8884 0.221191H78.2962V4.61965H73.8884V0.221191Z" fill="#F1B508" /></svg>
  )
}

export default Garpix;