import React, {useState} from 'react';
import Layout from '../views';
import { CreditModal } from '../components/Credits';
import { Button } from '@garpix/component-system';


const CreditDetail = ({ ...props }) => {
  const [ isShowModal, setShowModal ] = useState(false);
  const showModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const { title, content, image_thumb, terms, term, amount, interest_rate, id } = props;
  return (
    <Layout {...props} className={'main--grad'}>
      <section className="services">
        <div className="container">
          <div className="services-intro2">
            <div className="services-intro2__top">
              <div className="services-intro2__left">
                <h1 className="services-intro2__heading">{title}</h1>
                <div className="services-intro2__text" dangerouslySetInnerHTML={{ __html: content }} />
              </div>
              <div className="services-intro2__right">
                <img className="services-intro2__img" src={image_thumb} alt={""} />
              </div>
            </div>
            <div className="services-intro2__btm">
              <div className="services-intro2__row">
                <div className="services-intro2__col">
                  <div className="services-intro2__value">{amount}</div>
                  <div className="services-intro2__label">Сумма кредита</div>
                </div>
                <div className="services-intro2__col">
                  <div className="services-intro2__value">{term}</div>
                  <div className="services-intro2__label">Срок кредита</div>
                </div>
                <div className="services-intro2__col">
                  <div className="services-intro2__value">{interest_rate}</div>
                  <div className="services-intro2__label">Процентная ставка</div>
                </div>
                <div className="services-intro2__col">
                  <Button className="btn btn--arrow" onClick={showModal}>
                    <span>Отправить заявку</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="credlists">
        <div className="container">
          <h2 className="credlists-heading">Условия</h2>
          <div className="credlists-items">
            {terms.map(item => {
              return (
                <div key={item.id} className="credlists-item">
                  <div className="credlists-item__label">{item.title}</div>
                  <div className="credlists-item__value" dangerouslySetInnerHTML={{ __html: item.content }}/>
                </div>
              )
            })}
          </div>
          <div className="credlists-btm">
            <Button className="btn btn--arrow" onClick={showModal}>
              <span>Отправить заявку</span>
            </Button>
          </div>
        </div>
      </section>
      <CreditModal credit={id} isShow={isShowModal} handleClose={closeModal} />
    </Layout>
  )
}

export default CreditDetail;
