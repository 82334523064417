import React from "react";
import { useStoreon } from 'storeon/react';
import { useFormik } from 'formik';
import { ClearField } from '@garpix/component-system';
import api from "../../api";
import Errors from "../../views/Errors";


const RestorePassword = () => {
  const { dispatch } = useStoreon();
  const handleSubmit = (params, { setSubmitting, resetForm, setStatus, setErrors }) => {
    api.restorePassword(params)
      .then(res => {
        resetForm();
        setStatus('success')
      })
      .catch(error => {
        const { response } = error;
        setSubmitting(false);
        if (response) {
          setErrors(response.data)
        }
      })
  }
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
  });
  const {
    errors,
    values,
    setFieldValue
  } = formik;
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <ClearField
            label="Электронная почта"
            required
            name="email"
            onChange={setFieldValue}
            value={values.email}
          />
          {errors.non_field ? (
            <Errors errors={errors.non_field} />
          ) : null}
          {formik.status === 'success' ? (
            <div>Запрос успешно отправлен. Проверьте свою почту</div>
          ) : null}
        </div>

        <div className="form-end">
          <input className="btn btn--full" type="submit" value="Выслать пароль" />
        </div>
      </form>
      <div class="modal-footer restore-footer">
        <p>
          <a href="#"
            onClick={() => dispatch('modal/toggle', { modal: 'login' })}>Вход</a>
        </p>
      </div>
    </>
  )
}

export {
  RestorePassword
}