import React from "react";
import { Container, Button, CounterInput } from "@garpix/component-system";
import ProductPrice from "../ProductPrice";
import Product from "../../components/Product";
import Swiper from "@garpix/react-swiper";
import { Link } from "react-router-dom";
import { getLegalFormName } from "../../utils";

const swiperSettings = {
  isOutRender: true,
  slidesPerView: 5,
  spaceBetween: 30,
  speed: 500,
  mousewheel: false,
  rebuildOnUpdate: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    1024: {
      slidesPerView: 5,
      spaceBetween: 20
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 20
    },
    568: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    319: {
      slidesPerView: 2,
      spaceBetween: 10
    }
  }
};

const DetailProduct = ({
  product,
  addToCart,
  params,
  similar_products = [],
  isOrdered = true
} = {}) => {
  const handleChangeProduct = count => addToCart({ count });
  const handleAddToCart = () => {
    addToCart();
  };

  const {
    title,
    content,
    price,
    old_price,
    image_thumb,
    producer_obj,
    shop_obj,
    unit = ""
  } = product;
  return (
    <>
      <section className="product">
        <Container>
          <div className="product-wrap">
            <div className="product-row">
              <div className="product-left">
                <img
                  className="product-img"
                  src={image_thumb}
                  alt="Фото продукта"
                />
              </div>
              <div className="product-right">
                <h1 className="product-heading">{title}</h1>
                {shop_obj ? (
                  <div className="product-manuf">
                    Торговая площадка:{" "}
                    <Link to={shop_obj.slug}>{shop_obj.title}</Link>
                  </div>
                ) : null}
                {producer_obj && !shop_obj ? (
                  <div className="product-manuf">
                    Производитель:{" "}
                    <Link to={producer_obj.slug}>
                      {getLegalFormName(producer_obj.legal_form, true)}{" "}
                      {producer_obj.title}
                    </Link>
                  </div>
                ) : null}
                <div className="product-prices">
                  <div className="product-price">
                    <ProductPrice price={price} old_price={old_price} />{" "}
                    {unit ? (
                      <strong className="product-price__unit">({unit})</strong>
                    ) : null}
                  </div>
                  <div className="product-buys buys">
                    {params ? (
                      <div className="product-quants quants">
                        <CounterInput
                          count={params.count}
                          name={"count"}
                          min={1}
                          max={1000}
                          onCountChange={handleChangeProduct}
                        />
                      </div>
                    ) : isOrdered ? (
                      <Button
                        onClick={handleAddToCart}
                        className="product-buy btn--arrow"
                        disabled={!isOrdered}
                      >
                        <span>В корзину</span>
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="product-desc"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </Container>
      </section>
      {similar_products.length > 0 ? (
        <section className="catslider">
          <Container>
            <h3 className="catslider-head">Похожие товары</h3>
            <div className="catslider-slider">
              <Swiper {...swiperSettings}>
                {similar_products.map(product => {
                  return (
                    <div key={product.id} className="swiper-slide">
                      <Product product={product} />
                    </div>
                  );
                })}
              </Swiper>
            </div>
          </Container>
        </section>
      ) : null}
    </>
  );
};

export default DetailProduct;
