import React from "react";

const ShopDetailTabs = ({ tabs = {}, setActiveTab, activeTab }) => {
  return (
    <div className="btabs__wrap">
      <ul className="btabs__list nav">
        {Object.keys(tabs).map((key, idx) => {
          return (
            <li key={`${key}-${idx}`} className="btabs__item">
              <a
                onClick={e => setActiveTab(e, key)}
                className={`btabs__link tab ${
                  activeTab === key ? "active" : ""
                }`}
              >
                {tabs[key]}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ShopDetailTabs;
