import React from "react";
import { instIcon, odIcon, fbIcon, vkIcon } from "../../images";
const classNames = require("classnames");

const SocialLink = ({ link = "", icon = "", alt = "" }) => {
  const classes = classNames({
    "shopage-social__item": true,
    "shopage-social__item_disabled": !link || link === ""
  });
  return (
    <a
      className={classes}
      href={link || link !== "" ? link : undefined}
      target="_blank"
    >
      <img src={icon} className="shopage-gallery__img" alt={alt} />
    </a>
  );
};

const ShopDescription = ({ shop_info }) => {
  const {
    district_obj,
    // offer_description,
    content,
    address,
    phone,
    email,
    partner_site,
    url_title,
    full_name,
    ok_link,
    fb_link,
    instagram_link,
    vk_link
  } = shop_info;

  return (
    <>
      {content && content !== "" ? (
        <div
          className="manuf-desc"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : null}

      <div className="manuf-row">
        {district_obj ? (
          <div className="manuf-addr">
            <div className="manuf-label">Район</div>
            <div className="manuf-value">{district_obj.title}</div>
          </div>
        ) : null}
        {address ? (
          <div className="manuf-addr">
            <div className="manuf-label">Юридический адрес</div>
            <div className="manuf-value">{address}</div>
          </div>
        ) : null}

        <div className="manuf-addr">
          <div className="manuf-col10">
            <div className="manuf-label">Контакты</div>
            <div className="manuf-value">
              <div className="manuf-row">
                {full_name ? (
                  <div className="manuf-cont">
                    <div className="manuf-value">{full_name}</div>
                  </div>
                ) : null}
                {partner_site ? (
                  <div className="manuf-cont">
                    <div className="manuf-value">
                      <a
                        href={partner_site}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url_title}
                      </a>
                    </div>
                  </div>
                ) : null}
                {email ? (
                  <div className="manuf-cont">
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {email}
                    </a>
                  </div>
                ) : null}
                {phone ? (
                  <div className="manuf-cont">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:${phone}`}
                    >
                      {phone}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="manuf-addr">
          <div className="manuf-label">Соц. сети:</div>
          <div className="shopage-social">
            <SocialLink
              link={fb_link}
              icon={fbIcon}
              alt="ссылка на страницу Facebook"
            />
            <SocialLink
              link={vk_link}
              icon={vkIcon}
              alt="ссылка на страницу Вконтакте"
            />
            <SocialLink
              link={instagram_link}
              icon={instIcon}
              alt="ссылка на страницу Instagram"
            />
            <SocialLink
              link={ok_link}
              icon={odIcon}
              alt="ссылка на страницу Одноклассники"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDescription;
