import api from "./api";

export const PATHS = {
  ALL: {
    path: "*",
    fetchInitialData: api.getPage
  }
};

export const optionsLoanTerm = [
  { value: 0, label: "12 месяцев" },
  { value: 1, label: "24 месяца" }
];

export const legalFormOptions = [
  { value: "pc", label: "АО" },
  { value: "llc", label: "ООО" },
  { value: "ie", label: "ИП" },
  { value: "p_coop", label: "Производственный кооператив" },
  { value: "farm", label: "Фермерское хозяйство" },
  { value: "ep", label: "Хозяйственное партнерство" }
];

export const USER_TYPE_NORMAL = "normal";
export const USER_TYPE_PARTNER = "partner";

export const CK_EDITOR_CONFIG = {
  uiColor: "#ffffff",
  toolbar: [
    ["Bold", "Italic", "Strike Through"],
    [
      "Cut",
      "Copy",
      "Paste",
      "Pasteasplaintext",
      "FormattingStyles",
      "Undo",
      "Redo"
    ],
    ["List", "Indent", "Blocks", "Align", "Bidi", "Paragraph"],
    ["Find", "Selection", "Spellchecker", "Editing"]
  ]
};

export const RESPONSE_STATUS = {
  error404: 404,
  error500: 500
};

export const ACCEPT_FILES_DOC = ".doc,.docx,.pdf,.jpg,.png";
export const ACCEPT_FILES_IMAGE = ".jpg,.png, .jpeg";

export const INITIAL_SHOP_VALUES = {
  address: "",
  phone: "",
  email: "",
  partner_site: "",
  district: undefined,
  service: undefined,
  title: "",
  category: undefined,
  offer_description: "",
  content: "",
  video_page: "",
  image_list: []
};

export const CATEGORY_TITLE = {
  services: "Услуги",
  product: "Товары",
  farm_rows: "Фермерские ряды",
}
