import React from "react";
import { Button } from "@garpix/component-system";
import FileUpload from "../../views/FileUpload";
import Errors from "../../views/Errors";
import { ACCEPT_FILES_DOC } from "../../const";

const FormStepTwo = ({
  isShow,
  setFieldValue,
  // handleChange,
  // values,
  errors,
  touched,
  backClick,
  handleSubmit,
  isSubmitting
}) => {
  return (
    <div className="form-step2" style={{ display: isShow ? "block" : "none" }}>
      <div onClick={backClick} className="form-back js-stepPrev">
        Назад
      </div>
      <div className="form-info">
        Для оформления заявки на получение займа необходимо прикрепить следующие
        документы в форматах .doc, .docx, .pdf, .jpg или .png
      </div>
      <div className="form-steps">
        <span>Шаг 2/2</span>
      </div>
      <div className="form">
        <div className="form-group">
          <FileUpload
            placeholder="Паспорт гражданина Российской Федерации"
            accept={ACCEPT_FILES_DOC}
            name="passport_rf"
            setFieldValue={setFieldValue}
          />
          {errors.passport_rf && touched.passport_rf ? (
            <Errors errors={errors.passport_rf} />
          ) : null}
        </div>
        <div className="form-group">
          <FileUpload
            placeholder="Справка о заработной плате с места работы (форма 2-НДФЛ) за последние 6 месяцев"
            accept={ACCEPT_FILES_DOC}
            name="form_two_ndfl"
            setFieldValue={setFieldValue}
          />
          {errors.form_two_ndfl && touched.form_two_ndfl ? (
            <Errors errors={errors.form_two_ndfl} />
          ) : null}
        </div>
        <div className="form-group">
          <FileUpload
            placeholder="Рекомендация территориальной организации Профсоюза"
            accept={ACCEPT_FILES_DOC}
            name="recommendation_organization_union"
            setFieldValue={setFieldValue}
          />
          {errors.recommendation_organization_union &&
          touched.recommendation_organization_union ? (
            <Errors errors={errors.recommendation_organization_union} />
          ) : null}
        </div>
        <div className="form-group">
          <FileUpload
            placeholder="Ходатайство первичной организации Профсоюза"
            accept={ACCEPT_FILES_DOC}
            name="petition_organization_union"
            setFieldValue={setFieldValue}
          />
          {errors.petition_organization_union &&
          touched.petition_organization_union ? (
            <Errors errors={errors.petition_organization_union} />
          ) : null}
        </div>
        <div className="form-group">
          <FileUpload
            placeholder="Свидетельство ИНН (индивидуальный номер налогоплательщика)"
            accept={ACCEPT_FILES_DOC}
            name="inn"
            setFieldValue={setFieldValue}
          />
          {errors.inn && touched.inn ? <Errors errors={errors.inn} /> : null}
        </div>
        <Errors errors={errors.registration_number_cpc} />
        <div className="form-end">
          <div className="form-group">
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className="btn--full"
            >
              Отправить заявку
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormStepTwo };
