import React from 'react';
import Pagination from 'react-paginating';
import { PAGE_SIZE } from '../../api'
import { preventDefault } from '../../utils'

const PaginateLk = ({
  activePage,
  count,
  params = {},
  limit,
  loadData
}) => {

  return (
    <Pagination
      total={count}
      limit={limit ? limit : PAGE_SIZE}
      pageCount={5}
      currentPage={activePage}>
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
      }) => (
        <div className="pagi">
          {hasPreviousPage ? (
            <a className="pagi-link pagi-prev"
              onClick={preventDefault(() => loadData(previousPage, { ...params, page: previousPage }))}
            />
          ) : null}
          {pages.length > 1 ? (
            <>{pages.map(page => <a
              key={page}
              className={`pagi-link ${currentPage === page ? 'pagi-link--active' : ''}`}
              onClick={preventDefault(() => loadData(page, { ...params, page }))}>{page}</a>)}
            </>
          ) : null}
          {hasNextPage ? (
            <a className="pagi-link pagi-next"
              onClick={preventDefault(() => loadData(nextPage, { ...params, page: nextPage }))}
            />
          ) : null}
        </div>
      )}
    </Pagination>
  )
}

export default PaginateLk;