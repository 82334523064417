import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { Button, Modal } from "@garpix/component-system";
import PartnerProfile from "./PartnerProfile";
import PartnerOrders from "./PartnerOrders";
import UserProducts from "./UserProducts";
import UserServices from "./UserServices";
import Shop from "./Shop";
import CreateOrUpdateShop from "./CreateOrUpdateShop";
import { preventDefault } from "../../utils";
import api from "../../api";
import MemberOrders from "./MemberOrders";

const Partner = ({ currentUser }) => {
  const history = useHistory();
  const { isCreateShop, page } = useStoreon("isCreateShop", "page");
  const { dispatch } = useStoreon();
  const [activeTab, setTab] = useState("tb1");
  const [showModal, setShowModal] = useState(null);
  const [shopId, setShopId] = useState(null);

  const current_page = page.data.page;
  const { is_was_trained } = currentUser;
  const { help_slug } = current_page.site_configuration;

  useEffect(() => {
    if (currentUser && is_was_trained === false) {
      setShowModal("modal-help");
    }
  }, []);

  const handleClose = () => {
    setShowModal(null);
    setShopId(null);
  };

  const changeTabs = tab => {
    setTab(tab);
    dispatch("shop/toggle", false);
  };

  const showModalDelete = id => {
    setShowModal("delete-shop");
    setShopId(id);
  };

  const handleDelete = () => {
    if (shopId) {
      api
        .deleteProducerShop(shopId)
        .then(res => {
          handleClose();
          dispatch("shop/toggle", { isCreateShop: false });
        })
        .catch(err => console.log(err, "err del"));
    }
  };

  const userUpdate = ({ callback }) => {
    api
      .updateUser(currentUser.id, { is_was_trained: true })
      .then(res => {
        dispatch("user/get", {
          callback: () => callback()
        });
      })
      .catch(error => {
        handleClose();
      });
  };

  const closeHelpModal = () => {
    userUpdate({ callback: handleClose });
  };

  const goToFaq = () => {
    const callback = () => history.push(`/${help_slug}`);
    userUpdate({ callback });
  };

  return (
    <section className="cabinet">
      <div className="cabinet-tabs">
        <div className="container">
          <div className="tabs__wrap">
            <ul className="tabs__list nav">
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => changeTabs("tb1"))}
                  className={`tabs__link ${
                    activeTab === "tb1" ? "active show" : ""
                  }`}
                  data-toggle="tab"
                  href="#tb1"
                  role="tab"
                >
                  Профиль
                </a>
              </li>
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => changeTabs("tb2"))}
                  className={`tabs__link ${
                    activeTab === "tb2" ? "active show" : ""
                  }`}
                  data-toggle="tab"
                  href="#tb2"
                  role="tab"
                >
                  Заказы
                </a>
              </li>
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => changeTabs("tb3"))}
                  className={`tabs__link ${
                    activeTab === "tb3" ? "active show" : ""
                  }`}
                  data-toggle="tab"
                  href="#tb3"
                  role="tab"
                >
                  Мои покупки
                </a>
              </li>
              {currentUser.partner_farm ? (
                <li className="tabs__item">
                  <a
                    onClick={preventDefault(() => changeTabs("tb4"))}
                    className={`tabs__link ${
                      activeTab === "tb4" ? "active show" : ""
                    }`}
                    data-toggle="tab"
                    href="#tb4"
                    role="tab"
                  >
                    Для фермеров
                  </a>
                </li>
              ) : null}
              {!currentUser.partner_farm ? (
                <>
                  <li className="tabs__item">
                    <a
                      onClick={preventDefault(() => changeTabs("tb5"))}
                      className={`tabs__link ${
                        activeTab === "tb5" ? "active show" : ""
                      }`}
                      data-toggle="tab"
                      href="#tb5"
                      role="tab"
                    >
                      Товары и услуги
                    </a>
                  </li>
                  <li className="tabs__item">
                    <a
                      onClick={preventDefault(() => changeTabs("tb6"))}
                      className={`tabs__link ${
                        activeTab === "tb6" ? "active show" : ""
                      }`}
                      data-toggle="tab"
                      href="#tb6"
                      role="tab"
                    >
                      Торговая площадка
                    </a>
                  </li>
                </>
              ) : null}
              <li className="tabs__item tabs__item_help">
                <a
                  className={`tabs__link tabs__link_help`}
                  href={`/${help_slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Помощь
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tabs__content">
          <div
            className={`tabs__pane ${activeTab === "tb1" ? "show active" : ""}`}
            id="tb1"
            role="tabpanel"
          >
            <PartnerProfile currentUser={currentUser} />
          </div>
          <div
            className={`tabs__pane ${activeTab === "tb2" ? "show active" : ""}`}
            id="tb2"
            role="tabpanel"
          >
            <PartnerOrders />
          </div>
          <div
            className={`tabs__pane ${activeTab === "tb3" ? "show active" : ""}`}
            id="tb3"
            role="tabpanel"
          >
            <MemberOrders />
          </div>
          <div
            className={`tabs__pane ${activeTab === "tb4" ? "show active" : ""}`}
            id="tb4"
            role="tabpanel"
          >
            <UserProducts />
          </div>
          <div
            className={`tabs__pane ${activeTab === "tb5" ? "show active" : ""}`}
            id="tb5"
            role="tabpanel"
          >
            <UserServices />
          </div>
          <div
            className={`tabs__pane ${activeTab === "tb6" ? "show active" : ""}`}
            id="tb6"
            role="tabpanel"
          >
            {!isCreateShop ? (
              <Shop />
            ) : (
              <CreateOrUpdateShop deleteShop={showModalDelete} />
            )}
          </div>
        </div>
      </div>
      {showModal === "delete-shop" ? (
        <Modal
          centered={true}
          size={"sm"}
          show={!!showModal}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление торговой площадки</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"modal-center"}>
              <div className={"modal-text"}>
                Вы точно хотите удалить выбранную торговую площадку?
              </div>
              <div className={"modal-end"}>
                <div className={"modal-btns"}>
                  <Button onClick={handleClose} className={"btn--gray"}>
                    Отменить
                  </Button>
                  <Button onClick={handleDelete} className={"btn--red"}>
                    Удалить
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {showModal === "modal-help" ? (
        <Modal
          centered={true}
          size={"sm"}
          show={!!showModal}
          onHide={closeHelpModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Помощь</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"modal-center"}>
              <div className={"modal-text"}>
                Ответы на часто задаваемые вопросы вы можете посмотреть в
                разделе помощь.
              </div>
              <div className={"modal-end"}>
                <div className={"modal-btns"}>
                  <Button onClick={closeHelpModal} className={"btn--gray"}>
                    Закрыть
                  </Button>
                  <Button onClick={goToFaq} className={"btn--red"}>
                    Перейти в раздел
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </section>
  );
};

export default Partner;
