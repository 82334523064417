import siteConfigurationSerializer from "./site_configuration";
import blogSerializer from './blog';
import breadcrumbsSerializer from "./breadcrumbs";


const newsPageSerializer = page => {
  const { init_state } = page
  const { page_info, blog_posts } = init_state;
  blog_posts.results = blog_posts.results.map(blogSerializer)
  return {
    ...init_state,
    ...page_info,
    blog_posts: blog_posts,
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(page_info)
  }
}

export default newsPageSerializer;