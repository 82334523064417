import siteConfigurationSerializer from "./site_configuration";
import breadcrumbsSerializer from "./breadcrumbs";

const pageSerializer = page => {
  const { init_state } = page
  const { page_info } = init_state;
  const pageInfo = page_info;
  return {
    ...init_state,
    ...pageInfo,
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(page_info)
  }
}

export default pageSerializer;
