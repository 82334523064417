import React from 'react';
import { Link } from 'react-router-dom';

const CategoryBtnMore = ({ cat_url }) => {
    return (
        <>
            <div className="text-center mb-5">
                <Link to={cat_url} className="btn btn-more btn--arrow btn-primary">
                    <span>Смотреть все</span>
                </Link>
            </div>
        </>
    )
}

export { CategoryBtnMore };

