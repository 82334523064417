import React from "react";
import Layout from "../views";
import DetailShop from "../components/DetailShop";

const DetailShopPage = ({ shop_info, ...props }) => {
  return (
    <Layout {...props}>
      <DetailShop shop_info={shop_info} />
    </Layout>
  );
};

export default DetailShopPage;
