import React from 'react';
import { Formik } from "formik";
import { useStoreon } from 'storeon/react'
import { Form } from '@garpix/component-system';
import Errors from '../../views/Errors';
import ImageUpload from '../../views/ImageUpload';
import Avatar from "../../views/Avatar";
import ProfileLinks from './ProfileLinks';
import Logout from './Logout';
import api from '../../api';
import PartnerContact from "./PartnerContact";
import { Select } from '../Select';
import { legalFormOptions } from "../../const";
import baseCustomStyles from "../Select/style";
import { PhoneInput, EmailInput, UnionTicket } from '../../views/Fields';
import { getLegalFormName } from '../../utils';
import SelectDistrict from "../Select/SelectDistrict";
import { PartnerAccountSchema } from '../../schema';
import { EmailConfirmed } from "./EmailConfirmed";
import { IMaskInput } from 'react-imask';



const PartnerProfile = ({
  currentUser
}) => {
  const { dispatch } = useStoreon();
  const { producer, user_contacts } = currentUser;

  const update = (params, { setSubmitting, setErrors }) => {
    const { id } = producer;
    const newParams = { ...params };
    if (newParams.legal_form) {
      newParams.legal_form = newParams.legal_form.value
    }
    if (newParams.district && newParams.district) {
      newParams.district = newParams.district.value
    }
    api.updateProducer(id, newParams)
      .then(res => {
        dispatch('user/get', {
          callback: () => {
            dispatch('modal/toggle', { modal: 'success-save' });
            setSubmitting(false);
          }
        })
      })
      .catch(error => {
        setSubmitting(false);
        const { response } = error;
        if (response) {
          setErrors(response.data)
        }
      })
  };

  const addUserContacts = () => {
    api.createExtraContact()
      .then(res => dispatch('user/get'))
  }

  const { district_obj } = producer
  return (
    <div className="container">
      <Formik
        validationSchema={PartnerAccountSchema}
        enableReinitialize={true}
        initialValues={{
          title: producer.title,
          inn: producer.inn,
          address: producer.address,
          phone: producer.phone,
          contact_name: producer.contact_name,
          email: producer.email,
          about: producer.about,
          legal_form: producer.legal_form ? {
            value: producer.legal_form,
            label: producer.legal_form_display
          } : '',
          image: producer.image_thumb,
          district: district_obj ? {
            value: district_obj.id,
            label: district_obj.title
          } : '',
        }}
        onSubmit={update}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="profile">
            <div className="profile-top">
              <div className="profile-top__left">
                <ImageUpload>
                  {({
                    preview,
                    onSelectFile,
                    selectedFile
                  }) => (
                    <Avatar
                      onSelectFile={onSelectFile}
                      setFieldValue={setFieldValue}
                      selectedFile={selectedFile}
                      preview={preview}
                      avatar={values.image}
                      name="image"
                    />
                  )}
                </ImageUpload>
                {errors.image && touched.image ? (
                  <Errors errors={errors.image} />
                ) : null}
              </div>
              <div className="profile-top__right">
                <div className="profile-name">{getLegalFormName(producer.legal_form)} «{producer.title}»</div>
              </div>
              <div className="profile-exit"><Logout /></div>
            </div>
            <div className="profile-mid">
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Орг.-правовая форма</div>
                    <Select
                      options={legalFormOptions}
                      styles={baseCustomStyles}
                      name="legal_form"
                      onChange={(value) => setFieldValue('legal_form', value)}
                      value={values.legal_form}
                    />
                    {errors.legal_form && touched.legal_form ? (
                      <Errors errors={errors.legal_form} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Название</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.title}
                      name={"title"}
                    />
                    {errors.title && touched.title ? (
                      <Errors errors={errors.title} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">ИНН</div>
                    <IMaskInput 
                        mask={Number}
                        maxLength={12}
                        className="form-input"
                        type="text"
                        onChange={handleChange}
                        value={values.inn}
                        name={"inn"}
                    />
                    {errors.inn && touched.inn ? (
                      <Errors errors={errors.inn} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Район</div>
                    <SelectDistrict
                      value={values.district}
                      name="district"
                      onChange={value => {
                        setFieldValue('district', value);
                      }}
                    />
                    {errors.district && touched.district ? (
                      <Errors errors={errors.district} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col6">
                  <div className="form-group">
                    <div className="form__label">Юридический адрес</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.address}
                      name={"address"}
                    />
                    {errors.address && touched.address ? (
                      <Errors errors={errors.address} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Номер телефона</div>
                    <PhoneInput
                      className="form-input"
                      setFieldValue={setFieldValue}
                      value={values.phone}
                      name={"phone"}
                    />
                    {errors.phone && touched.phone ? (
                      <Errors errors={errors.phone} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">ФИО</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.contact_name}
                      name={"contact_name"}
                    />
                    {errors.contact_name && touched.contact_name ? (
                      <Errors errors={errors.contact_name} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">E-mail</div>
                    <EmailInput
                      className="form-input"
                      setFieldValue={setFieldValue}
                      value={values.email}
                      name={"email"}
                    />
                    {errors.email && touched.email ? (
                      <Errors errors={errors.email} />
                    ) : null}
                  </div>
                </div>
              </div>
              {user_contacts.map((item, index) => <PartnerContact contact={item} key={item.id} />)}
              {user_contacts.length < 5 ? (
                <div className="profile-row">
                  <div className="profile-col9">
                    <div className="form-group">
                      <button type={"button"} className="btn btn--link" onClick={addUserContacts}>+ добавить контакт</button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="profile-row">
                <div className="profile-col9">
                  <div className="form-group">
                    <div className="form__label">О компании
                      <textarea
                        className="form-textarea"
                        onChange={handleChange}
                        value={values.about}
                        name={"about"}
                      /></div>
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col9">
                  {!currentUser.is_email_confirmed ? (
                    <EmailConfirmed />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="profile-btm">
              <ProfileLinks />
              <div className="profile-save">
                <button disabled={isSubmitting} className="btn">Сохранить</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PartnerProfile;