import React from 'react';
import Layout from '../views';
import Account from '../components/Account';


const AccountPage = (props) => {
  return (
    <Layout {...props} className="main--bg">
      <Account {...props} />
    </Layout>
  )
}

export default AccountPage;
