import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { Product as ProductLayout } from "../../views/Product";

const Product = ({ product, size, ...props }) => {
  const { dispatch, cart, currentUser } = useStoreon("cart", "currentUser");
  const [isOrdered, setOrdered] = useState(true);

  useEffect(() => {
    if (product && currentUser) {
      if (product.producer && currentUser.producer) {
        setOrdered(product.producer !== currentUser.producer.id);
      }
    }
  }, [product, currentUser]);

  const addToCart = (params = { count: 1 }) => {
    if (currentUser && currentUser.status === true) {
      dispatch("cart/update", {
        product,
        params
      });
      return true;
    }
    dispatch("modal/toggle", { modal: "login" });
  };

  const removeFromCart = () => {
    dispatch("cart/remove", {
      id: product.id
    });
  };

  const addToWishlist = (params = {}) => {
    dispatch("wishlist/update", {
      product,
      params
    });
  };

  const removeFromWishlist = () => {
    dispatch("wishlist/remove", {
      id: product.id
    });
  };

  const params = cart[product.id] ? cart[product.id].params : undefined;

  return (
    <ProductLayout
      addToCart={addToCart}
      removeFromCart={removeFromCart}
      addToWishlist={addToWishlist}
      removeFromWishlist={removeFromWishlist}
      product={product}
      params={params}
      size={size}
      isOrdered={isOrdered}
      {...props}
    />
  );
};

export default Product;
