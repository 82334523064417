import React from "react";
import { FetcherList, dataStates } from "@garpix/fetcher";
import { Button } from "@garpix/component-system";

import api from "../../api";
import ShopMap from "./ShopMap";

const ShopAddresses = ({ shop_id }) => {
  return (
    <FetcherList
      api={api.getShopAddresses}
      isHistoryPush={false}
      initFilter={{
        page: 1,
        page_size: 5,
        shop_id: shop_id
      }}
    >
      {({ results = [], showMore, status, isNext }) => {
        return (
          <>
            <div className="table-wrap">
              {status === dataStates.loaded && results.length > 0 ? (
                <ShopMap shop_id={shop_id} />
              ) : null}
              <table className="table servlist-table">
                <tr>
                  <th>
                    <span className="servlist-table__taddr">Адрес</span>
                  </th>
                </tr>
                {status === dataStates.loaded && results.length === 0 ? (
                  <tr>
                    <td>
                      <span>Адреса пока не добавлены</span>
                    </td>
                  </tr>
                ) : null}
                {status === dataStates.loaded && results.length > 0 ? (
                  <>
                    {results.map(addr => {
                      return (
                        <tr key={addr.id}>
                          <td>
                            <span>{addr.address}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </table>
              {isNext ? (
                <Button onClick={showMore} className="btn loadmore">
                  <span>ПОКАЗАТЬ ЕЩЁ</span>
                </Button>
              ) : null}
            </div>
          </>
        );
      }}
    </FetcherList>
  );
};

export default ShopAddresses;
