import React, { useEffect, useState } from "react";
import dayjs from "../../utils/dayjs";
import { currencyFormat } from "../../utils";
import { orderModel } from '../../business';
import { OrderProductItem } from './OrderProductItem';
import { getLegalFormName } from '../../utils';

const STATUS_STYLE = orderModel.STATUS_STYLE;


const OrderDetail = ({ currentOrder = {} }) => {
  const [producerOrder, setProducerOrder] = useState({});
  const [openCart, setOpenCart] = useState(null);

  useEffect(() => {
    if (currentOrder.id) {
      setOpenCart(null);
      orderModel.setOrder(currentOrder)
      const result = orderModel.groupByProducer()
      setProducerOrder(result);
    }
  }, [currentOrder.id])
  if (!currentOrder.id) return null;

  return (
    <>
      <div className="orders-name">Заказ <strong>{currentOrder.id}</strong> от {dayjs(currentOrder.created_at).format('DD.MM.YYYY')}</div>
      <div className="orders-block">
        <div className="cart-head">
          <div className="cart-head__name">Продавец</div>
          <div className="cart-head__price">Позиции</div>
          <div className="cart-head__sum">Сумма</div>
        </div>
        <div className="cart-body accordion" id="cartitems">
          {Object.keys(producerOrder).map(key => {
            const {producer, products, total_qty, total, status_display, status} = producerOrder[key];
            const isOpen = openCart === key;
            return (
              <div key={key} className="cart-items accordion__block">
                <div 
                  onClick={() => setOpenCart(isOpen ? null : key)}
                  className={`cart-items__head accordion__heading ${isOpen ? '' : 'collapsed'}`} 
                  data-toggle="collapse"
                  aria-expanded={isOpen} 
                  aria-controls={`cart-items-${key}`}>
                  <div className="cart-items__name">
                    <span>{getLegalFormName(producer.legal_form)} {producer.title}</span> 
                    <span className={`orders-${STATUS_STYLE[status]}`}>{status_display}</span>
                  </div>
                  <div className="cart-items__quant">{total_qty}</div>
                  <div className="cart-items__sum">{currencyFormat(total)}</div>
                </div>
                <div 
                  className={`cart-items__body accordion__content ${isOpen ? 'collapse show' : 'collapse'}`} 
                  id={`cart-items-${key}`}>
                  <div className="cart-items__wrap">
                    {products.map(item => {
                      const { product, item_total, qty, id } = item;
                      return (
                        <OrderProductItem key={id} product={product} qty={qty} item_total={item_total} />
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export {
  OrderDetail
}