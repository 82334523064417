import { PlaceholderImage } from '../../images';



const productSerializer = product => {
  const { productimage_set, image_url, image_thumb, price } = product;
  const images = productimage_set;
  return {
    ...product,
    images,
    price: Number(price),
    // .toFixed(0),
    image: image_url ? image_url : PlaceholderImage,
    image_thumb: image_thumb ? image_thumb : PlaceholderImage
  }
}

export default productSerializer