import React from "react";
import { AvaImage } from "../../images";
import { ACCEPT_FILES_IMAGE } from "../../const";
const Avatar = ({
  onSelectFile,
  setFieldValue,
  selectedFile,
  preview = AvaImage,
  avatar,
  name = "avatar"
}) => {
  const removeImage = () => {
    setFieldValue(name, null);
    onSelectFile(null);
  };
  const handleChange = event => {
    const file = event.currentTarget.files[0];
    setFieldValue(name, file);
    onSelectFile(file);
  };

  return (
    <div className="profile-ava">
      <button
        type={"button"}
        onClick={removeImage}
        className="profile-ava__delete"
        title="Удалить фото"
      />
      <div className="profile-ava__wrap">
        <img
          className="profile-ava__img"
          src={selectedFile ? preview : avatar ? avatar : preview}
          alt={"Аватар"}
        />
        <div className="profile-ava__file">
          <input
            name={name}
            className="profile-ava__file-input"
            type="file"
            accept={ACCEPT_FILES_IMAGE}
            onChange={handleChange}
          />
          <div className="profile-ava__file-label">
            {selectedFile || avatar ? "Изменить" : "Загрузить"} фото
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
