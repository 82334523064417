import { PlaceholderImage } from '../../images';

const blogSerializer = post => {
  const { image_thumb } = post;
  return {
    ...post,
    image_thumb: image_thumb ? image_thumb : PlaceholderImage
  }
}

export default blogSerializer;