import React, { useState } from "react";
import { useFormik } from 'formik';
import Errors from "../../views/Errors";
import api from "../../api";
import { EmailInput, PhoneInput } from '../../views/Fields';


const PartnerContact = ({
  contact
}) => {
  const [ isDelete, setDelete ] = useState(false);
  const submit = ({ id, ...params }) => {
    api.updateExtraContact(id, params)
  }
  const formik = useFormik({
    initialValues: contact,
    onSubmit: submit,
  });

  const deleteContact = () => {
    setDelete(true);
    api.deleteExtraContact(contact.id);
  }

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue
  } = formik;
  if(isDelete) return null;
  return (
    <div className="profile-row">
      <div className="profile-col3">
        <div className="form-group">
          <div className="form__label">Номер телефона</div>
          <PhoneInput
            className="form-input"
            setFieldValue={setFieldValue}
            value={values.phone}
            name={"phone"}
            onBlur={handleSubmit}
          />
          {errors.phone && touched.phone ? (
            <Errors errors={errors.phone} />
          ) : null}
        </div>
      </div>
      <div className="profile-col3">
        <div className="form-group">
          <div className="form__label">ФИО</div>
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            onBlur={handleSubmit}
            value={values.contact_name}
            name={"contact_name"}
          />
          {errors.contact_name && touched.contact_name ? (
            <Errors errors={errors.contact_name} />
          ) : null}
        </div>
      </div>
      <div className="profile-col3">
        <div className="form-group">
          <button onClick={deleteContact} type="button" className="profile-ava__delete" title="Удалить контакт"></button>
          <div className="form__label">E-mail</div>
          <EmailInput
            className="form-input"
            setFieldValue={setFieldValue}
            value={values.email}
            name={"email"}
            onBlur={handleSubmit}
          />
          {errors.email && touched.email ? (
            <Errors errors={errors.email} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PartnerContact;