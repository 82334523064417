import React from 'react';
import { useStoreon } from 'storeon/react';
import { preventDefault } from '../../utils';

const PartnersBtns = ({ contract }) => {
  const { dispatch } = useStoreon();
  const openRegistration = preventDefault(() => dispatch('modal/toggle', { modal: 'registration-partner' }))
  return (
    <>
      <div className="partner-btns">
        <a
          className="btn partner-btns__be"
          onClick={openRegistration}>Стать партнёром</a>
        <a
          className="btn btn--link partner-btns__doc"
          href={contract}
          download>Скачать договор о сотрудничестве</a>
      </div>
    </>
  )
}

export default PartnersBtns;