const breadcrumbsSerializer = (page, parentKey) => {
  const { id, title, slug, breadcrumbs } = page;
  const result = [{
    'title': 'Главная',
    'slug': '/'
  }];
  if(breadcrumbs) {
    return [...result, ...breadcrumbs];
  }
  if(page[parentKey]) {
    const { id, title, slug } = page[parentKey];
    result.push({
      id, title, slug
    })
  }
  result.push({
    id, title, slug
  })
  return result
}

export default breadcrumbsSerializer;