import React from 'react';
import Layout from '../views';
import ContainerFull from "../views/ContainerFull";


const InfoPage = ({ content, created_at, ...props }) => {
  return (
    <Layout {...props}>
      <ContainerFull>
        <div className="blog-details">
          <div className="blog-content">
            <h1>{props.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </ContainerFull>
    </Layout>
  )
}

export default InfoPage
