import React from 'react';
import Layout from '../views';
import { Catalog } from '../components/Catalog';


const CatalogPage = ({products, ...props}) => {
  return (
    <Layout {...props}>
      <Catalog initProducts={products} />
    </Layout>
  )
}

export default CatalogPage
