import { useEffect, useState } from "react";

const useLimitData = (data, limit) => {
    const [limitData, setProducts] = useState();
    useEffect(() => {
        const limitData = data?.results.slice(0, limit);
        setProducts(limitData)
    }, [data])
    return limitData
}

export { useLimitData }