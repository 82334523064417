import siteConfigurationSerializer from "./site_configuration";
// import breadcrumbsSerializer from "./breadcrumbs";

const servicesListSerializer = page => {
  const { init_state } = page
  const { page_info, slider } = init_state;
  const pageInfo = page_info;
  return {
    ...init_state,
    ...pageInfo,
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    slider: slider && slider.length > 0 ? slider[0].sliderimage_set : [],
    breadcrumbs: []
  }
}

export default servicesListSerializer;
