import { currencyFormat, preventDefault } from "../../utils";
import dayjs from "../../utils/dayjs";
import React from "react";
import { usernameFormat } from '../../utils';
import { orderModel } from '../../business';

const { ORDER_STATUSES_NAME } = orderModel

const OrderItem = ({
  item,
  currentOrder,
  setActiveOrder,
  description = "producer"
}) => {
  const { user_obj, orderitem_set, status } = item;
  return (
    <a
      onClick={preventDefault(() => setActiveOrder(item))}
      className={`orders-list-item ${currentOrder.id === item.id ? 'orders-list-item--active' : ''}`}
      href="#">
      <div className="orders-list-item__left">
        <div className="orders-list-item__num">
          № {item.id} - <span className="orders-list-item__desc">{ORDER_STATUSES_NAME[status]}</span>
        </div>
        <div className="orders-list-item__desc">
          {description === 'producer' ? (
            orderitem_set.map(item => item.producer_obj.title).join(', ')
          ) : null}
          {description === 'user' ? (
            <div>{usernameFormat(user_obj)}</div>
          ) : null}
        </div>
      </div>
      <div className="orders-list-item__right">
        <div className="orders-list-item__date">{dayjs(item.created_at).format('DD.MM.YYYY')}</div>
        <div className="orders-list-item__sum">{currencyFormat(item.total)}</div>
      </div>
    </a>
  )
}

export {
  OrderItem
}