import { FetcherList } from "@garpix/fetcher";
import React from "react";
import { useStoreon } from "storeon/react";
import api from "../../api";
import './category.scss';
import { CategoryTemplate } from "./CategoryTemplate";

const CategoryProducts = ({ initProducts = {}, producer, categories, catName }) => {
  const { currentUser } = useStoreon("currentUser");
  const district_obj = currentUser && currentUser.district_obj ? currentUser.district_obj : null;
  const filterOptions = [
    { value: 'new', label: 'Сначала новые' },
    { value: 'old', label: 'Сначала старые' },
  ]

  return (
    <FetcherList
      api={api.getProducts}
      initData={initProducts}
    >
      {({
        count,
        results = [],
        activePage,
        loadData,
        showMore,
        isNext,
        filterParams
      }) => {
        const filterParamsKey = Object.keys(filterParams).length;
        return (
          <CategoryTemplate
            count={count} results={results} isNext={isNext}
            categories={categories} loadData={loadData}
            filterParams={filterParams} showMore={showMore}
            activePage={activePage} filterParamsKey={filterParamsKey}
            producer={producer} district_obj={district_obj} filterOptions={filterOptions}
            isProduct={true}
          />
        );
      }}
    </FetcherList>
  );
};

export { CategoryProducts };

