import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { useStoreon } from 'storeon/react'
import { Form, Button, PasswordField } from '@garpix/component-system';
import Errors from "../../views/Errors";
import { useFormik } from 'formik';
import { RestorePasswordSetSchema } from '../../schema';
import api from '../../api';
import qs from 'query-string';


const RestorePasswordSetPassword = ({ location }) => {
  const { dispatch } = useStoreon();
  const [isSend, setSend] = useState(false);

  const onSubmit = (params, { setSubmitting, setErrors }) => {
    const { key } = qs.parse(location.search);
    // const key = '';
    api.restorePasswordSetPassword({...params, reset_key: key})
      .then(data => {
        setSend(true)
      })
      .catch(error => {
        if (error.response) {
          setSubmitting(false)
          setErrors(error.response.data)
        }
      })
  }
  const formik = useFormik({
    initialValues: {
      password: '',
      password2: ''
    },
    validationSchema: RestorePasswordSetSchema,
    onSubmit: onSubmit,
  });
  const {
    errors,
    handleChange,
    values,
    isSubmitting,
    touched,
    handleSubmit
  } = formik
  if(isSend === true) {
    return (
      <div>
        <p>Смена пароля прошла успешно! Теперь можно авторизоваться.</p>
        <div className="form-group">
          <Button onClick={() => dispatch('modal/toggle', { modal: 'login' })}>Вход</Button>
        </div>
      </div>
    )
  }
  return (
    <Form onSubmit={handleSubmit} autoComplete='false'>
      <h1>Восстановление пароля</h1>
      <div className="form-group">
        <PasswordField
          label="Новый пароль"
          name="password"
          onChange={handleChange}
          value={values.password}
        />
        {errors.password && touched.password ? (
          <Errors errors={errors.password} />
        ) : null}
      </div>
      <div className="form-group">
        <PasswordField
          label="Пароль ещё раз"
          name="password2"
          onChange={handleChange}
          value={values.password2}
        />
        {formik.errors.password2 && formik.touched.password2 ? (
          <Errors errors={formik.errors.password2} />
        ) : null}
      </div>
      {errors.non_field ? <Errors errors={errors.non_field} /> : null}
      <div className="form-end">
        <div className="form-group">
          <Button type={"submit"} disabled={isSubmitting} className="btn--full">Сменить</Button>
        </div>
      </div>
    </Form>
  )
}

export default withRouter(RestorePasswordSetPassword);