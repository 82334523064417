import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from '../../utils/dayjs';


const Post = ({ image_thumb, title, slug, created_at }) => {
  return (
    <Link className="news-item" to={slug}>
      <div className="news-item__imagewrap">
        <div className="news-item__image" style={{ backgroundImage: `url(${image_thumb})` }} />
      </div>
      <div className="news-item__content">
        <div className="news-item__title">{title}</div>
        <div className="news-item__date">{dayjs(created_at).format('DD MMMM YYYY')}</div>
      </div>
    </Link>
  )
}

export default Post;