import React from "react";
import { Fetcher } from "@garpix/fetcher";
import Home from "./Home";
import Services from "./Services";
import Catalog from "./Catalog";
import Search from "./Search";
import Credits from "./Credits";
import Cart from "./Cart";
import ServiceDetail from "./ServiceDetail";
import Account from "./Account";
import ServicesParther from "./ServicesPartner";
import Product from "./Product";
import Post from "./Post";
import News from "./News";
import InfoPage from "./Info";
import FarmPage from "./Farm";
import EmptyPage from "./Empty";
import ErrorPage from "./Error";
import CreditDetail from "./CreditDetail";
import RestorePasswordSetPassword from "./RestorePasswordSetPassword";
import LPParther from "./LPParther";
import ConfirmEmail from "./ConfirmEmail";
import DetailShopPage from "./DetailShopPage";
import FAQPage from "./FAQPage";
import CategoryPage from "./CategoryPage"

const PAGE_TYPES = {
  1: Home,
  2: InfoPage,
  3: Product,
  4: Catalog,
  5: InfoPage,
  6: FarmPage,
  7: Account,
  8: Cart,
  9: RestorePasswordSetPassword,
  10: InfoPage,
  12: Search,
  13: EmptyPage,
  14: Credits,
  15: CreditDetail,
  16: News,
  17: Post,
  18: ConfirmEmail,
  19: InfoPage,
  20: Services,
  21: ServiceDetail,
  22: ServicesParther,
  23: LPParther,
  24: DetailShopPage,
  25: FAQPage,
  26: CategoryPage, 
  27: CategoryPage,
};

const Combine = props => {
  return (
    <Fetcher {...props} paramsKey={"0"}>
      {(data, error) => {
        if (data === null && error !== null) return <ErrorPage error={error} />;
        if (!data) return null;
        const { pageType, page } = data;
        const Page = PAGE_TYPES[pageType];
        return <Page {...page} />;
      }}
    </Fetcher>
  );
};

export default Combine;
