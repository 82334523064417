import React from 'react';
import { Container, Row, Col } from '@garpix/component-system';

const ContainerFull = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

export default ContainerFull;