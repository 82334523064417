import React from "react";
import {Modal} from "@garpix/component-system";
import EmailChang from '../Account/EmailChange';


const EmailChangeModal = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Сменить е-mail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EmailChang />
      </Modal.Body>
    </>
)
}

export default EmailChangeModal;
