import { Row } from '@garpix/component-system'
import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './card_menu.scss'

const CardMenu = ({banners}) => {
    return (
        <Row md={2} sm={2} xs={1} className="card-menu mb-5">
            {banners && banners.map(banner => {
                return (
                    <Col key={banner.id}>
                        <div className="card-menu__item mb-2">
                            <img className="card-menu__img" src={banner.image_url} alt={banner.title} />
                            <Link to={banner.url}>
                                <h3 className="card-menu__title">{banner.title}</h3>
                            </Link>
                        </div>
                    </Col> 
                )
            })}
        </Row>
    )
}

export { CardMenu }