import React from "react";
import Rating from "react-rating";
import { Formik } from "formik";
import { Form, Button } from "@garpix/component-system";
import { StarEmpty, StarFull } from "./RaitingStars";
import Errors from "../../views/Errors";
import { CreateReviewSchema } from "../../schema";

import api from "../../api";

const ShopReviewForm = ({ id, reload }) => {
  const sendReview = (values, actions) => {
    const { reload, setSubmitting, resetForm, setFieldError } = actions;
    api
      .createShopReview({ ...values })
      .then(res => {
        setSubmitting(false);
        resetForm();
        reload();
      })
      .catch(({ response }) => {
        setSubmitting(false);
        const setErrorDefault = () =>
          setFieldError(
            "form",
            "На сервере произошла ошибка. Пожалуйста, попробуйте позже"
          );
        if (response && response.data) {
          const { data } = response;
          if (typeof data === "object") {
            Object.keys(data).map(key => {
              if (key !== "star" && key !== "text") {
                setErrorDefault();
              } else {
                setFieldError(key, data[key][0]);
              }
            });
          } else {
            setErrorDefault();
          }
        }
      });
  };
  return (
    <Formik
      validationSchema={CreateReviewSchema}
      onSubmit={(values, actions) => {
        sendReview(values, { ...actions, reload });
      }}
      initialValues={{
        shop: id,
        text: "",
        star: 0
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        errors,
        setFieldValue
      }) => (
        <Form onSubmit={handleSubmit} className="shopage-reviews__form">
          <div className="shopage-reviews__form-heading">
            Оставьте свой отзыв
          </div>
          <div className="form-group">
            <div className="form__label">Поставьте оценку</div>
            <div className="shopage-rating">
              <div className="shopage-rating__stars">
                <Rating
                  emptySymbol={<StarEmpty />}
                  fullSymbol={<StarFull />}
                  initialRating={values.star}
                  name="star"
                  onChange={rate => setFieldValue("star", rate)}
                />
              </div>
            </div>
            {errors.star && touched.star ? (
              <Errors errors={errors.star} />
            ) : null}
          </div>
          <div className="form-group">
            <div className="form__label">Комментарий</div>
            <textarea
              name="text"
              onChange={handleChange}
              value={values.text}
              className="form-textarea"
              placeholder="Напишите что понравилось, что нет, и любую другую полезную информацию"
            ></textarea>
            {errors.text && touched.text ? (
              <Errors errors={errors.text} />
            ) : null}
          </div>
          {errors.form ? <Errors errors={errors.form} /> : null}
          <Button disabled={isSubmitting} type={"submit"} className="btn">
            Отправить
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ShopReviewForm;
