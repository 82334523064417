import React from 'react';
import { Container } from '@garpix/component-system';
import { useStoreon } from 'storeon/react'
import Member from './Member';
import Partner from './Partner';
import { Authorization } from '../Auth';


const Account = ({ site_configuration }) => {
  const { currentUser } = useStoreon('currentUser');

  if (currentUser === null) return null;
  const { status } = currentUser;
  if (status === false) return (
    <Container>
      <Authorization site_configuration={site_configuration} />
    </Container>
  )

  const { type } = currentUser
  if (type === 'partner') {
    return (
      <Partner currentUser={currentUser} />
    )
  }
  if (type === 'normal') {
    return <Member currentUser={currentUser} />
  }
}

export default Account;