import React from 'react';
import { Modal } from '@garpix/component-system';

import { PartnerRegistration } from '../Auth';

const PartnerRegistrationModal = () => {

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>Регистрация</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PartnerRegistration />
      </Modal.Body>
    </>
  )
}

export default PartnerRegistrationModal;