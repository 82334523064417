import React from "react";
import { CounterInput, Form } from "@garpix/component-system";
import { Link } from "react-router-dom";
import { currencyFormat } from "../../utils";

const CartProduct = ({
  product,
  addToCart,
  params = {
    count: 1
  },
  callbackCheckbox,
  isChecked
}) => {
  const handleChangeProduct = (count) => addToCart({ count })
  const {
    id,
    title,
    image_thumb,
    slug,
    price,
    unit = '',
    is_active
    // old_price,
  } = product;

  const handleCheckbox = e => callbackCheckbox(product);

  const count = params.count;
  return (
    <div className={`cart-item ${is_active ? '' : 'cart-item--not-active'}`}>
      <div className="cart-item__wrap">
        <div className="cart-item__check form-checkbox">
          <Form.Check
            disabled={!is_active}
            custom
            inline
            label=" "
            type={'checkbox'}
            checked={isChecked}
            id={`custom-inline-${id}-1`}
            onChange={handleCheckbox}
          />
        </div>
        <img className="cart-item__img" src={image_thumb} alt="Изображение продукта" />
        <div className="cart-item__namewrap">
          <Link className="cart-item__name" to={slug}>{title}, {unit}</Link>
        </div>
      </div>
      <div className="cart-item__prices">
        <div className="cart-item__label">Цена за шт.</div>
        <div className="cart-item__price">{currencyFormat(price)}</div>
      </div>
      <div className="cart-item__quants">
        <div className="cart-item__label">Количество</div>
        {is_active ? (
          <div className="cart-item__quant">
            <CounterInput
              disabled={!is_active}
              count={count}
              name={"count"}
              min={1}
              max={1000}
              onCountChange={handleChangeProduct}
            />
          </div>
        ) : (
            <div className={'cart-item__not-sale'}>Товар снят с продажи</div>
          )}
      </div>
      <div className="cart-item__sum">
        <div className="cart-item__label">Сумма</div>
        <div className="cart-item__price">{currencyFormat(price * count)}</div>
      </div>
    </div>
  )
}

export default CartProduct;
