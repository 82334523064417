import React, { useState, useEffect } from 'react';
import { Container, ScrollOffset } from '@garpix/component-system';
import { useStoreon } from 'storeon/react'
import { preventDefault } from '../../utils';
import { Link } from "react-router-dom";
import { LogoEco, CartSVG, UserSVG } from '../../images';
import Search from '../Search';
import { currencyFormat } from '../../utils';
import { MenuItem } from './MenuItem';
import { orderModel } from '../../business'

const HeaderBody = React.memo(({
  cart,
  currentUser,
  openRegistration,
  openLogin,
  showMenu,
  search_slug,
  cart_slug,
  account_slug,
  menu
}) => {
  const cartCount = Object.values(cart).reduce((sum, item) => sum + Number(item.params.count), 0);
  const cartSumm = orderModel.getActiveProductOfCart(cart).reduce((sum, item) => sum + Number(item.product.price) * Number(item.params.count), 0);
  return (
    <div className="header-wrap">
      <div className="header-top">
        <Container>
          <Link className="header-logo" to={"/"}>
            <LogoEco />
          </Link>
          <div className="header-search">
            <Search mode={'header'} url={search_slug} />
          </div>
          <div onClick={showMenu} className="header-mobmenubtn js-showMenu">
            <div /><div /><div />
          </div>
        </Container>
      </div>
      <div className="header-btmwrap">
        <div className="header-btm">
          <Container>
            <div className="header-menu">
              {menu.map((item, index) => <MenuItem key={item.id} {...item} />)}
            </div>
            <div className="header-search header-search--mob">
              <Search />
            </div>
            <div className="header-right">
              {currentUser && currentUser.status === true ? (
                <>
                  <Link className="header-cart" to={cart_slug}>
                    <CartSVG />
                    <div className="header-cart__text">
                      <span>{cartCount ? `${currencyFormat(cartSumm)}` : 'Корзина'}</span>
                      {cartCount ? <div className="header-cart__count">{cartCount > 100 ? `+99` : cartCount}</div> : null}
                    </div>
                  </Link>
                  <Link className="header-cabinet" to={account_slug}>
                    <UserSVG />
                    <div className="header-cabinet__text">Личный кабинет</div>
                  </Link>
                </>
              ) : (
                  <div className="header-logreg">
                    <div className="header-logreg__item">
                      <a className="header-logreg__link" href="#" onClick={openRegistration}>Регистрация</a>
                    </div>
                    <div className="header-logreg__item">
                      <a className="header-logreg__link" href="#" onClick={openLogin}>Вход</a>
                    </div>
                  </div>
                )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
})


export default ({ menu = [], site_configuration }) => {
  const { dispatch, cart, currentUser } = useStoreon('cart', 'currentUser');
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);

  const {
    search_slug,
    cart_slug,
    account_slug
  } = site_configuration

  const openRegistration = preventDefault(() => dispatch('modal/toggle', { modal: 'registration' }))
  const openLogin = preventDefault(() => dispatch('modal/toggle', { modal: 'login' }))
  const showMenu = preventDefault(() => setShowMobileMenu(!isShowMobileMenu))

  useEffect(() => {
    const body = document.querySelector('body')
    if (isShowMobileMenu) {
      body.classList.add('header--menushowed', 'modal-open')
    } else {
      body.classList.remove('header--menushowed', 'modal-open')
    }
  }, [isShowMobileMenu])

  return (
    <ScrollOffset>
      {({ isScrolled }) => (
        <header className={`header ${isScrolled ? 'fixed' : ''}`}>
          <HeaderBody
            cart={cart}
            currentUser={currentUser}
            openRegistration={openRegistration}
            openLogin={openLogin}
            showMenu={showMenu}
            search_slug={search_slug}
            cart_slug={cart_slug}
            account_slug={account_slug}
            menu={menu}
          />
        </header>
      )}
    </ScrollOffset>
  )
}
