import React from 'react';
import { Form, ClearField } from '@garpix/component-system';
import Errors from '../../views/Errors';
import { UnionTicket } from '../../views/Fields';

const FormMember = ({
  currentUser,
  isShow,
  setFieldValue,
  handleChange,
  values,
  errors,
  touched,
  handleSubmit,
  policy_slug = ''
}) => {
  const { first_name, last_name, middle_name, producer } = currentUser;
  return (
    <div className="form-member" style={{ display: isShow ? "block" : "none" }}>
      <div className="form-confirm">
        {producer.contact_name ? <span>{producer.contact_name}</span> : <span>{last_name} {first_name} {middle_name}</span>}
      </div>
      <div className="form-steps">
        <span>Шаг 1/2</span>
      </div>
      <div className="form">
        <div className="form-group">
          <UnionTicket
            value={values.registration_number_cpc}
            label="Регистрационный № записи в реестр членов КПК *"
            name="registration_number_cpc"
            setFieldValue={setFieldValue}
          />
          {errors.registration_number_cpc && touched.registration_number_cpc ? (
            <Errors errors={errors.registration_number_cpc} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="№ лицевого счёта для перечисления суммы займа *"
            name="account_number_transferring"
            onChange={setFieldValue}
            value={values.account_number_transferring}
          />
          {errors.account_number_transferring && touched.account_number_transferring ? (
            <Errors errors={errors.account_number_transferring} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="Наименование банка, в котором открыт счёт *"
            name="name_bank_account_opened"
            onChange={setFieldValue}
            value={values.name_bank_account_opened}
          />
          {errors.name_bank_account_opened && touched.name_bank_account_opened ? (
            <Errors errors={errors.name_bank_account_opened} />
          ) : null}
        </div>
        <div className="form-end">
          <div className="form-group">
            <div className="form-checkbox">
              <Form.Check
                custom
                inline
                type={'checkbox'}
                id={`is_policies`}
              >
                <Form.Check.Input
                  type={'checkbox'}
                  name="is_policies"
                  checked={values.is_policies}
                  onChange={handleChange}
                />
                <Form.Check.Label>
                  <span>
                    Согласен с условиями{" "}
                    <a target="_blank" rel="noopener noreferrer" href={policy_slug}>политики конфиденциальности</a>
                  </span>
                </Form.Check.Label>
              </Form.Check>
            </div>
            {errors.is_policies && touched.is_policies ? (
              <Errors errors={errors.is_policies} />
            ) : null}
          </div>
          <div className="form-group">
            <button onClick={handleSubmit} type="button" className="btn btn--full">Далее</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  FormMember
}