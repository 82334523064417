import React from 'react';
import { withRouter } from 'react-router-dom';
import { FetcherList } from '@garpix/fetcher';
import { Container } from '@garpix/component-system';
import { Link } from 'react-router-dom';
import Search from '../components/Search';
import Layout from '../views';
import Paginate from '../views/Paginate';
import api from '../api';
import { getPlural } from '../utils';


const SearchPage = ({ location, ...props }) => {
  const { search_slug } = props.site_configuration;

  return (
    <Layout {...props}>
      <FetcherList
        api={api.getSearch}
      >
        {({
          count,
          results = [],
          activePage,
          filterParams
        }) => (
            <>
              <section className="search-form">
                <Container>
                  <Search initialValues={{
                    q: filterParams.q ? filterParams.q : ''
                  }} url={search_slug} />
                </Container>
              </section>
              <section className="search-results">
                <Container>
                  <h3 className="search-heading">Результаты поиска: <span>найдено {count} {getPlural(count, 'ответ', 'ответа', 'ответов')}</span></h3>
                  <div className="search-results__items">
                    {results.map((item, index) => {
                      return (
                        <div key={index} className="search-results__item">
                          <Link to={item.slug} className="search-results__name">{index + 1}. {item.title}</Link>
                          <div className="search-results__text" dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                      )
                    })}
                  </div>
                  <Paginate count={count} activePage={activePage} params={filterParams} />
                </Container>
              </section>
            </>
          )}
      </FetcherList>
    </Layout>
  )
}

export default withRouter(SearchPage);