import React from 'react';
import Layout from '../views';
import { CategoryProducts } from '../components/Category/CategoryProducts';
import { CategoryServices } from '../components/Category/CategoryServices';


const CategoryPage = ({ products, products_category, services_category, page_info, ...props }) => {
  return (
    <Layout {...props}>
      {products_category ?
        <CategoryProducts initProducts={products} categories={products_category} catName={page_info?.title} /> :
        <CategoryServices initProducts={products} categories={services_category} catName={page_info?.title} />
      }
    </Layout>
  )
}

export default CategoryPage
