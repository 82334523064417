import React, { useState } from "react";
import { FetcherList } from "@garpix/fetcher";
import { Alert, Button } from "@garpix/component-system";
import api from "../../api";
import { OrderItem } from "./OrderItem";
import { usernameFormat, currencyFormat } from "../../utils";
import dayjs from "../../utils/dayjs";
import { OrderProductItem } from "./OrderProductItem";
import { OrdersSearch } from "./OrdersSearch";
import { ModalDialog } from "../../views/ModalDialog";
import { orderModel } from "../../business";

const { ORDER_STATUSES, STATUS_STYLE, ORDER_STATUSES_NAME } = orderModel;

const ACTION_PAY = ORDER_STATUSES.payed;
const ACTION_CANCEL = ORDER_STATUSES.canceled;

const OrderDetail = ({ order, update }) => {
  const [action, setAction] = useState(null);
  const [isSend, setSend] = useState(false);

  const handlePay = () => setAction(ACTION_PAY);
  const handleCancel = () => setAction(ACTION_CANCEL);

  const yesCallback = () => {
    const { id, orderitem_set } = order;
    const currentAction = action;
    setSend(true);
    setAction(null);
    if (currentAction === ACTION_PAY) {
      api.payOrder({ id }).then(res => {
        update(
          {
            status: currentAction,
            orderitem_set: [...orderitem_set].map(item => ({
              ...item,
              status: currentAction
            }))
          },
          id
        );
        setSend(false);
      });
    }
    if (currentAction === ACTION_CANCEL) {
      api.cancelOrder({ id }).then(res => {
        update(
          {
            status: currentAction,
            orderitem_set: [...orderitem_set].map(item => ({
              ...item,
              status: currentAction
            }))
          },
          id
        );
        setSend(false);
      });
    }
  };

  const noCallback = () => {
    setAction(null);
  };

  const status = order.orderitem_set[0].status;
  const isPay = [ORDER_STATUSES.created, ORDER_STATUSES.canceled].includes(
    status
  );
  const isCancel = [ORDER_STATUSES.created, ORDER_STATUSES.payed].includes(
    status
  );
  const status_display = ORDER_STATUSES_NAME[status];
  return (
    <>
      <div className="orders-name">
        Заказ <strong>{order.id}</strong> от{" "}
        {dayjs(order.created_at).format("DD.MM.YYYY")}
      </div>
      <div className={"mb-2"}>
        {isPay ? (
          <Button
            disabled={isSend}
            onClick={handlePay}
            className={"btn--sm mr-1"}
          >
            Подтвердить
          </Button>
        ) : null}
        {isCancel ? (
          <Button
            disabled={isSend}
            onClick={handleCancel}
            className={"btn--sm btn--red"}
          >
            Отменить
          </Button>
        ) : null}
      </div>
      <div className="orders-block">
        <div className="cart-head">
          <div className="cart-head__name">Покупатель</div>
          <div className="cart-head__price">Позиции</div>
          <div className="cart-head__sum">Сумма</div>
        </div>
        <div className="cart-body accordion" id="cartitems">
          <div className="cart-items accordion__block">
            <div className="cart-items__head accordion__heading">
              <div className="cart-items__name">
                <span title={`${usernameFormat(order.user_obj)}`}>
                  {usernameFormat(order.user_obj)}
                </span>
                <span className="orders-phone">{order.phone}</span>
                <span className={`orders-${STATUS_STYLE[status]}`}>
                  {status_display}
                </span>
              </div>
              <div className="cart-items__quant">{order.total_qty}</div>
              <div className="cart-items__sum">
                {currencyFormat(order.total)}
              </div>
            </div>
            <div className="cart-items__body accordion__content collapse show">
              <div className="cart-items__wrap">
                {order.orderitem_set.map(item => {
                  const { product, qty, item_total, id } = item;
                  return (
                    <OrderProductItem
                      key={id}
                      product={product}
                      qty={qty}
                      item_total={item_total}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialog
        title={"Подтверждение заказа"}
        text={"После подтверждения его можно будет только отменить."}
        show={action === ACTION_PAY}
        yesCallback={yesCallback}
        noCallback={noCallback}
      />
      <ModalDialog
        title={"Отмена заказа"}
        text={"Хотите отменить заказ?"}
        show={action === ACTION_CANCEL}
        yesCallback={yesCallback}
        noCallback={noCallback}
      />
    </>
  );
};

const PartnerOrders = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  return (
    <FetcherList
      api={api.getOrderProducer}
      isHistoryPush={false}
      isScrollTop={false}
    >
      {({ status, results = [], count, loadData, updateElementByKey }) => {
        const currentOrder =
          results.length > 0 && activeOrder ? activeOrder : results[0];
        return (
          <div className="container">
            <div className="orders">
              <div className="orders-left">
                <OrdersSearch callback={loadData} />
                <div className="orders-list">
                  <div className="orders-list__items">
                    {status === "loaded" && count === 0 ? (
                      <Alert variant={"light"}>Список пуст</Alert>
                    ) : null}
                    {results.map(item => (
                      <OrderItem
                        description="user"
                        key={item.id}
                        item={item}
                        currentOrder={currentOrder}
                        setActiveOrder={setActiveOrder}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {currentOrder ? (
                <div className="orders-right">
                  <OrderDetail
                    order={currentOrder}
                    update={(obj, id) => {
                      setActiveOrder({ ...currentOrder, ...obj });
                      updateElementByKey(obj, id);
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      }}
    </FetcherList>
  );
};

export default PartnerOrders;
