import React from 'react';
import { Formik } from 'formik';
import { FetcherList } from '@garpix/fetcher';
import { Container } from '@garpix/component-system';
import { SelectShop } from '../Select';
import EditorServices from './EditorServices';
import { isObject } from '../../utils';

import api from '../../api';

const UserServices = () => {

  return (
    <>
      <FetcherList
        api={api.getUserServices}
        isHistoryPush={false}
        isScrollTop={false}>
        {({
          count,
          results = [],
          status,
          activePage,
          loadData,
          showMore,
          isNext,
          deleteElement,
          updateElement,
          filterParams
        }) => {
          const submit = (params) => {
            const filterParams = { ...params };

            Object.keys(params).map(key => {
              const value = params[key];
              let resultValue = '';
              if (isObject(value) && value !== null) {
                resultValue = value.value;
              }
              if (!isObject(value) && value !== '') {
                resultValue = value;
              }
              filterParams[key] = resultValue
            })

            loadData(1, filterParams)
          }
          return (
            <Container>
              <div className="cabinet-cat">
                <div className="catalog-filters">
                  <Formik
                    initialValues={{
                      search: '',
                      shop: '',
                    }}
                    onSubmit={submit}>
                    {({
                      submitForm,
                      handleSubmit,
                      handleChange,
                      resetForm,
                      values,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit} className="catalog-filters__row">
                          <div className="catalog-filters__col2">
                            <div className="form__label">Поиск</div>
                            <div className="search-form__form">
                              <button type='submit' className="search-form__btn search__service_btn btn" />
                              <input
                                className="form-input search__service_input"
                                placeholder="Что вы ищете?"
                                onChange={handleChange}
                                value={values.search}
                                name="search"
                              />
                            </div>
                          </div>

                          <div className="catalog-filters__col">
                            <div className="form__label">Выберите торговую площадку</div>
                            <SelectShop
                              onChange={value => {
                                setFieldValue('shop', value);
                                submitForm();
                              }}
                              value={values.shop}
                            />
                          </div>

                          <div className="catalog-filters__collast">
                            <button
                              type='reset'
                              onClick={() => {
                                resetForm();
                                submit({});
                              }}
                              className="catalog-filters__reset">Сбросить</button>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
                <EditorServices
                  count={count}
                  filterParams={filterParams}
                  activePage={activePage}
                  status={status}
                  results={results}
                  loadData={loadData}
                  deleteElement={deleteElement}
                  updateElement={updateElement}
                  showMore={showMore}
                  isNext={isNext} />
              </div>

            </Container>
          )
        }}
      </FetcherList>
    </>
  )
}

export default UserServices;