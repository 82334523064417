import React from 'react';
import { IMaskInput } from 'react-imask';

const PhoneInput = ({
  value,
  setFieldValue,
  className,
  name,
  onBlur
}) => {
  return (
    <IMaskInput
      mask={'+{7}(000)000-00-00'}
      radix="."
      value={value}
      unmask={true}
      onAccept={(value, mask) => setFieldValue(name, value)}
      className={className}
      name={name}
      onBlur={onBlur}
    />
  )
}

export {PhoneInput}
