import pageSerializer from './page';
import breadcrumbsSerializer from "./breadcrumbs";

const partnerServiceSerializer = item => ({
  id: item.id,
  slug: item.slug,
  title: item.title,
  content: item.content,
  address: item.address,
  description: item.address,
  value: item.discount ? `${item.discount} %` : '',
  image_thumb: item.image_thumb ? item.image_thumb : ''
})

const servicesSerializer = page => {
  const data = pageSerializer(page);
  const { page_info, shops } = data;
  return {
    ...data,
    // shops: shops ? shops.map(item => ({
    //   ...item,
    //   relatedData: {
    //     count: item.shop_set.count,
    //     next: item.shop_set.next,
    //     results: item.shop_set.results.map(partnerServiceSerializer)
    //   }
    // })) : [],
    // partners: shops ? shops.map(item => ({
    //   ...item,
    //   relatedData: {
    //     count: item.partner_set.count,
    //     next: item.partner_set.next,
    //     results: item.partner_set.results.map(partnerServiceSerializer)
    //   }
    // })) : [],
    breadcrumbs: breadcrumbsSerializer(page_info)
  }
}

export default servicesSerializer;

export {
  partnerServiceSerializer
}