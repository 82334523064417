import React from 'react';
import { useStoreon } from 'storeon/react'
import { Modal } from '@garpix/component-system';
import { preventDefault } from '../../utils';
import { Registration } from '../Auth';


const RegistrationModal = ({ isCloseModal }) => {
  const { dispatch } = useStoreon('showModal');

  const openLogin = () => preventDefault(dispatch('modal/toggle', { modal: 'login' }))

  return (
    <>
      <Modal.Header closeButton={!isCloseModal}>
        <Modal.Title>Регистрация</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Registration />
        <Modal.Footer>
          <p>Уже есть аккаунт? <a href="#" onClick={openLogin}>Вход</a></p>
        </Modal.Footer>
      </Modal.Body>
    </>
  )
}

export default RegistrationModal
