import React from "react";
import AsyncPaginate from './base';
import api from "../../api";
import { optionSerializer } from "../../api/serializers";


const SelectShop = ({
  onChange,
  value,
  name = "shop",
  isDisabled = false,
  filters = {},
  placeholder = "Все варианты",
  ...props
}) => {
  const loadOptions = async (search, loadedOptions, { page }) => {
    const data = await api.getShopProducer({ page, search, ...filters });
    return {
      options: [
        { value: '', label: 'Все варианты' },
        ...data.results.map(optionSerializer)
      ],
      hasMore: !!data.next,
      additional: {
        page: page + 1,
      }
    }
  };
  return (
    <AsyncPaginate
      name={name}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default SelectShop;