import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { Modal } from "@garpix/component-system";
import DeleteAccount from "./AccountDelete";
import EmailChange from "./EmailChange";
import ErrorUpload from "./ErrorUpload";
import ForgotPassword from "./ForgotPassword";
import LoginModal from "./Login";
import PartnerRegistration from "./PartnerRegistration";
import PasswordChange from "./PasswordChange";
import Registration from "./Registration";
import SuccessSave from "./SuccessSave";
import ImageShopDelete from "./ImageShopDelete";
import ThankModal from "./Thank";

const pageIdNotOpen = [5, 9, 18, 23];

const modals = {
  login: LoginModal,
  registration: Registration,
  "registration-partner": PartnerRegistration,
  "email-change": EmailChange,
  "password-change": PasswordChange,
  "account-delete": DeleteAccount,
  thank: ThankModal,
  "thank-order": props => (
    <ThankModal text="Ожидайте подтверждение заказа." {...props} />
  ),
  "loan-application": props => (
    <ThankModal text="" {...props} />
  ),
  "forgot-password": ForgotPassword,
  "success-save": SuccessSave,
  "error-upload": ErrorUpload,
  "image-delete": ImageShopDelete,
  empty: () => null
};

const Modals = () => {
  const { dispatch, showModal, currentUser, page } = useStoreon(
    "showModal",
    "currentUser",
    "page"
  );

  useEffect(() => {
    if (currentUser && currentUser.status === false) {
      if (page && page.data && !pageIdNotOpen.includes(page.data.pageType)) {
        dispatch("modal/toggle", { modal: "login" });
      }
    }
  }, [currentUser, page.data]);

  let isCloseModal = false;
  if (page.data && !pageIdNotOpen.includes(page.data.pageType)) {
    if (!!currentUser && currentUser.status === false) {
      isCloseModal = true;
    }
  }

  const handleClose = () => {
    if (isCloseModal) return false;
    dispatch("modal/toggle", { modal: null });
  };

  const CurrentModal = modals[showModal ? showModal : "empty"];

  return (
    <Modal centered={true} size={"sm"} show={!!showModal} onHide={handleClose}>
      <CurrentModal handleClose={handleClose} isCloseModal={isCloseModal} />
    </Modal>
  );
};

export default Modals;
