import React, { useState } from 'react';
import MemberProfile from './MemberProfile';
import MemberOrders from './MemberOrders';
import LastViewed from './LastViewed';
import { preventDefault } from '../../utils';



const Member = ({ currentUser }) => {
  const [activeTab, setTab] = useState('tb1')

  return (
    <section className="cabinet">
      <div className="cabinet-tabs">
        <div className="container">
          <div className="tabs__wrap">
            <ul className="tabs__list nav">
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => setTab('tb1'))}
                  className={`tabs__link ${activeTab === 'tb1' ? 'active show' : ''}`}
                  data-toggle="tab"
                  href="#tb1"
                  role="tab">Профиль</a>
              </li>
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => setTab('tb2'))}
                  className={`tabs__link ${activeTab === 'tb2' ? 'active show' : ''}`}
                  data-toggle="tab"
                  href="#tb2"
                  role="tab">Заказы</a>
              </li>
              <li className="tabs__item">
                <a
                  onClick={preventDefault(() => setTab('tb3'))}
                  className={`tabs__link ${activeTab === 'tb3' ? 'active show' : ''}`}
                  data-toggle="tab"
                  href="#tb3"
                  role="tab">Товары</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tabs__content">
          <div className={`tabs__pane ${activeTab === 'tb1' ? 'show active' : ''}`} id="tb1" role="tabpanel">
            <MemberProfile currentUser={currentUser} />
          </div>
          <div className={`tabs__pane ${activeTab === 'tb2' ? 'show active' : ''}`} id="tb2" role="tabpanel">
            <MemberOrders />
          </div>
          <div className={`tabs__pane ${activeTab === 'tb3' ? 'show active' : ''}`} id="tb3" role="tabpanel">
            {activeTab === 'tb3' ? <LastViewed /> : null}
          </div>
        </div>
      </div>
    </section>

  )
}

export default Member;
