import siteConfigurationSerializer from './site_configuration';

const mainPageSerializer = page => {
  const { init_state } = page;
  const { page_info, slider } = init_state;
  return {
    ...page_info,
    ...init_state,
    slider: slider.length > 0 ? slider[0].sliderimage_set : [],
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
  }
}

export default mainPageSerializer
