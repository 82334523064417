import React from 'react';
import { useStoreon } from 'storeon/react';
import api from '../../api';

const Logout = () => {
  const { dispatch } = useStoreon();
  const logout = () => {
    api.logout().then(res => {
      dispatch("cart/clear")
      const { location } = window;
      location.href = '/';
    })
  }
  const handleLogout = e => {
    e.preventDefault();
    logout();
  }
  return <button type={"button"} onClick={handleLogout} className="btn btn--link-red">Выйти из профиля</button>
}

export default Logout;