import { Form } from "@garpix/component-system";
import classNames from "classnames";
import { Formik } from "formik";
import React from "react";
import './category_menu.scss';

const CategoryMenu = ({ categories, initParams, handleSubmit }) => {
    const submit = ({category}) => {
        handleSubmit(1, { ...initParams, category })
    }
    return (
        <ul className="category-menu">
            <Formik
                onSubmit={submit}
                initialValues={{ category: '' }}
            >
                {({
                    values,
                    setFieldValue,
                    submitForm, }) => {
                    return (
                        <Form>
                            {categories && categories.map(cat => {
                                return (
                                    <li key={cat.id} className="category-menu__item">
                                        <button type="button" onClick={() => { setFieldValue('category', cat.slug); submitForm() }} variant="link"
                                            className={classNames('category-menu__btn-link', { 'category-menu__btn-link_active' : cat.slug === initParams?.category })}>
                                            {cat.title}
                                        </button>
                                    </li>
                                )
                            })}
                        </Form>
                    )
                }}
            </Formik>
        </ul>
    )
}

export { CategoryMenu };
