import productSerializer from './product';
import siteConfigurationSerializer from "./site_configuration";
import breadcrumbsSerializer from "./breadcrumbs";

const productPageSerializer = page => {
  const { init_state } = page
  const { page_info, similar_products } = init_state;
  return {
    product: {...productSerializer(page_info)},
    ...init_state,
    ...page_info,
    similar_products: similar_products.map(productSerializer),
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(page_info, 'producer_obj')
  }
}

export default productPageSerializer;
