import React from 'react';
import { useStoreon } from 'storeon/react'
import { Formik } from "formik";
import { Form, PasswordField } from '@garpix/component-system';
import { AccountDeleteSchema } from '../../schema';
import Errors from '../../views/Errors';
import api from '../../api';


const AccountDelete = () => {
  const { currentUser } = useStoreon('currentUser');

  const handleDelete = (params, { setSubmitting, setErrors }) => {
    api.userCheckPassword(params)
      .then(res => {
        const { id } = currentUser;
        api.deleteUser(id)
          .then(res => {
            api.logout();
            window.location.href = '/';
          })
      })
      .catch(error => {
        setSubmitting(false)
        if (error.response) {
          setErrors(error.response.data)
        }
      })
  }

  return (
    <Formik
      validationSchema={AccountDeleteSchema}
      initialValues={{
        password: '',
        password2: ''
      }}
      onSubmit={handleDelete}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        errors,
        touched,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form--login">
          <div className="form-group">
            <PasswordField
              label="Пароль"
              name="password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && touched.password ? (
              <Errors errors={errors.password} />
            ) : null}
          </div>
          <div className="form-group">
            <PasswordField
              label="Пароль еще раз"
              name="password2"
              onChange={handleChange}
              value={values.password2}
            />
            {errors.password2 && touched.password2 ? (
              <Errors errors={errors.password2} />
            ) : null}
          </div>
          <div className="form-group">
            {errors.non_field ? (
              <Errors errors={errors.non_field} />
            ) : null}
          </div>
          <div className="form-end">
            <input
              disabled={isSubmitting}
              className="btn btn--red btn--full"
              type="submit"
              value="Удалить профиль"
            />
          </div>
        </Form>
      )}
    </Formik>

  )
}

export default AccountDelete;
