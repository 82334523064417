import React from 'react';
import DefaultProduct from './DefaultProduct';
import DetailProduct from './DetailProduct';
import EditProduct from "./EditProduct";
import CartProduct from "./CartProduct";


const sizesConfig = {
  'detail': DetailProduct,
  'edit': EditProduct,
  'cart': CartProduct,
};

const Product = ({ size, ...props }) => {
  const product = props;
  if (!product) return null;
  if (sizesConfig[size]) {
    const Component = sizesConfig[size]
    return <Component {...props} />
  }
  return <DefaultProduct {...props} />
}

export { Product };