import React from 'react';
import MainCarousels from '../views/MainCarousels';
import Banners from '../views/Banners';
import BlogGrid from '../views/BlogGrid';
import Layout from '../views';
import { HomeSlider } from '../components/Home/HomeSlider';
import { Container } from 'react-bootstrap';
import { CategorySection } from '../components/Home/CategorySection';
import { ShopsSection } from '../components/Home/ShopsSection';
import { CATEGORY_TITLE } from '../const';
import { CardMenu } from '../components/Home/CardMenu';


const Home = (props) => {
  const {
    slider,
    products,
    shop,
    services_product,
    farm_products,
    services_category,
    products_category,
    banners,
    page_service,
    page_product,
    page_services_and_products,
    page_farm_product,
  } = props;
  return (
    <Layout {...props}>
      <Container>
        <HomeSlider data={slider} />
        <CategorySection data={services_product} cat_url={page_service?.slug} categoryList={services_category} limit={6} md={3} title={CATEGORY_TITLE.services} />
        <CategorySection data={products} cat_url={page_product?.slug} categoryList={products_category} limit={8} md={4} title={CATEGORY_TITLE.product} />
        <CategorySection data={farm_products} cat_url={page_farm_product?.slug} limit={10} md={5} title={CATEGORY_TITLE.farm_rows} />
        {/* Скрыто по желанию заказчика 14.10.21
        <ShopsSection shop={shop} cat_url={page_services_and_products?.slug} limit={6}/> 
        */}
        <CardMenu banners={banners?.main_page_near_footer} />
      </Container>
    </Layout>
  )
}

export default Home
