import {Catalog} from "../components/Catalog";
import Layout from "../views";
import React from "react";

const FarmPage = ({products, page_info, ...props}) => {
  return (
    <Layout {...props}>
      <Catalog producer={page_info} initProducts={products} />
    </Layout>
  )
}

export default FarmPage;