import React from 'react';
import { useStoreon } from 'storeon/react'
import { Button } from '@garpix/component-system';


const ProfileLinks = () => {
  const { dispatch } = useStoreon();

  const showChangeEmail = () => dispatch('modal/toggle', { modal: 'email-change' });
  const showChangePassword = () => dispatch('modal/toggle', { modal: 'password-change' });
  const showDeleteUser = () => dispatch('modal/toggle', { modal: 'account-delete' });

  return (
    <div className="profile-links">
      <div className="profile-link">
        <Button variant="link" className="btn--link" onClick={showChangeEmail}>Сменить е-mail</Button>
      </div>
      <div className="profile-link">
        <Button variant="link" className="btn--link" onClick={showChangePassword}>Сменить пароль</Button>
      </div>
      <div className="profile-link">
        <Button variant="link" className="btn--link-red" onClick={showDeleteUser}>Удалить профиль</Button>
      </div>
    </div>
  )
}

export default ProfileLinks;