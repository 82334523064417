import React from 'react';
import { Formik } from "formik";
import { useStoreon } from 'storeon/react'
import { Form, Button } from "@garpix/component-system";
import { AccountSchema } from '../../schema';
import Errors from '../../views/Errors';
import ImageUpload from '../../views/ImageUpload';
import api from "../../api";
import Avatar from "../../views/Avatar";
import SelectDistrict from "../Select/SelectDistrict";
import ProfileLinks from './ProfileLinks';
import Logout from './Logout';
import { PhoneInput, BirthdateInput } from '../../views/Fields';
import { EmailConfirmed } from "./EmailConfirmed";


const MemberProfile = ({ currentUser }) => {
  const { dispatch } = useStoreon();

  const {
    first_name,
    last_name,
    number
  } = currentUser;

  const send = (params, { setSubmitting, setErrors }) => {
    const newParams = { ...params };
    if (newParams.district && newParams.district) {
      newParams.district = newParams.district.value
    }
    api.updateUser(currentUser.id, newParams)
      .then(res => {
        dispatch('user/get', {
          callback: () => {
            dispatch('modal/toggle', { modal: 'success-save' });
            setSubmitting(false)
          }
        })
      })
      .catch(error => {
        setSubmitting(false);
        const { response } = error;
        if (response) {
          setErrors(response.data)
        }
      })
  };
  const { district_obj } = currentUser;
  return (
    <div className="container">
      <Formik
        validationSchema={AccountSchema}
        onSubmit={send}
        initialValues={{
          last_name: currentUser.last_name,
          first_name: currentUser.first_name,
          middle_name: currentUser.middle_name,
          birthdate: currentUser.birthdate,
          phone: currentUser.phone,
          about: currentUser.about,
          district: district_obj ? {
            value: district_obj.id,
            label: district_obj.title
          } : '',
          image: currentUser.image
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isSubmitting,
          errors,
          setFieldValue
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="profile">
            <div className="profile-top">
              <div className="profile-top__left">
                <ImageUpload>
                  {({
                    preview,
                    onSelectFile,
                    selectedFile
                  }) => (
                    <Avatar
                      onSelectFile={onSelectFile}
                      setFieldValue={setFieldValue}
                      selectedFile={selectedFile}
                      preview={preview}
                      avatar={values.image}
                      name="image"
                    />
                  )}
                </ImageUpload>
              </div>
              <div className="profile-top__right">
                <div className="profile-name">{first_name} {last_name}</div>
                <div className="profile-number">{number}</div>
              </div>
              <div className="profile-exit"><Logout /></div>
            </div>
            <div className="profile-mid">
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Фамилия</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.last_name}
                      name={"last_name"}
                    />
                    {errors.last_name && touched.last_name ? (
                      <Errors errors={errors.last_name} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Имя</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.first_name}
                      name={"first_name"}
                    />
                    {errors.first_name && touched.first_name ? (
                      <Errors errors={errors.first_name} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Отчество</div>
                    <input
                      className="form-input"
                      type="text"
                      onChange={handleChange}
                      value={values.middle_name}
                      name={"middle_name"}
                    />
                    {errors.middle_name && touched.middle_name ? (
                      <Errors errors={errors.middle_name} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Район</div>
                    <SelectDistrict
                      value={values.district}
                      name="district"
                      onChange={value => {
                        setFieldValue('district', value);
                      }}
                    />
                    {errors.district && touched.district ? (
                      <Errors errors={errors.district} />
                    ) : null}
                  </div>
                </div>
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Дата рождения</div>
                    <BirthdateInput
                      className="form-input"
                      setFieldValue={setFieldValue}
                      value={values.birthdate}
                      name={"birthdate"}
                    />
                    {errors.birthdate && touched.birthdate ? (
                      <Errors errors={errors.birthdate} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col3">
                  <div className="form-group">
                    <div className="form__label">Номер телефона</div>
                    <PhoneInput
                      className="form-input"
                      setFieldValue={setFieldValue}
                      value={values.phone}
                      name={"phone"}
                    />
                    {errors.phone && touched.phone ? (
                      <Errors errors={errors.phone} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col9">
                  <div className="form-group">
                    <div className="form__label">
                      О себе
                      <textarea
                        className="form-textarea"
                        onChange={handleChange}
                        value={values.about}
                        name={"about"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-row">
                <div className="profile-col9">
                  {!currentUser.is_email_confirmed ? (
                    <EmailConfirmed />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="profile-btm">
              <ProfileLinks />
              <div className="profile-save">
                <Button disabled={isSubmitting} type={"submit"}>Сохранить</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MemberProfile;
