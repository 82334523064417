import React from 'react';
import { Container } from '@garpix/component-system';
import Swiper from '@garpix/react-swiper';
import Layout from '../views';
import Post from '../views/Blog';
import dayjs from "../utils/dayjs";


const swiperSettingsMain = {
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 500,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

const swiperSettingsReadAlso = {
  isOutRender: true,
  slidesPerView: 3,
  spaceBetween: 30,
  speed: 500,
  mousewheel: false,
  rebuildOnUpdate: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    568: {
      slidesPerView: 2,
      spaceBetween: 0
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    }
  }
};

const PostPage = ({ content, created_at, images, read_also, ...props }) => {
  const {
    title,
    video
  } = props;
  let sliderData = images;
  if (video) {
    sliderData = [{
      video,
      id: 'video'
    }, ...images]
  }

  return (
    <Layout {...props} className={"main--bg"}>
      <section className="news">
        <Container>
          <h2 className="news-head">Новости</h2>
          <div className="news-content">
            <h1 className="news-content__heading">{title}</h1>
            <div className="news-item__date">{dayjs(created_at).format('DD MMMM YYYY')}</div>
            <div className="news-text">
              {images.length > 0 || video !== null ? (
                <div className="pageslider">
                  <Swiper {...swiperSettingsMain}>
                    {sliderData.map(item => {
                      const { id, image_thumb, title, video } = item;
                      return (
                        <div key={id} className="swiper-slide">
                          {video ? (
                            <div className="pageslider-slide">
                              <video className="pageslider-img" autoPlay={false} controls={true}>
                                <source type="video/mp4" src={video} />
                              </video>
                              <div className="pageslider-text">{title}</div>
                            </div>
                          ) : (
                              <div className="pageslider-slide">
                                <img className="pageslider-img" src={image_thumb} alt="Фото новости" />
                                <div className="pageslider-text">{title}</div>
                              </div>
                            )}
                        </div>
                      )
                    })}
                  </Swiper>
                </div>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </Container>
      </section>
      {read_also.length > 0 ? (
        <section className="newsrel">
          <Container>
            <h3 className="news-head">Читайте также</h3>
            <div className="newsrel-slider js-sliderNews">
              <Swiper {...swiperSettingsReadAlso}>
                {read_also.map(item => <div key={item.id} className="swiper-slide"><Post {...item} /></div>)}
              </Swiper>
            </div>
          </Container>
        </section>
      ) : null}
    </Layout>
  )
}

export default PostPage
