import React from 'react';
import { Container } from '@garpix/component-system';
import { Link } from 'react-router-dom';
import Layout from '../views';
import MainCarousels from '../views/MainCarousels';

const Services = ({ title, services, slider, ...props }) => {
  return (
    <Layout title={title} {...props}>
      <MainCarousels data={slider} />
      <section className="services">
        <Container>
          <h2 className="services-heading">{title}</h2>
          <div className="services-items">
            {services.map(item => {
              return (
                <Link key={item.id} className="services-item" to={item.slug}>
                  <img className="services-item__img" src={item.icon} alt="" />
                  <div className="services-item__text">
                    <div className="services-item__name">{item.title}</div>
                    <div className="services-item__desc">{item.short_content}</div>
                  </div>
                </Link>
              )
            })}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Services;
