import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import {
  SearchForm,
  HeaderSearchForm,
} from '../../views/Search';
import qs from 'query-string';

const FORM_TYPES = {
  'default': SearchForm,
  'header': HeaderSearchForm,
}

const Search = ({ url, history, mode = 'default', initialValues = { q: '' } }) => {
  const handleSubmit = (values) => {
    history.push({
      pathname: url,
      search: qs.stringify(values)
    })
  }
  const Form = FORM_TYPES[mode];
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        values,
        handleChange
      }) => (<Form
        handleSubmit={handleSubmit}
        values={values}
        handleChange={handleChange}
      />)}
    </Formik>
  )
}

export default withRouter(Search);
