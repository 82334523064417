import React from "react";
import {Modal} from "@garpix/component-system";
import PasswordChange from '../Account/PasswordChange';


const PasswordChangeModal = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Сменить пароль</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PasswordChange />
      </Modal.Body>
    </>
  )
}

export default PasswordChangeModal;
