import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Button } from "@garpix/component-system";
import Errors from "../../views/Errors";
import { CreateReplySchema } from "../../schema";

import api from "../../api";

const ShopReplyForm = ({ reload, reviewId, replyFormRef }) => {
  const sendReply = values => {
    const { setFieldError, resetForm, setSubmitting } = formik;
    if (values.review) {
      api
        .createShopReviewReply({ ...values })
        .then(res => {
          setSubmitting(false);
          resetForm();
          reload();
        })
        .catch(err => {
          setSubmitting(false);
          setFieldError(
            "form",
            "На сервере произошла ошибка. Пожалуйста, попробуйте позже"
          );
        });
    } else {
      setFieldError("form", "Выберите отзыв для ответа");
    }
  };

  const formik = useFormik({
    initialValues: {
      review: reviewId,
      text: ""
    },
    validationSchema: CreateReplySchema,
    onSubmit: sendReply
  });

  useEffect(() => {
    if (reviewId) {
      formik.setFieldValue("review", reviewId);
    }
  }, [reviewId]);

  useEffect(() => {
    const { text, review } = formik.values;
    if (text && text.length > 0 && review) {
      formik.setSubmitting(false);
    }
  }, [formik.values]);

  return (
    <>
      {formik ? (
        <>
          <Form
            onSubmit={formik.handleSubmit}
            className="shopage-reviews__form"
          >
            <div ref={replyFormRef} className="shopage-reviews__form-heading">
              Оставьте ответ на отзыв
            </div>

            <div className="form-group">
              <div className="form__label">Комментарий</div>
              <textarea
                name="text"
                onChange={formik.handleChange}
                value={formik.values.text}
                className="form-textarea"
                placeholder="Напишите что понравилось, что нет, и любую другую полезную информацию"
              ></textarea>
              {formik.errors.text && formik.touched.text ? (
                <Errors errors={formik.errors.text} />
              ) : null}
            </div>
            {formik.errors.form ? <Errors errors={formik.errors.form} /> : null}
            <Button
              disabled={formik.isSubmitting}
              type={"submit"}
              className="btn mt-4"
            >
              Отправить
            </Button>
          </Form>
        </>
      ) : null}
    </>
  );
};

export default ShopReplyForm;
