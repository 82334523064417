import { AvaImage } from '../../images';
import { USER_TYPE_NORMAL } from '../../const';

const userSerializer = user => {
  const { producer } = user;
  let newProducer = {}
  if (producer) {
    newProducer = {
      ...producer,
      image: producer.image_url ? producer.image_url : AvaImage
    };
  }
  return {
    ...user,
    phone: user.phone === null ? '' : user.phone,
    birthdate: user.birthdate === null ? '' : user.birthdate,
    isCreateOrder: user.type === USER_TYPE_NORMAL,
    image: user.image_url ? user.image_url : AvaImage,
    producer: newProducer
  }
}

export default userSerializer;
