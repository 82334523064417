import React from "react";
import { Modal } from "@garpix/component-system";
import AccountDelete from '../Account/AccountDelete';


const AccountDeleteModal = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Удалить профиль</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AccountDelete />
      </Modal.Body>
    </>
  )
}

export default AccountDeleteModal;