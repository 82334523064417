import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import baseCustomStyles from "./style";

const LoadingMessage = props => {
  return (
    <div {...props.innerProps} style={props.getStyles("loadingMessage", props)}>
      Загрузка...
    </div>
  );
};

const NoOptionsMessage = props => {
  return (
    <div {...props.innerProps} style={props.getStyles("loadingMessage", props)}>
      Нет результатов
    </div>
  );
};

const AsyncPaginateBase = props => {
  return (
    <AsyncPaginate
      placeholder={props.placeholder ? props.placeholder : "Выберите значение"}
      className={"select"}
      styles={baseCustomStyles}
      components={{ LoadingMessage, NoOptionsMessage }}
      additional={{
        page: 1
      }}
      {...props}
    />
  );
};

export default AsyncPaginateBase;
