import React from 'react';
import { Container } from '@garpix/component-system';
import { RestorePasswordSetPassword as RestorePasswordSetPasswordForm } from '../components/Auth'
import Layout from '../views';


const RestorePasswordSetPassword = (props) => {
  return (
    <Layout {...props}>
      <Container>
        <RestorePasswordSetPasswordForm />
      </Container>
    </Layout>
  )
}

export default RestorePasswordSetPassword