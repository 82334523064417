import React from 'react';
import { Authorization as AuthorizationFormik } from '@garpix/user';
import { Button, Form, ClearField, PasswordField } from '@garpix/component-system';
import { useStoreon } from 'storeon/react'
import api from "../../api";
import Errors from '../../views/Errors';


const Authorization = () => {
  const { dispatch } = useStoreon();
  const authorizationCallback = () => dispatch('user/get', {
    callback: () => dispatch('modal/toggle', { modal: null })
  });

  const forgotPasswordModal = () => dispatch('modal/toggle', { modal: 'forgot-password' });

  return (
    <AuthorizationFormik
      apiLogin={api.login}
      callback={authorizationCallback}
    >
      {({
        handleSubmit,
        handleChange,
        // handleBlur,
        values,
        touched,
        // isValid,
        setFieldValue,
        errors,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <ClearField
              label="Электронная почта"
              required
              name="username"
              onChange={setFieldValue}
              value={values.username}
            />
            {errors.username && touched.username ? (
              <Errors errors={errors.username} />
            ) : null}
          </div>
          <div className="form-group">
            <PasswordField
              required
              name="password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && touched.password ? (
              <Errors errors={errors.password} />
            ) : null}
          </div>
          <div className="form-rowsc">
            <Form.Check
              custom
              type={'checkbox'}
              id={`remember-me`}
              label={`Запомнить меня`}
            />
            <a href="#" onClick={forgotPasswordModal}>Забыли пароль?</a>
          </div>
          <div className="form-rowsc">
            {errors.non_field_errors ? (
              <Errors errors={errors.non_field_errors} />
            ) : null}
          </div>
          <div className="form-end">
            <div className="form-group">
              <Button disabled={isSubmitting} type="submit" className="btn btn--full">Войти</Button>
            </div>
          </div>
        </Form>
      )}
    </AuthorizationFormik>
  )
}

export default Authorization;