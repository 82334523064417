import React from "react";

const ProductPrice = ({price, oldPrice = null}) => {
  return (
    <>
      {price} <span>руб</span>
      {oldPrice ? <del className="prev-price">{oldPrice} ₽</del> : null}
    </>
  )
};

export default ProductPrice;
