import React from "react";
import AsyncPaginate from './base';
import api from "../../api";
import { optionSerializer } from "../../api/serializers";


const loadOptions = async (search, loadedOptions, { page }) => {
  const data = await api.getProductGroups({
    page,
    search
  });
  return {
    options: [
      { value: '', label: 'Все варианты' },
      ...data.results.map(optionSerializer)
    ],
    hasMore: !!data.next,
    additional: {
      page: page + 1,
    }
  }
};


const SelectGroup = ({
  onChange,
  value,
  name = "type__group",
  placeholder = 'Все группы'
}) => {
  return (
    <AsyncPaginate
      name={name}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default SelectGroup;