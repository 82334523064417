import React from 'react';
import { Button } from "@garpix/component-system";

const MessageSuccess = ({
  text = 'Данные успешно отправлены',
  handleClick = () => { }
}) => {
  return (
    <div className='message-block'>
      <div className='message-text'>{text}</div>
      <Button
        type='button'
        className="btn"
        onClick={handleClick}>Закрыть</Button>
    </div>
  )
};

export default MessageSuccess;