import React from "react";
import List from './List';
import BigBlock from './BigBlock';

const MODE = {
  'big_block': BigBlock,
  'list': List,
}

const ServiceList = ({ mode = 'list', isAddress = true, ...props }) => {
  const View = MODE[mode];
  return <View {...props} isAddress={isAddress} />
}

export default ServiceList;