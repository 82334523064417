import React from 'react';
import { useFormik } from 'formik';


const OrdersSearch = ({ callback }) => {
  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: values => {
      callback(1, values)
    },
  });
  const {
    handleSubmit,
    handleChange,
    values
  } = formik;
  return (
    <form onSubmit={handleSubmit} className="orders-search">
        <input 
        name="search"
        type="text"
        onChange={handleChange}
        value={values.search}
        className="orders-search__input form-input" 
        placeholder="Поиск по номеру или дате заказа..." 
      />
      <button className="orders-search__btn btn" />
    </form>
  )
}

export {
  OrdersSearch
};