import React from "react";
import Layout from "../views";
import Product from "../components/Product";

const ProductPage = props => {
  const { product, similar_products } = props;
  return (
    <Layout {...props} className={"main--bg"}>
      <Product
        product={product}
        size="detail"
        similar_products={similar_products}
      />
    </Layout>
  );
};

export default ProductPage;
