import React, { useState } from "react";
import { Formik } from 'formik';
import { useStoreon } from 'storeon/react'
import { Form, PasswordField } from '@garpix/component-system';
import { MessageSuccess } from '../../views/Messages';
import { SetPasswordSchema } from '../../schema';
import Errors from '../../views/Errors';
import api from '../../api';


const PasswordChange = () => {
  const [showType, setType] = useState('form');
  const { dispatch } = useStoreon();

  const handleSend = (params, { setSubmitting, setErrors }) => {
    api.setPassword(params)
      .then(res => {
        if (res.status) {
          setSubmitting(false);
          setType('success');
          setTimeout(() => {
            dispatch('modal/close');
          }, 4500);
        }
      })
      .catch(error => {
        setSubmitting(false);
        if (error.response) {
          setErrors(error.response.data)
        }
      })
  }

  return (
    <Formik
      validationSchema={SetPasswordSchema}
      onSubmit={handleSend}
      initialValues={{
        old_password: '',
        new_password: '',
        new_password2: ''
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        errors,
      }) => (
        <>
          {showType === 'form' ? (
            <Form onSubmit={handleSubmit} noValidate className="form form--login">
              <div className="form-group">
                <PasswordField
                  label="Старый пароль"
                  name="old_password"
                  onChange={handleChange}
                  value={values.old_password}
                />
                {errors.old_password && touched.old_password ? (
                  <Errors errors={errors.old_password} />
                ) : null}
              </div>
              <div className="form-group">
                <PasswordField
                  label="Новый пароль"
                  name="new_password"
                  onChange={handleChange}
                  value={values.new_password}
                />
                {errors.new_password && touched.new_password ? (
                  <Errors errors={errors.new_password} />
                ) : null}
              </div>
              <div className="form-group">
                <PasswordField
                  label="Новый пароль еще раз"
                  name="new_password2"
                  onChange={handleChange}
                  value={values.new_password2}
                />
                {errors.new_password2 && touched.new_password2 ? (
                  <Errors errors={errors.new_password2} />
                ) : null}
              </div>
              <div className="form-end">
                <input disabled={isSubmitting} className="btn btn--full" type="submit" value="Сменить пароль" />
              </div>
            </Form>
          ) : null}
          {showType === 'success' ? (
            <MessageSuccess
              handleClick={() => dispatch('modal/close')}
              text='Ваш пароль успешно изменен' />
          ) : null}
        </>
      )}
    </Formik>
  )
}

export default PasswordChange;
