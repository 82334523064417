import React from 'react';
import {Link} from "react-router-dom";
import Layout from "../views";

const EmptyPage = (props) => {
  return (
    <Layout {...props} className={"main--bg"}>
      <section className="news">
        <div className="container">
          <h1 className="news-head">Раздел находится в разработке</h1>
          <Link to={"/"} className="btn btn--arrowback"><span>На главную</span></Link>
        </div>
      </section>
    </Layout>
  )
};

export default EmptyPage;
