import React from 'react';
import { IMaskInput } from 'react-imask';
import { Form } from '@garpix/component-system';
import Errors from '../Errors';


const UnionTicket = ({ error, touched, value, setFieldValue, name = "", label = "", htmlFor = '' }) => {
  const onChange = (value, mask) => setFieldValue(name, value);
  const handleClear = () => setFieldValue(name, '');
  return (

    <>
      <div className="inputlabel">
        <Form.Label htmlFor={htmlFor} className='inputlabel__label'>{label}</Form.Label>
        <div className="inputlabel__wrapper">
          <IMaskInput
            mask={'0000/0000/0000/0000'}
            radix="."
            value={value}
            unmask={true}
            onAccept={onChange}
            className="inputlabel__input form-input"
          />
          <button
            type="button"
            className={`inputlabel__clear ${value ? 'show' : ''}`}
            onClick={handleClear}
          ></button>
        </div>
      </div>
      {error && touched ? (
        <Errors errors={error} />
      ) : null}
    </>
  )
}

export {
  UnionTicket
}
