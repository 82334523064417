import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreContext } from "storeon/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { store } from "./store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "axios-progress-bar/dist/nprogress.css";
import "./styles/bootstrap.scss";
import "./styles/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://23e58a6e02b34878ac20f07025067059@sentry.garpix.com/11"
  });
}

const render =
  process.env.NODE_ENV === "development" ? ReactDOM.render : ReactDOM.hydrate;

const CAPTCHA_KEY = "6Ld01ZMaAAAAAG2azQ7fLoLACZqUyYMjYfhz4Mic";

render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={CAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <StoreContext.Provider value={store}>
        <Router>
          <App />
        </Router>
      </StoreContext.Provider>
    </GoogleReCaptchaProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
