class Order {
  ORDER_STATUSES = {
    'created': 'created',
    'payed': 'payed',
    'canceled': 'canceled',
  }
  ORDER_STATUSES_NAME = {
    'created': 'Ожидает',
    'payed': 'Оплачен',
    'canceled': 'Отменен',
  }
  STATUS_STYLE = {
    'created': 'wait',
    'payed': 'ok',
    'canceled': 'cancel',
  }

  order = null;
  setOrder = order => this.order = order;

  groupByProducer = () => {
    const { ORDER_STATUSES_NAME } = this;
    const result = {};
    const { orderitem_set } = this.order;
    orderitem_set.map(item => {
      const { producer, producer_obj, item_total, qty, status } = item;
      if (!result[producer]) {
        result[producer] = {
          producer: producer_obj,
          products: [],
          total_qty: 0,
          total: 0,
          status_display: ORDER_STATUSES_NAME[status],
          status
        };
      }
      result[producer].total_qty += qty;
      result[producer].total += Number(item_total);
      result[producer].products.push(item);
      return item;
    });

    return result;
  }

  getActiveProductOfCart = (cart) => {
    return Object.values(cart)
      .filter(item => item.product.is_active)
  }
}

const order = new Order();

export {
  order
}
