import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import api from "../../api";
import { deleteArrayItem } from "../../utils";

const ShopAddressesList = ({ shop }) => {
  const [addressesList, setList] = useState(null);

  useEffect(() => {
    if (shop) {
      api
        .getShopAddresses({ shop_id: shop })
        .then(({ results = [] }) => {
          if (results.length > 0) setList(results);
          else setList([{ id: null, address: "" }]);
        })
        .catch(err => console.log(err, "err"));
    }
  }, [shop]);

  useEffect(() => {
    if (addressesList) {
      formik.setValues({ addressesList });
    }
  }, [addressesList]);

  const addInputAddress = () => {
    const { values, setValues } = formik;
    const newVals = { ...values };
    const { addressesList } = newVals;
    addressesList.push({ id: null, address: "" });
    setList(addressesList);
    setValues(newVals);
  };

  const createAddress = ({ index }) => {
    const { setSubmitting, values } = formik;
    const { address } = values.addressesList[index];
    api
      .addShopAddress({ shop, address })
      .then(res => {
        const addressesList = [...values.addressesList];
        addressesList[index] = res;
        setList(addressesList);
        setSubmitting(false);
      })
      .catch(err => console.log(err, "err"));
  };

  const updateAddress = ({ index }) => {
    const { setSubmitting, values } = formik;
    const { address, id } = values.addressesList[index];
    api
      .updateShopAddress({ id, address })
      .then(res => {
        const addressesList = [...values.addressesList];
        addressesList[index] = res;
        setList(addressesList);
        setSubmitting(false);
      })
      .catch(err => console.log(err));
  };
  const setListAfterDel = index => {
    const { setSubmitting, values } = formik;
    let addressesList = [...values.addressesList];

    if (addressesList.length > 1) {
      addressesList = deleteArrayItem(addressesList, index);
    } else {
      addressesList = [{ id: null, address: "" }];
    }
    setList(addressesList);
    setSubmitting(false);
  };

  const deleteAddress = ({ id, index }) => {
    const { setSubmitting } = formik;
    setSubmitting(true);
    if (id) {
      api
        .deleteShopAddress({ id })
        .then(res => {
          setListAfterDel(index);
        })
        .catch(err => console.log(err));
    } else {
      setListAfterDel(index);
    }
  };

  const handleSubmit = index => {
    const { setSubmitting, values } = formik;
    setSubmitting(true);
    const { id } = values.addressesList[index];
    if (id) updateAddress({ index });
    else createAddress({ index });
  };

  const formik = useFormik({
    initialValues: {}
  });

  return (
    <>
      {addressesList && formik ? (
        <>
          {addressesList.map((item, index) => {
            return (
              <div key={`addr-${index}`} className="form-row">
                <div className="form-col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={e =>
                        deleteAddress({
                          id: item.id,
                          index
                        })
                      }
                      className="profile-ava__delete"
                      title="Удалить адрес"
                      disabled={formik.isSubmitting}
                    />
                    <div className="form__label">Адрес</div>
                    <input
                      className="form-input"
                      type="text"
                      key={`input-addr-${index}`}
                      autoComplete={"none"}
                      onChange={e => {
                        const value = e.target.value;
                        const newVals = { ...formik.values };
                        const { addressesList } = newVals;
                        addressesList[index].address = value;
                        formik.setValues(newVals);
                      }}
                      onBlur={e => {
                        const value = e.target.value;
                        if (value && value !== "") {
                          handleSubmit(index);
                        }
                      }}
                      value={item.address}
                      name={"addressesList"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="profile-row">
            <div className="profile-col9">
              <div className="form-group">
                {addressesList.length <= 100 ? (
                  <button
                    onClick={addInputAddress}
                    type="button"
                    disabled={
                      addressesList[addressesList.length - 1].address === ""
                    }
                    className="btn btn--link"
                  >
                    + добавить адрес
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ShopAddressesList;
