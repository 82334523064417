import { Form } from '@garpix/component-system';
import { Formik } from "formik";
import React from 'react';
import SelectFilter from '../Select/SelectFilter';

const Filters = ({
  handleSubmit,
  initParams,
  options,
}) => {
  const submit = ({ filter }) => {
      handleSubmit(1, { ...initParams, order: filter.value })
  }


  return (
    <div className="category-filters">
      <Formik
        onSubmit={submit}
        initialValues={{ filter: 'new' }}
      >
        {({
          values,
          setFieldValue,
          submitForm,
        }) => {
          return (
            <Form>
              <div className="catalog-filters__col">

                <SelectFilter
                  onChange={value => {
                    setFieldValue('filter', value);
                    submitForm();
                  }}
                  name="producer__district"
                  value={values.producer__district}
                  placeholder="Сначала новые"
                  options={options}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Filters;
