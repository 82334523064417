import React from "react";
import Select from "react-select";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "none",
    // This line disable the blue border
    background: "#f6f7f8",
    height: 72,
    cursor: "pointer",
    boxShadow: state.isFocused ? 0 : 0,
    paddingLeft: 10,
    fontSize: "20px",
  }),
  dropdownIndicator: () => ({
    color: "rgb(36, 112, 212)",
    marginRight: "10px",
  }),
  indicatorsContainer: () => ({
    border: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default function SelectCalc({ data, onChange, value }) {
  const options = Object.keys(data).map((id) => ({
    value: data[id].id,
    label: data[id].title,
  }));

  return (
    <Select
      onChange={(value) => onChange(value)}
      name="credit_id"
      options={options}
      value={value}
      styles={customStyles}
    />
  );
}
