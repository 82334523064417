import React from "react";
import { dataStates } from "@garpix/fetcher";
import { Link } from "../Link";

const ServiceTable = ({
  results = [],
  status,
  isAddress = true,
  showAddProduct,
  showEditModal,
  showDeletedModal
}) => {
  return (
    <table className="table servlist-table">
      <tbody>
        <tr>
          <th>
            <span className="servlist-table__tname">Наименование</span>
          </th>
          <th>
            <span className="servlist-table__tinfo">Описание</span>
          </th>
          <th>
            <span className="servlist-table__tsale">Скидка</span>
          </th>
          <th></th>
        </tr>
        <tr>
          <td className="servlist-table__tadd table__tadd" colSpan="4">
            <button
              onClick={showAddProduct}
              type="button"
              className="btn__services-add catalog-item catalog-item--add"
            >
              <div className="catalog-item__add">
                <span>Добавить</span>
              </div>
            </button>
          </td>
        </tr>
        {status === dataStates.loaded && results.length > 0 ? (
          <>
            {results.map((element, idx) => {
              return (
                <tr key={element.id}>
                  <td className="servlist-table__title">
                    <span>
                      {element.slug ? (
                        <Link
                          target_blank={element.target_blank}
                          to={element.slug}
                          className="servlist-table__name"
                        >
                          {element.title}
                        </Link>
                      ) : (
                        <span className="servlist-table__name servlist-table__title">
                          {element.title}
                        </span>
                      )}
                    </span>
                  </td>
                  <td
                    dangerouslySetInnerHTML={{ __html: element.content }}
                  ></td>
                  <td>
                    <strong>
                      {element.discount ? `${element.discount} %` : ""}
                    </strong>
                  </td>
                  <td>
                    <div className="servlist-table__actions">
                      <button
                        onClick={() => showDeletedModal(idx, element.id)}
                        type="button"
                        className="btn--white btn--white-del"
                      />
                      <button
                        onClick={() => showEditModal(idx, element)}
                        type="button"
                        className="btn--white btn--white-edit"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td>Список пуст</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ServiceTable;
