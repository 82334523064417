import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { deleteArrayItem } from "../../utils";
import api from "../../api";
import { ACCEPT_FILES_IMAGE } from "../../const";

const classNames = require("classnames");

const styleHiddenInput = {
  position: "absolute",
  top: 0,
  right: 0,
  margin: 0,
  padding: 0,
  opacity: 0,
  fontSize: 40
};

const styleField = {
  display: "inline-block",
  position: "relative",
  overflow: "hidden"
};

const ImageListUpload = ({
  setFieldValue,
  name,
  value = [],
  limit = 8,
  shopimage_set = []
}) => {
  const { dispatch } = useStoreon();
  const [imagesSrc, setSrcs] = useState([]);

  useEffect(() => {
    if (shopimage_set) {
      const list = [];
      shopimage_set.map(file => {
        list.push(file.image_url ? file.image_url : URL.createObjectURL(file));
      });
      setSrcs(list);
    }
  }, [shopimage_set]);

  const deleteItemList = idx => {
    const newList = deleteArrayItem(shopimage_set, idx);
    const deleteId = shopimage_set[idx].id ? shopimage_set[idx].id : null;
    // dispatch("modal/toggle", { modal: "image-delete", imageId: deleteId });

    if (deleteId) {
      api
        .deleteShopImage(deleteId)
        .then(res => {
          setNewList(newList);
        })
        .catch(err => console.log(err, "err"));
    } else {
      setNewList(newList);
    }
  };

  const setNewList = list => {
    setFieldValue(name, list);
    setFieldValue("shopimage_set", list);
  };

  const addImages = event => {
    const files = event.currentTarget.files;
    const newList = [...shopimage_set];

    const filesLength = Object.keys(files).length;
    const currentListLength = value.length;
    const length = filesLength + currentListLength;

    if (length <= limit) {
      Object.keys(files).map(key => newList.push(files[key]));
      setNewList(newList);
    } else {
      dispatch("modal/toggle", { modal: "error-upload" });
    }
  };

  const classes = classNames({
    "jq-file": true,
    "jq-file__image": true,
    "form-file": true,
    changed: true,
    "jq-file_disabled": value.length === limit
  });

  return (
    <>
      <div className={classes} style={styleField}>
        <div className="jq-file__browse">
          Загрузите одно или несколько изображений
        </div>
        <input
          accept={ACCEPT_FILES_IMAGE}
          className="form-file"
          type="file"
          name={name}
          disabled={value.length === limit}
          multiple
          style={styleHiddenInput}
          onChange={addImages}
        />
      </div>

      {imagesSrc && imagesSrc.length > 0 ? (
        <div className="upload-list">
          {imagesSrc.map((src, idx) => {
            return (
              <div className="upload-list__item" key={`image-${idx}`}>
                <img className="upload-list__img" src={src} />
                <button
                  onClick={e => deleteItemList(idx)}
                  type="button"
                  className="upload-list__btn-close"
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default ImageListUpload;
