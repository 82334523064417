import React from 'react';
import Layout from '../views';
import siteConfigurationSerializer from "../api/serializers/site_configuration";
import { NotFoundImage } from '../images';
import {Link} from "react-router-dom";

const Error404 = ({site_configuration, ...props}) => {
  return (
    <Layout site_configuration={siteConfigurationSerializer(site_configuration)} {...props}>
      <section className="p404">
        <div className="container">
          <img src={NotFoundImage} alt={""} />
          <h3 className="news-head">Страница не найдена или находится в разработке</h3>
          <Link to={"/"} className="btn btn--arrowback"><span>На главную</span></Link>
        </div>
      </section>
    </Layout>
  )
}

export default Error404;
