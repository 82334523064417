import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SelectCalc } from "../Select";
import Errors from "../../views/Errors";
import RangeSlider from "../RangeSlider";
import CreditTerms from "./CreditTerms";
import { Button, Form } from "@garpix/component-system";
import api from "../../api";

export default function CreditCalc({
  termCredits,
  setShowModal,
  getTermPriceCredit,
  main,
  links,
}) {
  const [timeout, setTout] = useState(null);
  const [selectVal, setSelectVal] = useState({
    value: termCredits[Object.keys(termCredits)[0]].id,
    label: termCredits[Object.keys(termCredits)[0]].title_ru,
  });
  const [initVals, setInitVals] = useState({
    credit_id: termCredits[selectVal.value].id,
    amount: termCredits[selectVal.value].min_credit,
    term: termCredits[selectVal.value].term,
  })
  
  const [calculatedPrice, setCalculatedPrice] = useState(0);

  useEffect(() => {
    getPriceAndSendFields(initVals);
  }, []);

  useEffect(() => {
    setInitVals({
      credit_id: termCredits[selectVal.value].id,
      amount: termCredits[selectVal.value].min_credit,
      term: termCredits[selectVal.value].term,
    })
  }, [selectVal])

  const showModal = () => setShowModal(true);

  const getPriceAndSendFields = async (values) => {
    const val = await api.getCalcValue(values);
   
    setCalculatedPrice(val.mp);
    getTermPriceCredit({
      term: values.term,
      loan_amount: values.amount,
      credit: values.credit_id,
    });
  };
 
  return (
    <>
      <div className="credcalc-row">
        <Formik
          enableReinitialize={true}
          onSubmit={(values) => {
            if (timeout) clearTimeout(timeout);
            setTout(
              setTimeout(() => {
                getPriceAndSendFields(values);
              }, 700)
            );
          }}
          initialValues={initVals}
        >
          {({
            handleSubmit,
            values,
            touched,
            errors,
            setFieldValue,
            submitForm,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="credcalc-form form"
            >
              <div className="form-group">
                <div className="form-label">Программа займа</div>
                <SelectCalc
                  data={termCredits}
                  onChange={(value) => {
                    if (timeout) clearTimeout(timeout);
                    setFieldValue("credit_id", value.value);
                    setSelectVal(value);
                    submitForm();
                  }}
                  value={selectVal}
                />
                {errors.credit_id && touched.credit_id ? (
                  <Errors errors={errors.credit_id} />
                ) : null}
              </div>
              <div className="form-group">
                <div className="form-label">Сумма займа</div>
                <div className="range-box">
                  <div>{values.amount.toLocaleString("ru-RU")}</div>
                  <div> ₽</div>
                </div>
                <RangeSlider
                  step={1000}
                  min={termCredits[selectVal.value].min_credit}
                  max={termCredits[selectVal.value].max_credit}
                  values={[values.amount]}
                  name="amount"
                  getRangeVals={(val) => {
                    setFieldValue("amount", val[0]);
                    submitForm();
                  }}
                />
                <div className="min-max">
                  <div>{termCredits[selectVal.value].min_credit.toLocaleString("ru-RU")} ₽</div>
                  <div>{termCredits[selectVal.value].max_credit.toLocaleString("ru-RU")} ₽</div>
                </div>
                {errors.amount && touched.amount ? (
                  <Errors errors={errors.amount} />
                ) : null}
              </div>
              <div className="form-group">
                <div className="form-label">Срок займа</div>
                <div className="range-box">
                  <div>{values.term}</div>
                  <div> мес.</div>
                </div>
                <RangeSlider
                  step={1}
                  min={1}
                  max={termCredits[selectVal.value].term}
                  values={[values.term]}
                  name="term"
                  getRangeVals={(val) => {
                    setFieldValue("term", val[0]);
                    submitForm();
                  }}
                />
                <div className="min-max">
                  <div>1 мес.</div>
                  <div>{termCredits[selectVal.value].term} мес.</div>
                </div>
                {errors.term && touched.term ? (
                  <Errors errors={errors.term} />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
        <div className="credcalc-results">
          <div className="credcalc-results__block">
            <div className="credcalc-results__heading">Результат расчёта</div>
            <div className="credcalc-results__row">
              <div className="credcalc-results__sums">
                <div className="credcalc-results__label">
                  Ежемесячный платеж
                </div>
                <div className="credcalc-results__sum">
                  {calculatedPrice.toLocaleString("ru-RU")} ₽
                </div>
              </div>
              <div className="credcalc-results__percs">
                <div className="credcalc-results__label">Процентная ставка</div>
                <div className="credcalc-results__perc">
                  {termCredits[selectVal.value].interest_rate}{" "}
                  %
                </div>
              </div>
            </div>
            <div className="credcalc-results__bottom">
              <Button onClick={showModal}>Отправить заявку</Button>
              <div className="credcalc-results__info">
                Расчёты предварительные. Не является публичной офертой.
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreditTerms
        data={termCredits}
        selectVal={selectVal.value}
        link={termCredits[selectVal.value].file}
        title="Условия"
        showModal={showModal}
      />
    </>
  );
}
