import React from "react";
import { Container } from "@garpix/component-system";
import { Link } from "react-router-dom";
import Layout from "../views";
import ServiceList from "../components/ServiceList";
import api from "../api";

const Services = ({
  content,
  image_url,
  subservice_set,
  shops,
  partners,
  partners_view_type,
  features,
  features_link_title,
  features_link,
  ...props
}) => {
  const { title } = props;

  return (
    <Layout {...props} className={"main--grad"}>
      <section className="services">
        <Container>
          {features.length === 0 ? (
            <div className="services-intro">
              <div className="services-intro__left">
                {features_link ? (
                  <Link style={{ color: "#000" }} to={features_link}>
                    <h1 className="health-intro__heading">{title}</h1>
                  </Link>
                ) : (
                  <h1 className="health-intro__heading">{title}</h1>
                )}
                <div
                  className="services-intro_text"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
              <div className="services-intro__right">
                <img className="services-intro__img" src={image_url} alt="" />
              </div>
            </div>
          ) : (
            <div className="services-intro2">
              <div className="services-intro2__top">
                <div className="services-intro2__left">
                  <h1 className="services-intro2__heading">{title}</h1>
                  <div
                    className="services-intro2__text"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
                <div className="services-intro2__right">
                  <img
                    className="services-intro2__img"
                    src={image_url}
                    alt=""
                  />
                </div>
              </div>
              <div className="services-intro2__btm">
                <div className="services-intro2__row">
                  {features.map(item => {
                    return (
                      <div key={item.id} className="services-intro2__col">
                        <div className="services-intro2__value">
                          {item.title}
                        </div>
                        <div className="services-intro2__label">
                          {item.feature_text}
                        </div>
                      </div>
                    );
                  })}
                  {features_link_title ? (
                    <div className="services-intro2__col">
                      <Link to={features_link} className="btn btn--arrow">
                        <span>{features_link_title}</span>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {subservice_set.length > 0 ? (
            <div className="health-items">
              {subservice_set.map(item => {
                return (
                  <div key={item.id} className="health-item">
                    <img className="health-item__icon" src={item.icon} alt="" />
                    <div className="health-item__name">{item.title}</div>
                    <div className="health-item__text">
                      {item.short_content}
                    </div>
                    <div className="health-item__btm">
                      <Link to={item.url} className="btn btn--arrow btn--full">
                        <span>Записаться к врачу</span>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Container>
      </section>
      <section className="cabinet">
        <div className="tabs__content">
          <ServiceList
            filterObj={{ service: props.id }}
            mode={"list"}
            data={shops}
            api={api.getShops}
            isDistrict={true}
          ></ServiceList>
        </div>
      </section>
    </Layout>
  );
};

export default Services;
