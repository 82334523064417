import React, { useState, useEffect, useRef } from "react";
import { Container } from "@garpix/component-system";
import ShopGallery from "./ShopGallery";
import ShopRaiting from "./ShopRaiting";
import ShopShare from "./ShopShare";
import ShopDescription from "./ShopDescription";
import ShopGoodsAndServices from "./ShopGoodsAndServices";
import ShopAddresses from "./ShopAddresses";
import ShopReviews from "./ShopReviews";
import ShopDetailTabs from "../../views/ShopDetailTabs";

import { parserHtmlToString, executeScroll } from "../../utils";

const tabs = {
  tab1: "ОПИСАНИЕ",
  tab2: "ТОВАРЫ/УСЛУГИ",
  tab3: "АДРЕСА",
  tab4: "ОТЗЫВЫ"
};

const DetailShop = ({ shop_info }) => {
  const descrRef = useRef(null);
  const reviewsRef = useRef(null);
  const goodsRef = useRef(null);

  const [activeTab, setTab] = useState("tab1");
  const [reviewCount, setReviewCount] = useState(shop_info.review_count);
  const [reviewStar, setReviewStar] = useState(shop_info.review_star);
  const [description, setDescription] = useState("");
  const {
    offer_description,
    title,
    id,
    video_page,
    shopimage_set,
    youtube_id,
    producer
  } = shop_info;

  useEffect(() => {
    if (offer_description && offer_description.length > 0) {
      const descr = parserHtmlToString(offer_description);
      const length = descr.length;
      let trimmedString = descr;

      if (length > 500) {
        trimmedString = descr.substring(0, 496);
        trimmedString = trimmedString.replace(/\.$/, "") + "...";
      }
      setDescription(trimmedString);
    }
  }, [offer_description]);

  const setActiveTab = (e, tab) => {
    e.preventDefault();
    setTab(tab);
  };

  const setActiveTabAndScroll = (e, tab, ref) => {
    setActiveTab(e, tab);
    executeScroll(ref);
  };

  return (
    <section className="shopage">
      <Container>
        <div className="shopage-row">
          <div className="shopage-left">
            <ShopGallery
              youtube_id={youtube_id}
              video_page={video_page}
              data={shopimage_set}
            />
          </div>
          <div className="shopage-right">
            <h1 className="shopage-title">{title}</h1>
            <ShopRaiting
              review_count={reviewCount}
              review_star={reviewStar}
              setActiveTabAndScroll={e =>
                setActiveTabAndScroll(e, "tab4", reviewsRef)
              }
            />
            <div
              className="shopage-minidesc"
              // dangerouslySetInnerHTML={{ __html: offer_description }}
            >
              <p>{description}</p>
              {/* <a
                href="#"
                onClick={e => setActiveTabAndScroll(e, "tab1", descrRef)}
              >
                Полное описание
              </a> */}
            </div>
            <div className="shopage-btns">
              {/*тут кнопка фокусирует и открывает таб с товарами*/}
              <a
                href="#"
                className="shopage-btnmore btn"
                onClick={e => setActiveTabAndScroll(e, "tab2", goodsRef)}
              >
                Смотреть предложения
              </a>
            </div>
            <ShopShare />
          </div>
        </div>

        <div className="shopage-content">
          <div className="btabs">
            <ShopDetailTabs
              tabs={tabs}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
            <div className="btabs__content">
              <div
                ref={descrRef}
                className={`btabs__pane ${
                  activeTab === "tab1" ? "show active" : ""
                }`}
              >
                <ShopDescription shop_info={shop_info} />
              </div>
              <div
                ref={goodsRef}
                className={`btabs__pane ${
                  activeTab === "tab2" ? "show active" : ""
                }`}
              >
                <ShopGoodsAndServices shop_id={id} />
              </div>
              <div
                className={`btabs__pane ${
                  activeTab === "tab3" ? "show active" : ""
                }`}
              >
                <ShopAddresses shop_id={id} />
              </div>
              <div
                ref={reviewsRef}
                className={`btabs__pane ${
                  activeTab === "tab4" ? "show active" : ""
                }`}
              >
                <ShopReviews
                  producer={producer}
                  id={id}
                  reviewCount={reviewCount}
                  setReviewCount={setReviewCount}
                  setReviewStar={setReviewStar}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DetailShop;
