import React from 'react';
import { Form } from '@garpix/component-system';

const Errros = ({ errors }) => {
  let errorMessage = '';
  if(typeof errors === 'string') {
    errorMessage = errors
  }
  if(Array.isArray(errors)) {
    errorMessage = errors[0];
  }
  return (
    <div className="mt-1">
      <Form.Control.Feedback type="invalid" className="d-block">
        {errorMessage}
      </Form.Control.Feedback>
    </div>
  )
}

export default Errros