import React from 'react';
import './home_slider_template.scss'
import { Carousel } from "react-responsive-carousel";

const HomeSlider = ({ data }) => {
    return (
        <Carousel className="mt-5 mb-5 home-slider" showThumbs={false} showStatus={false}>
            {data && data.map((item) => {
                return (
                    <div key={item.id}>
                        <img className="slider-img" alt={item.title} src={item.image_url} />
                    </div>
                )
            })}
        </Carousel>
    )
}

export { HomeSlider }