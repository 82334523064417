import React from "react";
import { Link } from "react-router-dom";
import { preventDefault } from "../../utils";
import { CounterInput } from "@garpix/component-system";
import { getLegalFormName } from "../../utils";

const DefaultProduct = ({
  product,
  addToCart,
  params,
  isOrdered = true
  // addToWishlist,
  // removeFromWishlist
} = {}) => {
  const {
    // id,
    title,
    image_thumb,
    slug,
    price,
    producer_obj,
    unit = ""
    // old_price,
  } = product;
  const handleAddToCart = () => {
    addToCart();
  };
  const handleChangeProduct = count => addToCart({ count });
  return (
    <div className="catalog-item">
      <Link className="catalog-item__imgwrap" to={slug}>
        <div
          className="catalog-item__img"
          style={{ backgroundImage: `url(${image_thumb})` }}
        />
      </Link>
      <div className="catalog-item__desc">
        {producer_obj ? (
          <div className="catalog-item__prod">
            {getLegalFormName(producer_obj.legal_form, true)}{" "}
            {producer_obj.title}
          </div>
        ) : null}
        <Link className="catalog-item__name" to={slug}>
          {title}
        </Link>
      </div>
      <div className="catalog-item__btm">
        <div className="catalog-item__prices">
          <div className="catalog-item__price">
            {price} <span>руб</span>
          </div>
          <div className="catalog-item__value">за {unit}</div>
        </div>
        <div className="catalog-item__buys buys">
          {params ? (
            <div className="catalog-item__quants quants">
              <CounterInput
                count={params.count}
                name={"count"}
                min={1}
                max={1000}
                onCountChange={handleChangeProduct}
              />
            </div>
          ) : isOrdered ? (
            <a
              onClick={preventDefault(handleAddToCart)}
              className="catalog-item__buy js-catalogBuy"
              href="#"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DefaultProduct;
