import { Alert, Button } from "@garpix/component-system";
import React, { useState } from "react";
import api from "../../api";

const EmailConfirmed = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const resendConfirmEmail = () => {
    setSubmitting(true);
    api.resendConfirmEmail()
      .then(res => {
        setSuccess(true);
      })
      .catch(error => {
        console.log(error);
      })
  }

  if (success) {
    return (
      <Alert variant="success">
        <p><b>Отправлено письмо для подтверждения email</b></p>
      </Alert>
    )
  }
  return (
    <Alert variant="danger">
      <p><b>Ваш email не подтвержден</b></p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={resendConfirmEmail} disabled={isSubmitting} variant="warning">
          Подтвердить
        </Button>
      </div>
    </Alert>
  )
}

export {
  EmailConfirmed
}