import React from "react";
import { Modal, Button } from "@garpix/component-system";

const ErrorUpload = ({ handleClose }) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Ошибка</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-center">
          <div className="modal-text">
            Файлы не загружены. Вы можете загрузить не более 8 файлов.
          </div>
          <div className="modal-end">
            <Button
              onClick={handleClose}
              className="modal-btn"
              data-dismiss="modal"
            >
              Хорошо
            </Button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ErrorUpload;
