import siteConfigurationSerializer from "./site_configuration";
import breadcrumbsSerializer from "./breadcrumbs";

const shopSerializer = page => {
  const { init_state } = page
  const { shop_info } = init_state;
  const shopInfo = shop_info;
  return {
    ...init_state,
    ...shopInfo,
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(shopInfo)
  }
}

export default shopSerializer;
