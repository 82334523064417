import React from 'react';
import Select from 'react-select';
import baseCustomStyles from "./style";

const customStyles = {
  ...baseCustomStyles,
  control: base => ({
    ...base,
    fontSize: 14,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '14px',
  })
}

const SelectFilter = (props) => {
  return (
    <Select
      placeholder="Выберите значение"
      styles={customStyles}
      isSearchable={false}
      {...props}
    />
  )
}

export default SelectFilter;
