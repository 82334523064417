import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { Modal, Button } from '@garpix/component-system';
import Product from "../Product";
import api from "../../api";
import AddOrUpdateProduct from '../Account/AddOrUpdateProduct';
import PaginateLk from '../../views/Paginate/PaginateLk';

const EditorProducts = ({
  filterParams,
  count,
  results,
  activePage,
  loadData,
  showMore,
  isNext,
  deleteElement,
  updateElement,
}) => {
  const { currentUser } = useStoreon('currentUser');
  const [producer, setProducer] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [selectObj, setSelectObj] = useState(undefined);
  const handleClose = () => setShowModal(null);

  useEffect(() => {
    if (currentUser && currentUser.producer) {
      setProducer(currentUser.producer);
    }
  }, []);

  const showAddProduct = () => {
    setSelectObj(undefined);
    setShowModal('add-product');
  };

  const showDeletedModal = (index, id, closeDropMenu) => {
    closeDropMenu(false);
    setSelectObj({ index, id });
    setShowModal('delete-product');
  }

  const showEditModal = (index, product, closeDropMenu) => {
    closeDropMenu(false);
    setShowModal('update-product')
    setSelectObj({ index, ...product });
  }

  const createCallback = () => {
    handleClose();
    loadData(1, {});
  }

  const updateCallback = (params) => {
    const { index } = selectObj;
    updateElement(index, params);
    handleClose();
  }

  const handleDeleted = () => {
    const { index, id } = selectObj;
    deleteElement(index);
    api.deleteProduct(id);
    handleClose();
  };

  return (
    <>
      <div className="catalog-items">
        <button type={'button'} className="catalog-item catalog-item--add" onClick={showAddProduct}>
          <div className="catalog-item__add"><span>Добавить</span></div>
        </button>
        {results.map((item, index) => <Product
          key={item.id}
          product={item}
          size="edit"
          deleteProduct={showDeletedModal}
          editProduct={showEditModal}
          index={index}
        />
        )}
      </div>
      {isNext ? (
        <div className="catalog-bottom">
          <Button className="btn loadmore" onClick={showMore}><span>ПОКАЗАТЬ ЕЩЁ</span></Button>
        </div>
      ) : null}
      <PaginateLk
        loadData={loadData}
        count={count}
        activePage={activePage}
        params={filterParams} />
      {showModal === 'add-product' ? (
        <Modal centered={true} size={'xl'} show={!!showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Добавление продукта</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddOrUpdateProduct
              cancelCallback={handleClose}
              createCallback={createCallback}
              producer={producer.id}
              product={selectObj} />
          </Modal.Body>
        </Modal>
      ) : null}
      {showModal === 'update-product' ? (
        <Modal centered={true} size={'xl'} show={!!showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Редактирование продукта</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddOrUpdateProduct
              cancelCallback={handleClose}
              updateCallback={updateCallback}
              producer={producer.id}
              product={selectObj} />
          </Modal.Body>
        </Modal>
      ) : null}
      {showModal === 'delete-product' ? (
        <Modal centered={true} size={'sm'} show={!!showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Удаление продукта</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"modal-center"}>
              <div className={"modal-text"}>Вы точно хотите удалить выбранный продукт?</div>
              <div className={"modal-end"}>
                <div className={"modal-btns"}>
                  <Button onClick={handleClose} className={"btn--gray"}>Отменить</Button>
                  <Button onClick={handleDeleted} className={"btn--red"}>Удалить</Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  )
}

export default EditorProducts;