import React from "react";

const SearchForm = ({ handleSubmit, handleChange, values }) => {
  return (
    <form onSubmit={handleSubmit} className="search-form__form">
      <input
        className="search-form__input form-input"
        placeholder="Что вы ищете?"
        onChange={handleChange}
        value={values.q}
        name="q"
        required
      />
      <button className="search-form__btn btn" />
    </form>
  );
};

export default SearchForm;
