import React from "react";
import { Container, Button } from "@garpix/component-system";
import { FetcherList } from "@garpix/fetcher";
import { useStoreon } from "storeon/react";
import Product from "../Product";
import MapFilter from "./MapFilter";
import Producer from "./Producer";
import Filters from "./Filters";
import api from "../../api";
import Paginate from "../../views/Paginate";

const Catalog = ({ initProducts = {}, producer }) => {
  const { currentUser } = useStoreon("currentUser");
  const district_obj =
    currentUser && currentUser.district_obj ? currentUser.district_obj : null;

  const initFilter = producer
    ? {
        producer: producer.id,
      }
    : {};

  return (
    <FetcherList
      api={api.getFermersProducts}
      initData={initProducts}
      initFilter={initFilter}
    >
      {({
        count,
        results = [],
        activePage,
        loadData,
        showMore,
        isNext,
        filterParams
      }) => {
        const { producer__district } = filterParams;
        const isProducer = !!producer;
        const filterParamsKey = Object.keys(filterParams).length;
        return (
          <div className="catalog">
            <Container>
              {isProducer ? (
                <Producer producer={producer} />
              ) : (
                <MapFilter
                  handleFilter={loadData}
                  district={
                    producer__district
                      ? producer__district
                      : district_obj
                      ? district_obj.id
                      : -1
                  }
                />
              )}
              <Filters
                initParams={filterParams}
                handleSubmit={loadData}
                producer={producer}
                district={district_obj}
              />
              {filterParamsKey === 0 ? (
                <h2 className="catalog-heading">
                  Каталог продуктов: <span>{count}</span>
                </h2>
              ) : (
                <h2 className="catalog-heading">
                  Результаты запроса: <span>{count}</span>
                </h2>
              )}
              <div className="catalog-items">
                {results.map(item => (
                  <Product key={item.id} product={item} size="catalog" />
                ))}
              </div>
              <div className={"catalog-bottom"}>
                {isNext ? (
                  <Button onClick={showMore} className="btn loadmore">
                    <span>ПОКАЗАТЬ ЕЩЁ</span>
                  </Button>
                ) : null}
                <Paginate
                  count={count}
                  activePage={activePage}
                  params={filterParams}
                />
              </div>
            </Container>
          </div>
        );
      }}
    </FetcherList>
  );
};

export { Catalog };
