import React, { useState } from "react";
import { Link } from "react-router-dom";
import { preventDefault, getLegalFormName } from "../../utils";

const EditProduct = ({ product, deleteProduct, editProduct, index }) => {
  const [toggleShowMenu, setToggleShowMenu] = useState(false);
  const {
    title,
    price,
    slug,
    image_thumb,
    id,
    unit = "",
    producer_obj
  } = product;
  const handleDelete = preventDefault(() =>
    deleteProduct(index, id, setToggleShowMenu)
  );
  const handleEdit = preventDefault(() =>
    editProduct(index, product, setToggleShowMenu)
  );
  return (
    <div className="catalog-item">
      <Link className="catalog-item__imgwrap" to={slug}>
        <div
          className="catalog-item__img"
          style={{ backgroundImage: `url(${image_thumb})` }}
        />
      </Link>
      <div className="catalog-item__desc">
        <div className="catalog-item__prod">
          {getLegalFormName(producer_obj.legal_form, true)} {producer_obj.title}
        </div>
        <Link className="catalog-item__name" to={slug}>
          {title}
        </Link>
      </div>
      <div className="catalog-item__btm">
        <div className="catalog-item__prices">
          <div className="catalog-item__price">
            {price} <span>руб</span>
          </div>
          <div className="catalog-item__value">за {unit}</div>
        </div>
        <div className="catalog-item__buys buys">
          {/* <a className="catalog-item__del" href="#" onClick={handleDelete} title="Удалить" /> */}
          {/* <a className="catalog-item__edit" href="#" onClick={handleEdit} title="Редактировать" /> */}
          <button
            onClick={() => setToggleShowMenu(!toggleShowMenu)}
            type={"button"}
            href="#"
            className="catalog-item__edit"
          ></button>
          <div
            onClick={() => setToggleShowMenu(false)}
            className={`${
              toggleShowMenu ? "open-edit__wrapper" : "close-edit__wrapper"
            }`}
          ></div>
          <div
            id={id}
            className={`dropdown-edit-menu ${
              toggleShowMenu ? "open-edit" : "close-edit"
            }`}
          >
            <a onClick={handleEdit} className="dropdown-item" href="#">
              <svg
                className={"dropdown-item__icon edit"}
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3335 1.99955C11.5086 1.82445 11.7165 1.68556 11.9452 1.5908C12.174 1.49604 12.4192 1.44727 12.6668 1.44727C12.9145 1.44727 13.1597 1.49604 13.3884 1.5908C13.6172 1.68556 13.8251 1.82445 14.0002 1.99955C14.1753 2.17465 14.3142 2.38252 14.4089 2.61129C14.5037 2.84006 14.5524 3.08526 14.5524 3.33288C14.5524 3.58051 14.5037 3.8257 14.4089 4.05448C14.3142 4.28325 14.1753 4.49112 14.0002 4.66622L5.00016 13.6662L1.3335 14.6662L2.3335 10.9996L11.3335 1.99955Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Редактировать</span>
            </a>
            {/* <a className="dropdown-item" href="#">
              <svg className={'dropdown-item__icon copy'} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9998 5.66602H6.99984C6.26346 5.66602 5.6665 6.26297 5.6665 6.99935V12.9993C5.6665 13.7357 6.26346 14.3327 6.99984 14.3327H12.9998C13.7362 14.3327 14.3332 13.7357 14.3332 12.9993V6.99935C14.3332 6.26297 13.7362 5.66602 12.9998 5.66602Z" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 9.66667H2.33333C1.97971 9.66667 1.64057 9.52619 1.39052 9.27614C1.14048 9.02609 1 8.68696 1 8.33333V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H8.33333C8.68696 1 9.02609 1.14048 9.27614 1.39052C9.52619 1.64057 9.66667 1.97971 9.66667 2.33333V3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>Копировать</span>
            </a> */}
            <a
              href="#"
              onClick={handleDelete}
              className="dropdown-item"
              href="#"
            >
              <svg
                className={"dropdown-item__icon delete"}
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 3.66602H2.33333H13"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.3335 3.66667V2.33333C4.3335 1.97971 4.47397 1.64057 4.72402 1.39052C4.97407 1.14048 5.31321 1 5.66683 1H8.3335C8.68712 1 9.02626 1.14048 9.27631 1.39052C9.52635 1.64057 9.66683 1.97971 9.66683 2.33333V3.66667M11.6668 3.66667V13C11.6668 13.3536 11.5264 13.6928 11.2763 13.9428C11.0263 14.1929 10.6871 14.3333 10.3335 14.3333H3.66683C3.31321 14.3333 2.97407 14.1929 2.72402 13.9428C2.47397 13.6928 2.3335 13.3536 2.3335 13V3.66667H11.6668Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Удалить</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
