import React, { useEffect, useState, useRef } from "react";
import { useStoreon } from "storeon/react";
import { Button } from "@garpix/component-system";
import { FetcherList, dataStates } from "@garpix/fetcher";
import Paginate from "../../views/Paginate";
import ShopReviewForm from "./ShopReviewForm";
import ShopReplyForm from "./ShopReplyForm";
import ReviewItem from "./ReviewItem";
import { executeScroll } from "../../utils";

import api from "../../api";

const ShopReviews = ({
  id,
  reviewCount = 0,
  setReviewCount,
  producer,
  setReviewStar
}) => {
  const { currentUser } = useStoreon("currentUser");
  const replyFormRef = useRef(null);

  const [userType, setUserType] = useState(null);
  const [reviewId, setReviewId] = useState(null);

  useEffect(() => {
    if (producer && currentUser) {
      if (currentUser.producer && producer === currentUser.producer.id) {
        setUserType("producer");
      } else {
        setUserType("user");
      }
    }
  }, [producer, currentUser]);

  const handleClickReply = ({ id }) => {
    setReviewId(id);
    executeScroll(replyFormRef);
  };

  const setCountAndStar = res => {
    const starsList = [];
    res.map(elem => starsList.push(elem.star));
    const sum = starsList.reduce((a, b) => a + b, 0);
    const avg = sum / starsList.length || 0;

    setReviewCount(res.length);
    setReviewStar(avg);
  };

  return (
    <FetcherList
      api={api.getShopReviews}
      isHistoryPush={false}
      initFilter={{
        page: 1,
        shop_id: id
      }}
    >
      {({
        count,
        results = [],
        activePage,
        showMore,
        status,
        isNext,
        filterParams,
        reload
      }) => {
        setCountAndStar(results);
        return (
          <div className="shopage-reviews">
            <div className="shopage-reviews__row">
              <div className="shopage-reviews__left">
                <div className="shopage-reviews__heading">
                  Отзывы ({reviewCount})
                </div>
                {status === dataStates.loaded && results.length === 0 ? (
                  <div className="shopage-reviews__empty">
                    Пока отзывов нет. Не расстраивайтесь, Ваш отзыв может стать
                    первым!
                  </div>
                ) : null}
                {status === dataStates.loaded && results.length > 0 ? (
                  <div className="shopage-reviews__items">
                    {results.map(item => {
                      return (
                        <ReviewItem
                          key={`review-id-${item.id}`}
                          handleClickReply={handleClickReply}
                          userType={userType}
                          review={item}
                        />
                      );
                    })}
                    {isNext ? (
                      <Button onClick={showMore} className="btn loadmore">
                        <span>ПОКАЗАТЬ ЕЩЁ</span>
                      </Button>
                    ) : null}
                    <Paginate
                      count={count}
                      activePage={activePage}
                      params={filterParams}
                    />
                  </div>
                ) : null}
              </div>
              <div className="shopage-reviews__right">
                {userType && userType === "producer" && results.length > 0 ? (
                  <ShopReplyForm
                    replyFormRef={replyFormRef}
                    reviewId={reviewId}
                    id={id}
                    reload={reload}
                  />
                ) : null}
                {userType && userType === "user" ? (
                  <ShopReviewForm id={id} reload={reload} />
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
    </FetcherList>
  );
};

export default ShopReviews;
