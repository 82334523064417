import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Logo, Garpix } from '../../images';


export default ({ site_configuration, menu = [] }) => {
  const { policy_slug, logo_text } = site_configuration;
  const [year, setYear] = useState(dayjs().year());
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-row">
            <div className="footer-left">
              <a className="footer-logo"
                href='https://xn--80aabfwcj3bcabdfofl4c2l4a.xn--p1ai/'
                target="_blank"
                rel="noopener noreferrer">
                <Logo />
              </a>
            </div>
            <div className="footer-middle" dangerouslySetInnerHTML={{ __html: logo_text }} />
            <div className="footer-right">
              <div className="footer-menu">
                {menu.map(item => {
                  return (
                    <div key={item.id} className="footer-menu__item">
                      <Link className="footer-menu__link" to={item.url}>{item.title}</Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container"><a className="totop js-Up" href="#" /> 
          <div className="footer-col">©{year}, Краснодарская краевая организация Общероссийского Профсоюза образования</div>
          <div className="footer-col"><Link to={policy_slug}>Политика конфиденциальности</Link></div>
          <div className="footer-col">
            <a className="garpix" href="https://garpix.com/" target="_blank">
              Разработано
              <Garpix />
            </a>
          </div>
        </div>
      </div>
    </footer>

  )
}
