import React from "react";

export const StarFull = () => {
  return (
    <div style={{ width: "20px", height: "22px", color: "#3873ee" }}>
      <svg
        enableBackground="new 0 0 24 24"
        height="16"
        viewBox="0 0 24 24"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m23.363 8.584-7.378-1.127-3.307-7.044c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127c-.606.093-.848.83-.423 1.265l5.36 5.494-1.267 7.767c-.101.617.558 1.08 1.103.777l6.59-3.642 6.59 3.643c.54.3 1.205-.154 1.103-.777l-1.267-7.767 5.36-5.494c.425-.436.182-1.173-.423-1.266z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export const StarEmpty = () => {
  return (
    <div style={{ width: "20px", height: "22px", color: "#B5CFE8" }}>
      <svg
        fill="currentColor"
        enableBackground="new 0 0 24 24"
        height="16"
        viewBox="0 0 24 24"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m5.574 15.362-1.267 7.767c-.046.283.073.568.308.734.234.165.543.183.795.043l6.59-3.642 6.59 3.643c.114.062.239.093.363.093.152 0 .303-.046.432-.137.235-.166.354-.451.308-.734l-1.267-7.767 5.36-5.494c.196-.201.264-.496.175-.762-.089-.267-.32-.46-.598-.503l-7.378-1.127-3.307-7.044c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127c-.278.043-.509.236-.598.503s-.022.561.174.762zm3.063-6.464c.247-.038.459-.196.565-.422l2.798-5.961 2.798 5.96c.106.226.318.385.565.422l6.331.967-4.605 4.72c-.167.17-.242.41-.204.645l1.08 6.617-5.602-3.096c-.113-.062-.238-.094-.363-.094s-.25.031-.363.094l-5.602 3.096 1.08-6.617c.038-.235-.037-.474-.204-.645l-4.605-4.72z" />
      </svg>
    </div>
  );
};
