import React from 'react';
import { useStoreon } from 'storeon/react'
import { FetcherList } from '@garpix/fetcher';
import api from '../../api'

const Shops = () => {
  const { dispatch } = useStoreon();

  const toggleCreateStore = ({ shop }) => {
    dispatch('shop/toggle', {
      isCreateShop: true,
      shop
    });
  }

  return (
    <div className="container">
      <div className="shops">
        <div className="my-shops">
          <FetcherList
            api={api.getShopProducer}
            isHistoryPush={false}>
            {({
              status,
              results = [],
              count
            }) => {
              if (status === 'loaded' && count === 0) return (
                <>
                  <h3>Здесь будут ваши торговые площадки</h3>
                  <p className="my-shops__no-shops" >Вы еще не создали торговые площадки</p>
                </>
              )
              return (
                <>
                  <h3>Торговые площадки</h3>
                  <table className="table servlist-table">
                    <tbody>
                      {results.map(element => {
                        return (
                          <tr key={element.id}>
                            <td className='my-shops__name-shop'>
                              <strong>
                                <a onClick={(e) => {
                                  e.preventDefault();
                                  toggleCreateStore({ shop: element });
                                }}>
                                  {element.title}
                                </a>
                              </strong>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </>
              )
            }}
          </FetcherList>
          <button onClick={(e) => toggleCreateStore({
            shop: {
              id: null,
              image: '',
              district: null,
              district_obj: undefined,
              service: null,
              service_obj: undefined,
              title: '',
              category: null,
              category_obj: undefined,
              offer_description: ''
            }
          })} type={'button'} className='btn btn--plus'>
            <span>Создать торговую площадку</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Shops;