import { Modal } from "@garpix/component-system";
import React from "react";
import { RestorePassword } from '../Auth/RestorePassword'

const ForgotPasswordModal = () => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Забыли пароль?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RestorePassword />
      </Modal.Body>
    </>
  )
}

export default ForgotPasswordModal