import {Button, Modal} from "@garpix/component-system";
import React from "react";

const ModalDialog = ({
  yesCallback,
  noCallback,
  show,
  title = '',
  text = '',
}) => {
  return (
    <Modal centered={true} size={'sm'} show={show} onHide={noCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"modal-center"}>
          <div className={"modal-text"}>{text}</div>
          <div className={"modal-end"}>
            <div className={"modal-btns"}>
              <Button onClick={noCallback} className={"btn--gray"}>Нет</Button>
              <Button onClick={yesCallback} className={"btn--red"}>Да</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {
  ModalDialog
}