import React, { useState } from "react";
import { Formik } from "formik";
import { Form, PasswordField, ClearField } from '@garpix/component-system';
import Errors from '../../views/Errors';
import { EmailChangeSchema } from '../../schema';
import api from '../../api'


const EmailChange = () => {
  const [isShowSuccess, setShowSuccess] = useState(false)
  const send = (params, { setSubmitting, setErrors }) => {
    api.emailChange(params)
      .then(res => {
        setShowSuccess(true)
      })
      .catch(error => {
        setSubmitting(false)
        if (error.response) {
          setErrors(error.response.data)
        }
      })
  }
  if (isShowSuccess) {
    return (
      <div className="modal-center">
        <div className="modal-text">
          Отправлено письмо для подтверждения email
        </div>
      </div>
    )
  }
  return (
    <Formik
      validationSchema={EmailChangeSchema}
      onSubmit={send}
      initialValues={{
        email: '',
        password: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        errors,
        setFieldValue
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form--login">
          <div className="form-group">
            <ClearField
              label="Новый E-mail"
              name="email"
              onChange={setFieldValue}
              value={values.email}
            />
            {errors.email && touched.email ? (
              <Errors errors={errors.email} />
            ) : null}
          </div>
          <div className="form-group">
            <PasswordField
              label="Пароль"
              name="password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && touched.password ? (
              <Errors errors={errors.password} />
            ) : null}
          </div>
          <div className="form-end">
            <input disabled={isSubmitting} className="btn btn--full" type="submit" value="Сменить e-mail" />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EmailChange;
