import React, { useEffect, useState, useCallback } from "react";
import {useDropzone} from 'react-dropzone'


const ImageUpload = ({ children, setFieldValue = () => {} }) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = file => {
    if (!file) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(file)
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    const file = acceptedFiles[0];
    setSelectedFile(file);
    setFieldValue(file)
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop })

  return <>{children({ preview, onSelectFile, selectedFile, getRootProps, getInputProps, isDragActive })}</>
}

export default ImageUpload;
