import React from 'react';
import { Container } from '@garpix/component-system';
import Swiper from '@garpix/react-swiper';
// import { Link } from 'react-router-dom';


const MainCarousels = ({ data = [] }) => {

  const settings = {
    isOutRender: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 500,
    mousewheel: false,
    rebuildOnUpdate: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  };
  return (
    <section className="mainslider">
      <Container>
        <div className="mainslider-slider">
          <Swiper {...settings} >
            {data.map((item, index) => {
              const { image_thumb, url, title, content, url_title } = item;
              return (
                <div key={index} className="swiper-slide">
                  <div key={index} className="mainslider-item">
                    <div className="mainslider-item__left">
                      <div className="mainslider-item__heading">
                        <p>{title}</p>
                      </div>
                      <div className="mainslider-item__text" dangerouslySetInnerHTML={{ __html: content }} />
                      <div className="mainslider-item__btm">
                        <a rel="noopener noreferrer" target="_blank" href={url} className="mainslider-item__btn btn btn--arrow">
                          <span>{url_title ? url_title : 'Подробнее'}</span>
                        </a>
                      </div>
                    </div>
                    <div className="mainslider-item__right">
                      <img className="mainslider-item__img" src={image_thumb} alt="" />
                    </div>
                  </div>
                </div>
              )
            })}
          </Swiper>
        </div>
      </Container>
    </section>
  )
}

export default MainCarousels;