import React from 'react';
import { FetcherList } from '@garpix/fetcher';
import { useStoreon } from 'storeon/react';
import { Link } from 'react-router-dom'
import Product from "../Product";
import api from "../../api";

const LastViewed = () => {
  const { page } = useStoreon('page');
  const { site_configuration } = page.data.page;
  return (
    <div className="container">
      <div className="cabinet-cat">
        <h3>Последние просмотренные товары</h3>
        <div className="catalog-items">
          <FetcherList
            api={api.getViewedProducts}
            isHistoryPush={false}
          >
            {({
              status,
              results = [],
              count
            }) => {
              if (status === 'loaded' && count === 0) return <div>Список пуст</div>
              return (
                <>
                  {results.map((item, index) => <Product
                    key={item.id}
                    product={item}
                  />)}
                </>
              )
            }}
          </FetcherList>
        </div>
        <div className="cabinet-cat__btm">
          <Link to={site_configuration.catalog_slug} className="btn btn--arrow"><span>Каталог товаров</span></Link>
        </div>
      </div>
    </div>
  )
}

export default LastViewed;