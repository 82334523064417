import React from 'react';
import { Modal } from '@garpix/component-system';
import LoanApplication from './LoanApplication';


const CreditModal = ({
  isShow,
  handleClose,
  termPriceCredit
}) => {
  return (
    <Modal centered={true} size={'sm'} show={isShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Заявка на займ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoanApplication termPriceCredit={termPriceCredit} callback={handleClose} />
      </Modal.Body>
    </Modal>
  )
}

export default CreditModal;