import React from 'react';
import { CartimImage } from '../../images';
import { useStoreon } from 'storeon/react';
import { Link } from 'react-router-dom';


const EmptyCart = () => {
  const { page } = useStoreon('page');
  const { site_configuration } = page.data.page;
  return (
    <div className="cart-empty">
      <div className="cart-empty__row">
        <div className="cart-empty__left">
          <img className="cart-empty__img" src={CartimImage} alt="" />
        </div>
        <div className="cart-empty__right">
          <h2>Корзина пока пуста</h2>
          <p>Добавьте товары в вашу корзину и оформите заказ</p>
          <div className="cart-empty__btm">
            <Link className="btn" to={site_configuration.catalog_slug}>Смотреть товары</Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export {
  EmptyCart
}