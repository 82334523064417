import React from "react";
import { Link } from "react-router-dom";
import { PlaceholderImage } from "../../images";
import { currencyFormat } from "../../utils";

const OrderProductItem = ({ product, qty = 0, item_total = 0 }) => {
  return (
    <div className="cart-item">
      {product ? (
        <>
          <div className="cart-item__wrap">
            <img
              className="cart-item__img"
              src={product.image_thumb ? product.image_thumb : PlaceholderImage}
              alt={""}
            />
            <div className="cart-item__namewrap">
              <Link className="cart-item__name" to={product.slug}>
                {product.title}, {product.unit}
              </Link>
            </div>
          </div>
          <div className="cart-item__prices">
            <div className="cart-item__label">Позиции</div>
            <div className="cart-item__price">{qty}</div>
          </div>
          <div className="cart-item__sum">
            <div className="cart-item__label">Сумма</div>
            <div className="cart-item__price">{currencyFormat(item_total)}</div>
          </div>
        </>
      ) : (
        <div className="cart-item__namewrap">Товар был удален</div>
      )}
    </div>
  );
};

export { OrderProductItem };
