import React from 'react';

const HeaderSearchForm = ({
  handleSubmit,
  handleChange,
  values
}) => {
  return (
    <form onSubmit={handleSubmit} className="header-search__form" autoComplete={'off'}>
      <input
        className="header-search__input form-input"
        placeholder="Что вы ищете?"
        onChange={handleChange}
        value={values.q}
        name="q"
        required
      />
      <button className="header-search__btn btn" />
    </form>
  )
}

export default HeaderSearchForm;