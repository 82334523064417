import React from "react";
import Rating from "react-rating";
import { getPlural } from "../../utils";
import { StarEmpty, StarFull } from "./RaitingStars";

const ShopRaiting = ({
  review_count = 0,
  review_star = 0,
  setActiveTabAndScroll = () => {}
}) => {
  return (
    <div className="shopage-rating">
      <div className="shopage-rating__stars">
        <Rating
          emptySymbol={<StarEmpty />}
          fullSymbol={<StarFull />}
          initialRating={review_star}
          readonly
        />
      </div>
      <a
        href="#"
        className="shopage-rating__link"
        onClick={e => setActiveTabAndScroll(e)}
      >
        {review_count} {getPlural(review_count, "отзыв", "отзыва", "отзывов")}
      </a>
    </div>
  );
};

export default ShopRaiting;
