import React from 'react';
import Layout from '../views';
import Cart from '../components/Cart';


const CartPage = (props) => {
  return (
    <Layout {...props}>
      <Cart {...props} />
    </Layout>
  )
}

export default CartPage;
