import React, { useState, createRef, useEffect } from "react";
import {
  YMaps,
  Map,
  ZoomControl,
  TrafficControl,
  GeolocationControl,
  Placemark
} from "react-yandex-maps";

import api from "../../api";

const style = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const ShopMap = ({ shop_id }) => {
  const [center, setCenter] = useState([45.544904, 39.610422]);
  const [addresses, setList] = useState([]);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    if (shop_id) {
      api
        .getShopAddresses({ shop_id })
        .then(res => setList(res.results))
        .catch(err => console.log(err, "err"));
    }
  }, [shop_id]);
  
  useEffect(() => {
    if (addresses.length > 0) {
      setCenter([addresses[0].latitude, addresses[0].longitude]);
    }
  }, [addresses]);
  
  const mapRef = createRef(null);
  const toggleShow = () => {
    setShow(!isShow);
    const container = mapRef.current.container;
    setTimeout(() => {
      if (container) container.fitToViewport();
    }, 300);
  };

  return (
    <div className={`catalog-map ${isShow ? "catalog-map--opened" : ""}`}>
      <div className="catalog-map__map">
        <div className="catalog-map__wrapper">
          <YMaps>
            <Map
              instanceRef={mapRef}
              style={style}
              defaultState={{
                center: center,
                zoom: 6,
                controls: []
              }}
            >
              <GeolocationControl options={{ float: "left" }} />
              <ZoomControl options={{ float: "right" }} />
              <TrafficControl options={{ float: "right" }} />
              {addresses.map(item => (
                <Placemark
                  key={item.id}
                  geometry={[item.latitude, item.longitude]}
                />
              ))}
            </Map>
          </YMaps>
        </div>
      </div>
      <div type="button" onClick={toggleShow} className="catalog-map__overlay">
        <div className="catalog-map__intro">Нажмите, чтобы раскрыть карту</div>
      </div>
      <button
        type="button"
        onClick={toggleShow}
        className="catalog-map__show"
      ></button>
    </div>
  );
};

export default ShopMap;
