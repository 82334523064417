import React from 'react';
import AsyncPaginate from './base';
import { optionSerializer } from "../../api/serializers";
import api from "../../api";

const loadOptions = async (search, loadedOptions, { page }) => {
  const data = await api.getServicesList({
    page,
    search
  });
  return {
    options: [
      { value: '', label: 'Все варианты' },
      ...data.results.map(optionSerializer)
    ],
    hasMore: !!data.next,
    additional: {
      page: page + 1,
    }
  }
};

const SelectServices = ({
  onChange = () => { },
  value = '',
  name = 'services',
}) => {
  return (
    <AsyncPaginate
      name={name}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder='Все услуги'
    />
  )
}

export default SelectServices;