import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import Product from "../Product";
import { Form, Button } from "@garpix/component-system";
import { currencyFormat } from "../../utils";
import api from "../../api";
import { CartSchema } from "../../schema";
import { Formik } from "formik";
import { EmptyCart } from "./Empty";
import { PhoneField } from "../../views/Fields";
import { getLegalFormName } from "../../utils";
import { orderModel } from "../../business";

const Cart = ({ title, ...props }) => {
  const [isCreateOrder, setCreateOrder] = useState(false);
  const { dispatch, cart, currentUser } = useStoreon("cart", "currentUser");
  const [selectProduct, setSelectProduct] = useState([]);
  const [totalValues, setTotalValues] = useState({
    totalPrice: 0,
    totalCount: 0
  });
  const [showProducer, setShowProducer] = useState([]);
  const [producers, setProducers] = useState({});

  const cartLengthElements = Object.keys(cart).length;
  const activeProductLength = orderModel.getActiveProductOfCart(cart).length;

  const idsProducer = [];
  useEffect(() => {
    if (cartLengthElements > 0) {
      const idList = Object.keys(cart).map(key => Number(key));
      // TODO переделать на серверную корзину
      api
        .checkActiveProducts({ products: idList })
        .then(res => {
          let newCart = {};
          Object.keys(res).map(key => {
            newCart = {
              ...newCart,
              [key]: {
                id: Number(key),
                is_active: res[key]
              }
            };
          });
          dispatch("cart/update-items", { ...newCart });
          setCreateOrder(true);
        })
        .catch(err => console.log(err, "err"));
    }
  }, []);

  useEffect(() => {
    const results = {};
    let totalPrice = 0;
    let totalCount = 0;

    Object.values(cart).forEach(item => {
      const { product, params } = item;
      const { producer_obj, price } = product;
      const { id } = producer_obj;
      if (!results[id]) {
        results[id] = {
          producer: producer_obj,
          count: 0,
          total_sum: 0,
          products: []
        };
        idsProducer.push(id);
      }
      results[id].products.push(item);
      if (product.is_active) {
        const count = params.count;
        const total_sum = count * price;
        results[id].count += count;
        results[id].total_sum += total_sum;
        totalPrice += total_sum;
        totalCount += count;
      }
    });
    setProducers(results);
    setTotalValues({
      totalPrice,
      totalCount
    });
    setShowProducer(idsProducer);
  }, [cart]);

  const showContentProducer = id => {
    const results = [...showProducer];
    if (results.includes(id)) {
      setShowProducer(results.filter(el => el !== id));
    } else {
      results.push(id);
      setShowProducer(results);
    }
  };

  const handleCheckbox = product => {
    const { id } = product;
    const result = [...selectProduct];
    if (result.includes(id)) {
      setSelectProduct(result.filter(el => el !== id));
    } else {
      result.push(product.id);
      setSelectProduct(result);
    }
  };

  const handleSelectAll = () => {
    if (selectProduct.length === cartLengthElements) {
      setSelectProduct([]);
    } else {
      setSelectProduct(Object.keys(cart).map(Number));
    }
  };

  const removeSelectElements = () => {
    dispatch("cart/removeList", { ids: selectProduct });
  };

  const clearCart = () => dispatch("cart/clear");

  const createOrder = params => {
    api.createOrder({ cart, ...params }).then(res => {
      dispatch("cart/clear");
      dispatch("modal/toggle", { modal: "thank-order" });
    });
  };

  if (currentUser === null) return null;
  return (
    <section className="cart">
      <div className="container">
        <h3 className="cart-heading">{title}</h3>
        {cartLengthElements === 0 ? (
          <EmptyCart />
        ) : (
          <div className="cart-row">
            <div className="cart-left">
              <div className="cart-block">
                <div className="cart-head">
                  <div className="cart-head__check form-checkbox">
                    <Form.Check
                      custom
                      inline
                      label=""
                      type={"checkbox"}
                      id={`delete`}
                      onChange={handleSelectAll}
                      checked={
                        selectProduct.length > 0 &&
                        selectProduct.length === cartLengthElements
                      }
                    />
                    {/* надпись появляется когда что-то выбрал*/}
                    {selectProduct.length > 0 ? (
                      <div
                        onClick={removeSelectElements}
                        className="form-checkbox__label"
                      >
                        Удалить выбранные
                      </div>
                    ) : null}
                  </div>
                  <div className="cart-head__price">Цена за шт.</div>
                  <div className="cart-head__quant">Количество</div>
                  <div className="cart-head__sum">Сумма</div>
                </div>
                <div className="cart-body accordion" id="cartitems">
                  {Object.values(producers).map(item => {
                    const { products, producer, count, total_sum } = item;
                    const isShow = showProducer.includes(producer.id);
                    return (
                      <div
                        key={item.producer.id}
                        className="cart-items accordion__block"
                      >
                        <div
                          onClick={() => showContentProducer(producer.id)}
                          className={`cart-items__head accordion__heading ${
                            isShow ? "" : "collapsed"
                          }`}
                          aria-expanded={isShow}
                          aria-controls={`cart-items-${producer.id}`}
                        >
                          <div className="cart-items__name">
                            {getLegalFormName(producer.legal_form)}{" "}
                            {producer.title}
                          </div>
                          <div className="cart-items__quant">{count}</div>
                          <div className="cart-items__sum">
                            {currencyFormat(total_sum)}
                          </div>
                        </div>
                        <div
                          className={`cart-items__body accordion__content ${
                            isShow ? "collapse show" : "collapse"
                          }`}
                          id={`cart-items-${producer.id}`}
                        >
                          {products.map(item => {
                            const { product } = item;
                            const { id } = product;
                            return (
                              <Product
                                isChecked={selectProduct.includes(id)}
                                callbackCheckbox={handleCheckbox}
                                product={product}
                                key={id}
                                size="cart"
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Formik
              validationSchema={CartSchema}
              initialValues={{
                phone: currentUser && currentUser.phone ? currentUser.phone : ""
              }}
              onSubmit={createOrder}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                values,
                touched,
                isSubmitting,
                errors,
                setFieldValue
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="cart-right">
                  <div className="cart-total">
                    <div className="cart-total__top">
                      <h3 className="cart-total__heading">Итого</h3>
                      <div className="cart-total__items">
                        <div className="cart-total__name">Товаров</div>
                        <div className="cart-total__value">
                          {totalValues.totalCount}
                        </div>
                      </div>
                      <div className="cart-total__items">
                        <div className="cart-total__name">Сумма</div>
                        <div className="cart-total__value">
                          {currencyFormat(totalValues.totalPrice)}
                        </div>
                      </div>
                    </div>
                    <div className="cart-total__mid">
                      <div className="cart-total__phone">
                        <PhoneField
                          label="Ваш телефон"
                          value={values.phone}
                          error={errors.phone}
                          touched={touched.phone}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    {currentUser ? (
                      <div className="cart-total__btm">
                        <Button
                          type={"submit"}
                          disabled={
                            activeProductLength === 0 ||
                            isSubmitting ||
                            !isCreateOrder
                          }
                          className="btn btn--full"
                        >
                          Оформить заказ
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  <div className="cart-last">
                    <button
                      onClick={clearCart}
                      type="button"
                      className="cart-clear"
                    >
                      Очистить корзину
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </section>
  );
};

export default Cart;
