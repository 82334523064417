import productSerializer from './product';
import siteConfigurationSerializer from "./site_configuration";
import breadcrumbsSerializer from "./breadcrumbs";

const categoryPageSerializer = page => {
  const { init_state } = page;
  const { page_info, products } = init_state;
  products.results = products.results.map(productSerializer)
  return {
    ...init_state,
    ...page_info,
    products,
    site_configuration: siteConfigurationSerializer(init_state.site_configuration),
    breadcrumbs: breadcrumbsSerializer(page_info, 'producer_obj')
  }
}

export default categoryPageSerializer;
