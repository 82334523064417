import * as Yup from "yup";
import dayjs from "../utils/dayjs";
import { getLengthCKEditorData } from "../utils";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const maxLengthContent = 10000;
const maxLengthDescr = 500;

const urlErrorHint = "Введите корректный url";
const stringHint = "Введите строку";
const requiredHint = "Это обязательное поле";
const phoneErrorHint = "Введите корректный номер телефона";

const AccountSchema = Yup.object().shape({
  first_name: Yup.string("Введите строку").required("Это обязательное поле"),
  last_name: Yup.string("Введите строку").required("Это обязательное поле"),
  phone: Yup.string("Введите строку").matches(
    phoneRegExp,
    "Введите корректный номер телефона"
  ),
  birthdate: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .test("birthdate", "Введите корректную дату.", value =>
      dayjs(value, "DD.MM.YYYY").isValid()
    )
    .test("birthdate", "Введите корректную дату", value =>
      dayjs().isAfter(dayjs(value, "DD.MM.YYYY"))
    )
});

const PartnerAccountSchema = Yup.object().shape({
  title: Yup.string("Введите строку").required("Это обязательное поле"),
  inn: Yup.string("Введите строку")
    .strict(true)
    .trim("Строка не может быть пустой")
    .required("Это обязательное поле"),
  address: Yup.string("Введите строку")
    .strict(true)
    .trim("Строка не может быть пустой")
    .required("Это обязательное поле"),
  phone: Yup.string("Введите строку").matches(
    phoneRegExp,
    "Введите корректный номер телефона"
  ),
  contact_name: Yup.string("Введите строку").required("Это обязательное поле"),
  email: Yup.string("Введите строку")
    .email("Введите email адрес")
    .required("Это обязательное поле")
});

const EmailChangeSchema = Yup.object().shape({
  email: Yup.string()
    .email("Введите email адрес")
    .required("Это обязательное поле"),
  password: Yup.string("Введите строку").required("Это обязательное поле")
});

const PartnershipApplicationSchema = Yup.object().shape({
  fio: Yup.string("Введите строку").required("Это обязательное поле"),
  name: Yup.string("Введите строку").required("Это обязательное поле"),
  phone: Yup.string("Введите строку")
    .test("phone", "Неверно введен номер телефона", function(val) {
      if (val.length === 11) {
        return val;
      }
    })
    .required("Это обязательное поле"),
  email: Yup.string()
    .email("Введите email адрес")
    .required("Это обязательное поле"),
  district: Yup.string("Введите строку").required("Это обязательное поле"),
  password: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .min(8, "Пароль должен содержать не менее 8 символов"),
  password2: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .min(8, "Пароль должен содержать не менее 8 символов")
    .test("match", "Пароли не совпадают", function(val) {
      if (val && this.parent.password) {
        return val === this.parent.password;
      }
    }),
  is_policies: Yup.boolean("Введите строку")
    .required("Это обязательное поле")
    .oneOf([true], "Необходимо принять условия")
});

const RegistrationStep1Schema = Yup.object().shape({
  first_name: Yup.string("Введите строку").required("Это обязательное поле"),
  last_name: Yup.string("Введите строку").required("Это обязательное поле"),
  number: Yup.string("Введите строку").required("Это обязательное поле"),
  is_policies: Yup.boolean("Введите строку")
    .required("Это обязательное поле")
    .oneOf([true], "Вы должны согласиться с условиями")
});

const CheckoutSchema = Yup.object().shape({
  first_name: Yup.string("Введите строку").required("Это обязательное поле"),
  last_name: Yup.string("Введите строку").required("Это обязательное поле"),
  email: Yup.string("Введите строку")
    .email("Введите email адрес")
    .required("Это обязательное поле"),
  phone: Yup.string("Введите строку").required("Это обязательное поле"),
  address: Yup.string("Введите строку").required("Это обязательное поле")
});

const ProductSchema = Yup.object().shape({
  title: Yup.string("Заголовок должен быть строкой")
    .required("Это обязательное поле")
    .max(150, "Заголовок не должен быть более 150 символов"),
  price: Yup.number("Цена должна быть числом")
    .required("Это обязательное поле")
    .test("price", "Цена должна быть больше 0", val => Number(val) > 0)
    .max(99999999.99, "Цена должна быть меньше 1 000 000 000"),
  unit: Yup.string("Введите строку")
    .max(25, "Введите не более 25 символов")
    .required("Это обязательное поле"),
  content: Yup.string("Введите строку").required("Это обязательное поле"),
  shop: Yup.object(),
  group: Yup.object()
    .required("Это обязательное поле")
    .nullable(),
  type: Yup.object()
    .required("Это обязательное поле")
    .nullable()
});

const ServiceSchema = Yup.object().shape({
  title: Yup.string("Заголовок должен быть строкой")
    .required("Это обязательное поле")
    .max(150, "Заголовок не должен быть более 150 символов"),
  price: Yup.number("Цена должна быть числом")
    .required("Это обязательное поле")
    .test("price", "Цена должна быть больше 0", val => Number(val) > 0)
    .max(99999999.99, "Цена должна быть меньше 1 000 000 000"),
  unit: Yup.string("Введите строку")
    .max(25, "Введите не более 25 символов")
    .required("Это обязательное поле"),
  content: Yup.string("Введите строку").required("Это обязательное поле"),
  shop: Yup.object().shape({
    value: Yup.string().required("Это обязательное поле")
  })
});

const AddProductSchema = Yup.object().shape({
  title: Yup.string("Заголовок должен быть строкой")
    .required("Это обязательное поле")
    .test(
      "title",
      "Заголовок не должен быть более 150 символов",
      val => val.length <= 150
    ),
  price: Yup.number("Цена должна быть числом")
    .required("Это обязательное поле")
    .test("price", "Цена должна быть больше 0", val => Number(val) > 0)
    .max(99999999.99, "Цена должна быть меньше 1 000 000 000"),
  shop: Yup.string("Введите строку").required("Это обязательное поле")
});

const SetPasswordSchema = Yup.object().shape({
  old_password: Yup.string("Введите строку").required("Это обязательное поле"),
  new_password: Yup.string("Введите строку").required("Это обязательное поле"),
  new_password2: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .oneOf([Yup.ref("new_password"), null], "Пароли должны совпадать")
});

const AccountDeleteSchema = Yup.object().shape({
  password: Yup.string("Введите строку").required("Это обязательное поле"),
  password2: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .oneOf([Yup.ref("password"), null], "Пароли должны совпадать")
});

const CartSchema = Yup.object().shape({
  phone: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .matches(phoneRegExp, "Введите корректный номер телефона")
});

const LoanApplicationPartnerSchema = Yup.object().shape({
  series_and_number: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  by_whom_and_when_issued: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  actual_address_residence: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  account_number_transferring: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  name_bank_account_opened: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  is_policies: Yup.boolean()
    .required("Это обязательное поле")
    .oneOf([true], "Вы должны согласиться с условиями")
});

const LoanApplicationMember1Schema = Yup.object().shape({
  registration_number_cpc: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  account_number_transferring: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  name_bank_account_opened: Yup.string("Введите строку").required(
    "Это обязательное поле"
  ),
  is_policies: Yup.boolean()
    .required("Это обязательное поле")
    .oneOf([true], "Вы должны согласиться с условиями")
});

const LoanApplicationMember2Schema = Yup.object().shape({
  passport_rf: Yup.mixed().required("Это обязательное поле"),
  form_two_ndfl: Yup.mixed().required("Это обязательное поле"),
  recommendation_organization_union: Yup.mixed().required(
    "Это обязательное поле"
  ),
  petition_organization_union: Yup.mixed().required("Это обязательное поле"),
  inn: Yup.mixed().required("Это обязательное поле")
});

const RestorePasswordSetSchema = Yup.object().shape({
  password: Yup.string("Введите строку").required("Это обязательное поле"),
  password2: Yup.string("Введите строку")
    .required("Это обязательное поле")
    .oneOf([Yup.ref("password"), null], "Пароли должны совпадать")
});

const trimValue = value => value && value.trim() !== "";

const CreateShopSchema = Yup.object().shape({
  address: Yup.string(stringHint)
    .test("address", requiredHint, trimValue)
    .required(requiredHint),
  phone: Yup.string(stringHint)
    .matches(phoneRegExp, phoneErrorHint)
    .test("phone", phoneErrorHint, val => val && val.length === 11)
    .required(requiredHint),
  email: Yup.string()
    .email("Введите email адрес")
    .required(requiredHint),
  title: Yup.string(stringHint)
    .test("title", requiredHint, trimValue)
    .required(requiredHint),
  service: Yup.object()
    .required(requiredHint)
    .nullable(),
  /*category: Yup.object()
    .required(requiredHint)
    .nullable(), */
  district: Yup.string(stringHint).required(requiredHint),
  content: Yup.string(stringHint)
    .test("content", "Введите не более 10000 символов", value => {
      const length = getLengthCKEditorData(value);
      return length <= maxLengthContent;
    })
    .notRequired(),
  offer_description: Yup.string(stringHint)
    .test("offer_description", "Введите не более 500 символов", value => {
      console.log(value, "val");
      if (value) {
        const length = value.length;
        return length <= maxLengthDescr;
      } else {
        return Yup.string(stringHint).notRequired();
      }
    })
    .notRequired(),
  vk_link: Yup.string(stringHint)
    .url(urlErrorHint)
    .notRequired(),
  fb_link: Yup.string(stringHint)
    .url(urlErrorHint)
    .notRequired(),
  ok_link: Yup.string(stringHint)
    .url(urlErrorHint)
    .notRequired(),
  instagram_link: Yup.string(stringHint)
    .url(urlErrorHint)
    .notRequired(),
  partner_site: Yup.string(stringHint)
    .url(urlErrorHint)
    .notRequired()
});

const CreateReviewSchema = Yup.object().shape({
  star: Yup.number()
    .required("Это обязательное поле")
    .min(1, "Это обязательное поле"),
  text: Yup.string("Введите строку").required("Это обязательное поле"),
  form: Yup.mixed().notRequired()
});

const CreateReplySchema = Yup.object().shape({
  // review: Yup.number().notRequired(),
  // .required("Это обязательное поле"),
  text: Yup.string("Введите строку").required("Это обязательное поле"),
  form: Yup.mixed().notRequired()
});

export {
  AccountSchema,
  RegistrationStep1Schema,
  CheckoutSchema,
  ProductSchema,
  ServiceSchema,
  SetPasswordSchema,
  AccountDeleteSchema,
  PartnershipApplicationSchema,
  EmailChangeSchema,
  CartSchema,
  LoanApplicationPartnerSchema,
  LoanApplicationMember1Schema,
  LoanApplicationMember2Schema,
  PartnerAccountSchema,
  RestorePasswordSetSchema,
  CreateShopSchema,
  AddProductSchema,
  CreateReviewSchema,
  CreateReplySchema
};
