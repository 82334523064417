import React, { useEffect, useState } from "react";
import CKEditor from "ckeditor4-react";
import { useFormik } from "formik";
import { useStoreon } from "storeon/react";
import { useHistory } from "react-router-dom";
import { Button } from "@garpix/component-system";
import ShopAddressesList from "./ShopAddressesList";
import ImageListUpload from "../ImageListUpload";
import { PhoneInput } from "../../views/Fields";
import Errors from "../../views/Errors";

import {
  SelectDistrict,
  SelectServices,
  SelectServicesCategories
} from "../Select";
import { CreateShopSchema } from "../../schema";
import {
  CK_EDITOR_CONFIG,
  INITIAL_SHOP_VALUES,
  RESPONSE_STATUS
} from "../../const";
import { getLengthCKEditorData } from "../../utils";
import api from "../../api";

const CreateOrUpdateShop = ({ deleteShop = () => {} }) => {
  const { currentUser, dispatch, shop } = useStoreon("shop", "currentUser");
  const [nameShop, setNameShop] = useState(shop.title ? shop.title : "");
  const [shopSlug, setSlug] = useState(shop.slug ? shop.slug : null);
  const [descrLength, setDescrLength] = useState(0);
  const [contentLength, setContentLength] = useState(0);
  const [shopId, setShopId] = useState(shop.id);
  const [imagesSrc, setSrcs] = useState([]);

  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      dispatch("shop/toggle", { isCreateShop: false });
    });
  }, [history, dispatch]);

  const getNameShop = ({ name = "" }) => {
    setNameShop(name);
  };

  const submitFormSuccess = res => {
    const { setSubmitting, setFieldValue } = formik;
    dispatch("modal/toggle", { modal: "success-save" });
    setSlug(res.slug);
    setShopId(res.id);
    setFieldValue("image_list", res.shopimage_set);
    setSubmitting(false);
    dispatch("shop/toggle", { isCreateShop: true, shop: res });
  };

  const returnToList = () => dispatch("shop/toggle", { isCreateShop: false });

  const handleSubmitErrors = ({ error }) => {
    const { setErrors, setSubmitting } = formik;
    setSubmitting(false);
    if (error.response) {
      const status = error.response.status;
      if (
        status === RESPONSE_STATUS.error404 ||
        status === RESPONSE_STATUS.error500
      ) {
        setErrors({
          submit: "На сервере произошла ошибка. Пожалуйста, попробуйте позже."
        });
      } else {
        setErrors(error.response.data);
      }
    }
  };

  const submitShop = params => {
    if (shopId) {
      api
        .updateProducerShop(shopId, params)
        .then(res => {
          submitFormSuccess(res);
        })
        .catch(error => {
          handleSubmitErrors({ error });
        });
    } else {
      api
        .createProducerShop(params)
        .then(res => {
          submitFormSuccess(res);
        })
        .catch(error => {
          handleSubmitErrors({ error });
        });
    }
  };

  const checkValue = val => {
    const value = val.value;
    if (!value || value === "") return null;
    else return val;
  };

  const onBeforeLoad = () => {
    const length = getLengthCKEditorData(shop.content);
    setContentLength(length);
  };

  const formik = useFormik({
    initialValues: {
      ...INITIAL_SHOP_VALUES,
      address: currentUser ? currentUser.producer.address : "",
      phone: currentUser ? currentUser.phone : "",
      email: currentUser ? currentUser.email : ""
    },
    validationSchema: CreateShopSchema,
    onSubmit: submitShop
  });

  useEffect(() => {
    if (shop) {
      formik.setValues({
        address: shop.address
          ? shop.address
          : currentUser
          ? currentUser.producer.address
          : "",
        phone: shop.phone ? shop.phone : currentUser ? currentUser.phone : "",
        email: shop.email ? shop.email : currentUser ? currentUser.email : "",
        partner_site: shop.partner_site ? shop.partner_site : "",
        district: shop.district_obj
          ? {
              value: shop.district_obj.id,
              label: shop.district_obj.title
            }
          : undefined,
        service: shop.service_obj
          ? {
              value: shop.service_obj.id,
              label: shop.service_obj.title
            }
          : undefined,
        title: shop.title ? shop.title : "",
        category: shop.category_obj
          ? {
              value: shop.category_obj.id,
              label: shop.category_obj.title
            }
          : undefined,
        content: shop.content ? shop.content : "",
        offer_description: shop.offer_description ? shop.offer_description : "",
        video_page: shop.video_page ? shop.video_page : "",
        image_list: [],
        shopimage_set: shop.shopimage_set ? shop.shopimage_set : [],
        vk_link: shop.vk_link ? shop.vk_link : "",
        fb_link: shop.fb_link ? shop.fb_link : "",
        ok_link: shop.ok_link ? shop.ok_link : "",
        instagram_link: shop.instagram_link ? shop.instagram_link : ""
      });
      const lengthDescr = shop.offer_description.length;
      setDescrLength(lengthDescr);
    }
  }, [shop]);

  return (
    <div className="create-shop">
      <div className="conteiner">
        <div className="create-shop__info">
          {formik ? (
            <>
              <div className="main-info-shop">
                <h3 className="main-info-shop__name">
                  Торговая площадка{" "}
                  {nameShop && nameShop !== "" ? (
                    <>
                      &laquo;
                      {shopSlug ? (
                        <a href={shopSlug}>{nameShop}</a>
                      ) : (
                        <>{nameShop}</>
                      )}
                      &raquo;
                    </>
                  ) : null}
                </h3>
              </div>
              <div className="create-shop__all-info">
                <form
                  encType="multipart/form-data"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <div className="description-shop">
                    <div className="form-row">
                      <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">Название</div>
                          <input
                            className="form-input"
                            type="text"
                            onChange={e => {
                              const value = e.target.value;
                              formik.handleChange(e);
                              getNameShop({ name: value });
                            }}
                            value={formik.values.title}
                            name={"title"}
                          />
                          {formik.errors.title && formik.touched.title ? (
                            <Errors errors={formik.errors.title} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-col_big">
                        <div className="form-group">
                          <div className="form__label">Юр. адрес</div>
                          <input
                            className="form-input"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                            name={"address"}
                          />
                          {formik.errors.address && formik.touched.address ? (
                            <Errors errors={formik.errors.address} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="profile-col6">
                        <div className="form__label">Район</div>
                        <SelectDistrict
                          value={formik.values.district}
                          name="district"
                          onChange={value => {
                            formik.setFieldValue("district", value);
                          }}
                        />
                        {formik.errors.district && formik.touched.district ? (
                          <Errors errors={formik.errors.district} />
                        ) : null}
                      </div>
                      <div className="profile-col6">
                        <div className="form-group">
                          <div className="form__label">Услуга</div>
                          <SelectServices
                            className="form-input"
                            onChange={val =>
                              formik.setFieldValue("service", checkValue(val))
                            }
                            value={formik.values.service}
                            name={"service"}
                          />
                          {formik.errors.service && formik.touched.service ? (
                            <Errors errors={formik.errors.service} />
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">Подкатегория</div>
                          <SelectServicesCategories
                            className="form-input"
                            onChange={val =>
                              formik.setFieldValue("category", checkValue(val))
                            }
                            value={formik.values.category}
                            name={"category"}
                          />
                          {formik.errors.category && formik.touched.category ? (
                            <Errors errors={formik.errors.category} />
                          ) : null}
                        </div>
                      </div> */}
                    </div>
                    <div className="form-row">
                      <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">Номер телефона</div>
                          <PhoneInput
                            className="form-input"
                            setFieldValue={formik.setFieldValue}
                            value={formik.values.phone}
                            name={"phone"}
                          />
                          {formik.errors.phone && formik.touched.phone ? (
                            <Errors errors={formik.errors.phone} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">E-mail</div>
                          <input
                            className="form-input"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name={"email"}
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <Errors errors={formik.errors.email} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">
                            Адрес сайта компании
                          </div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.partner_site}
                            name={"partner_site"}
                          />
                          {formik.errors.partner_site &&
                          formik.touched.partner_site ? (
                            <Errors errors={formik.errors.partner_site} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="profile-col6">
                        <div className="form-group">
                          <div className="form__label">
                            Ссылка на страницу ВКонтакте
                          </div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.vk_link}
                            name={"vk_link"}
                          />
                          {formik.errors.vk_link && formik.touched.vk_link ? (
                            <Errors errors={formik.errors.vk_link} />
                          ) : null}
                        </div>
                      </div>

                      <div className="profile-col6">
                        <div className="form-group">
                          <div className="form__label">
                            Ссылка на страницу Одноклассники
                          </div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.ok_link}
                            name={"ok_link"}
                          />
                          {formik.errors.ok_link && formik.touched.ok_link ? (
                            <Errors errors={formik.errors.ok_link} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="profile-col6">
                        <div className="form-group">
                          <div className="form__label">
                            Ссылка на страницу Facebook
                          </div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.fb_link}
                            name={"fb_link"}
                          />
                          {formik.errors.fb_link && formik.touched.fb_link ? (
                            <Errors errors={formik.errors.fb_link} />
                          ) : null}
                        </div>
                      </div>

                      <div className="profile-col6">
                        <div className="form-group">
                          <div className="form__label">
                            Ссылка на страницу Instagram
                          </div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.instagram_link}
                            name={"instagram_link"}
                          />
                          {formik.errors.instagram_link &&
                          formik.touched.instagram_link ? (
                            <Errors errors={formik.errors.instagram_link} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-col_big">
                        <div className="form-group">
                          <div className="form__label">Изображения</div>
                          <ImageListUpload
                            imagesSrc={imagesSrc}
                            value={formik.values.image_list}
                            name="image_list"
                            shopimage_set={formik.values.shopimage_set}
                            setFieldValue={formik.setFieldValue}
                          />
                          {formik.errors.image_list &&
                          formik.touched.image_list ? (
                            <Errors errors={formik.errors.image_list} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-col">
                        <div className="form-group">
                          <div className="form__label">Ссылка на видео</div>
                          <input
                            className="form-input"
                            type="url"
                            onChange={formik.handleChange}
                            value={formik.values.video_page}
                            name={"video_page"}
                          />
                          {formik.errors.video_page &&
                          formik.touched.video_page ? (
                            <Errors errors={formik.errors.video_page} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col-12">
                        <div className="form-group">
                          <div className="form__label">
                            Информация о торговой площадке
                          </div>
                          <div>
                            <textarea
                              className="form-textarea"
                              onChange={e => {
                                const value = e.target.value;
                                const length = value.length;
                                formik.handleChange(e);
                                setDescrLength(length);
                              }}
                              value={formik.values.offer_description}
                              name={"offer_description"}
                            />
                          </div>
                          <div className="form-hint-group">
                            {formik.errors.offer_description &&
                            formik.touched.offer_description ? (
                              <Errors
                                errors={formik.errors.offer_description}
                              />
                            ) : null}
                            <div className="form-hint">
                              Количество символов: {descrLength}/500
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col-12">
                        <div className="form-group">
                          <div className="form__label">
                            Описание торговой площадки
                          </div>
                          <CKEditor
                            data={shop.content}
                            onBeforeLoad={onBeforeLoad}
                            onChange={e => {
                              const text = e.editor.document["$"].body
                                ? e.editor.document["$"].body.innerText
                                : "";
                              const length =
                                text && text !== ""
                                  ? text
                                      .replace(/\s\s+/g, " ")
                                      .replace(/\n/g, "").length
                                  : 0;
                              setContentLength(length);
                              formik.setFieldValue(
                                "content",
                                e.editor.getData()
                              );
                            }}
                            config={CK_EDITOR_CONFIG}
                          />
                          <div className="form-hint-group">
                            {formik.errors.content ? (
                              <Errors errors={formik.errors.content} />
                            ) : null}
                            <div className="form-hint">
                              Количество символов: {contentLength}/10000
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {shop && shopId ? (
                      <ShopAddressesList shop={shopId} />
                    ) : null}
                  </div>
                  {formik.errors.submit ? (
                    <Errors errors={formik.errors.submit} />
                  ) : null}
                  <div className="addprod-btm addprod-btm--revers">
                    {shopId ? (
                      <Button
                        onClick={() => deleteShop(shopId)}
                        variant="link"
                        className="btn--link-red btn--delete"
                      >
                        Удалить торговую площадку
                      </Button>
                    ) : null}

                    <Button
                      onClick={returnToList}
                      variant="link"
                      className="btn--link btn"
                    >
                      К списку торговых площадок
                    </Button>

                    <Button
                      type={"submit"}
                      disabled={formik.isSubmitting}
                      className="addprod-save"
                    >
                      Сохранить
                    </Button>
                  </div>
                </form>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CreateOrUpdateShop;
