import { Button, Container } from "@garpix/component-system";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Paginate from "../../views/Paginate";
import './category.scss';
import { CategoryMenu } from "./CategoryMenu";
import { CategoryProductList } from "./CategoryProductList";
import Filters from "./Filters";
import { DiscountFilter } from "./DiscountFilter";

const CategoryTemplate = ({
    categories, count, loadData, filterParams, isProduct,
    filterParamsKey,  filterOptions, results, isNext,
    showMore, activePage }) => {
    return (
        <div className="catalog">
            <Container>
                {filterParamsKey <= 1 ? (
                    <h2 className="catalog-heading cat-heading">
                        Каталог {isProduct ? 'товаров' : 'услуг'}: <span>{count}</span>
                    </h2>
                ) : (
                    <h2 className="catalog-heading">
                        Результаты запроса: <span>{count}</span>
                    </h2>
                )}
                <Row>
                    <Col md={2}>
                        <CategoryMenu
                            categories={categories}
                            initParams={filterParams}
                            handleSubmit={loadData}
                        />
                        {isProduct &&
                            <div className="mb-2">
                                <DiscountFilter
                                    initParams={filterParams}
                                    label={'Со скидкой'}
                                    handleSubmit={loadData}
                                />
                            </div>
                        }

                    </Col>
                    <Col md={10}>
                        <div className="mb-3">
                            <Filters
                                initParams={filterParams}
                                handleSubmit={loadData}
                                options={filterOptions}
                            />
                        </div>
                        <div className="category-items">
                            <CategoryProductList data={results} md={3} />
                        </div>
                        <div className={"category-bottom"}>
                            {isNext ? (
                                <Button onClick={showMore} className="btn loadmore">
                                    <span>ПОКАЗАТЬ ЕЩЁ</span>
                                </Button>
                            ) : null}
                            <Paginate
                                count={count}
                                activePage={activePage}
                                params={filterParams}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export { CategoryTemplate };

