import React from "react";
import { Modal, Button } from "@garpix/component-system";
import { OrderokImage } from "../../images";

const Thank = ({ handleClose, text = "Ожидайте подтверждение учетной записи." }) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Заявка оформлена</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-center">
          <img className="modal-iconok" src={OrderokImage} alt="" />
          <div className="modal-text">
            {text}<br />
            Уведомление будет отправлено на указанный электронный адрес.
          </div>
          <div className="modal-end">
            <Button
              onClick={handleClose}
              className="modal-btn"
              data-dismiss="modal"
            >
              Хорошо
            </Button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default Thank;
