import React from 'react';
import { Link } from 'react-router-dom';
import './title_section.scss'
const TitleSection = ({ title, cat_url }) => {
    return (
        <>
            <div className="head-info">
                <h2 className="mb-0">{title}</h2>
                <Link className="head-info__more-link" to={cat_url}>Смотреть все</Link>
            </div>

        </>
    )
}

export { TitleSection }