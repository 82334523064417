import React from 'react';


const Cart = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#C4C8CB" fillOpacity="0.1"/>
      <path d="M24.8474 10.8612C24.7227 10.7116 24.5377 10.625 24.3434 10.625H8.91917L8.34824 8.48693C8.27212 8.1995 8.01225 8 7.71431 8H4.65624C4.294 8 4 8.294 4 8.65624C4 9.01848 4.294 9.31248 4.65624 9.31248H7.21032L7.77469 11.4269C7.776 11.4348 7.77338 11.4426 7.776 11.4518L9.87597 19.3004C9.87597 19.3018 9.87728 19.3018 9.87728 19.3031L10.5847 21.9503C10.6608 22.2378 10.9207 22.4373 11.2186 22.4373H21.7185C22.0807 22.4373 22.3747 22.1433 22.3747 21.781C22.3747 21.4188 22.0807 21.1248 21.7185 21.1248H11.7226L11.3538 19.7441L23.0638 19.1548C23.3669 19.139 23.6216 18.9172 23.6767 18.618L24.9892 11.3993C25.0246 11.2064 24.9721 11.0095 24.8474 10.8612ZM13.1873 16.5311C13.1873 16.8934 12.8934 17.1873 12.5311 17.1873C12.1689 17.1873 11.8749 16.8934 11.8749 16.5311V13.9062C11.8749 13.5439 12.1689 13.2499 12.5311 13.2499C12.8934 13.2499 13.1873 13.5439 13.1873 13.9062V16.5311ZM17.1248 16.5311C17.1248 16.8934 16.8308 17.1873 16.4685 17.1873C16.1063 17.1873 15.8123 16.8934 15.8123 16.5311V13.9062C15.8123 13.5439 16.1063 13.2499 16.4685 13.2499C16.8308 13.2499 17.1248 13.5439 17.1248 13.9062V16.5311ZM21.0622 16.5311C21.0622 16.8934 20.7682 17.1873 20.406 17.1873C20.0437 17.1873 19.7497 16.8934 19.7497 16.5311V13.9062C19.7497 13.5439 20.0437 13.2499 20.406 13.2499C20.7682 13.2499 21.0622 13.5439 21.0622 13.9062V16.5311Z" fill="#16324F"/>
      <path d="M12.531 27.6874C13.6183 27.6874 14.4997 26.806 14.4997 25.7187C14.4997 24.6314 13.6183 23.75 12.531 23.75C11.4437 23.75 10.5623 24.6314 10.5623 25.7187C10.5623 26.806 11.4437 27.6874 12.531 27.6874Z" fill="#16324F"/>
      <path d="M20.406 27.6874C21.4933 27.6874 22.3747 26.806 22.3747 25.7187C22.3747 24.6314 21.4933 23.75 20.406 23.75C19.3187 23.75 18.4373 24.6314 18.4373 25.7187C18.4373 26.806 19.3187 27.6874 20.406 27.6874Z" fill="#16324F"/>
    </svg>
  )
}

export default Cart;