import React from 'react';
import { Form, ClearField } from '@garpix/component-system';
import Errors from '../../views/Errors';

const FormPartner = ({
  isShow,
  setFieldValue,
  handleChange,
  values,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  policy_slug = ''
}) => {
  return (
    <div className="form-partner" style={{ display: isShow ? "block" : "none" }}>
      <div className="form-steps">
        <span>Шаг 1/2</span>
      </div>
      <div className="form">
        <div className="form-group">
          <ClearField
            label="Серия и № паспорта *"
            name="series_and_number"
            onChange={setFieldValue}
            value={values.series_and_number}
          />
          {errors.series_and_number && touched.series_and_number ? (
            <Errors errors={errors.series_and_number} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="Кем и когда выдан *"
            name="by_whom_and_when_issued"
            onChange={setFieldValue}
            value={values.by_whom_and_when_issued}
          />
          {errors.by_whom_and_when_issued && touched.by_whom_and_when_issued ? (
            <Errors errors={errors.by_whom_and_when_issued} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="Фактический адрес проживания *"
            name="actual_address_residence"
            onChange={setFieldValue}
            value={values.actual_address_residence}
          />
          {errors.actual_address_residence && touched.actual_address_residence ? (
            <Errors errors={errors.actual_address_residence} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="№ лицевого счёта для перечисления суммы займа *"
            name="account_number_transferring"
            onChange={setFieldValue}
            value={values.account_number_transferring}
          />
          {errors.account_number_transferring && touched.account_number_transferring ? (
            <Errors errors={errors.account_number_transferring} />
          ) : null}
        </div>
        <div className="form-group">
          <ClearField
            label="Наименование банка, в котором открыт счёт *"
            name="name_bank_account_opened"
            onChange={setFieldValue}
            value={values.name_bank_account_opened}
          />
          {errors.name_bank_account_opened && touched.name_bank_account_opened ? (
            <Errors errors={errors.name_bank_account_opened} />
          ) : null}
        </div>
        <div className="form-end">
          <div className="form-group">
            <div className="form-checkbox">
              <Form.Check
                custom
                inline
                type={'checkbox'}
                id={`is_policies`}
              >
                <Form.Check.Input
                  type={'checkbox'}
                  name="is_policies"
                  checked={values.is_policies}
                  onChange={handleChange}
                />
                <Form.Check.Label>
                  <span>
                    Согласен с условиями{" "}
                    <a target="_blank" rel="noopener noreferrer" href={policy_slug}>политики конфиденциальности</a>
                  </span>
                </Form.Check.Label>
              </Form.Check>
            </div>
            {errors.is_policies && touched.is_policies ? (
              <Errors errors={errors.is_policies} />
            ) : null}
          </div>
          <div className="form-group">
            <button onClick={handleSubmit} type="button" className="btn btn--full">Далее</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  FormPartner
}