import React, { useState } from "react";
import { Form } from "@garpix/component-system";
import { useStoreon } from "storeon/react";
import { Formik } from "formik";
import api from "../../api";
import { FormMember } from "./FormMember";
import { FormPartner } from "./FormPartner";
import { FormStepTwo } from "./FormStepTwo";
import {
  LoanApplicationPartnerSchema,
  LoanApplicationMember1Schema,
  LoanApplicationMember2Schema,
} from "../../schema";
import { Authorization } from "../Auth";

const FORMS = {
  member: "member",
  partner: "partner",
  stepTwo: "stepTwo",
};

const LoanApplication = ({ termPriceCredit, callback }) => {
  const { currentUser, dispatch, page } = useStoreon("currentUser", "page");
  const [showForm, setShowForm] = useState(FORMS.member);

  const { site_configuration } = page.data.page;
  const { policy_slug } = site_configuration;

  const nextClick = () => setShowForm(FORMS.stepTwo);
  const backClick = () => setShowForm(FORMS.member);

  const handleSend = async (params, { setSubmitting, setErrors }) => {
    if ([FORMS.member, FORMS.partner].includes(showForm)) {
      setSubmitting(false);
      nextClick();
      return false;
    }
    if ([FORMS.stepTwo].includes(showForm)) {
      try {
        await api.createLoanApplication({
          ...params,
          ...termPriceCredit,
        });
        dispatch("modal/toggle", { modal: "loan-application"});
        callback();
      } catch (error) {
        setSubmitting(false);
        const { response } = error;
        if (response) {
          setErrors(response.data);
        }
      }
    }
  };

  let validationSchema;
  if (FORMS.partner === showForm) {
    validationSchema = LoanApplicationPartnerSchema;
  }

  if (FORMS.member === showForm) {
    validationSchema = LoanApplicationMember1Schema;
  }

  if (FORMS.stepTwo === showForm) {
    validationSchema = LoanApplicationMember2Schema;
  }

  if (currentUser === null) return null;
  const { status } = currentUser;
  if (status === false)
    return (
      <div>
        <p>Необходимо авторизоваться, чтобы подать заявку на займ</p>
        <Authorization />
      </div>
    );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        type: 0,
        series_and_number: "",
        by_whom_and_when_issued: "",
        actual_address_residence: "",
        account_number_transferring: "",
        name_bank_account_opened: "",
        registration_number_cpc: "",
        passport_rf: "",
        form_two_ndfl: "",
        recommendation_organization_union: "",
        petition_organization_union: "",
        inn: "",
        // union_ticket_contribution_mark: '',
        is_policies: true,
      }}
      enableReinitialize={true}
      onSubmit={handleSend}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <Form noValidate className="forms">
          <div className="form-step1">
            <div className="form-radios">
              <div className="form-radio">
                <Form.Check
                  custom
                  type={"radio"}
                  name="type"
                  id={`type-${FORMS.member}`}
                  label={`Являюсь членом кредитного кооператива`}
                  checked={values.type === 0}
                  value={0}
                  onChange={(e) => {
                    resetForm()
                    setShowForm(FORMS.member);
                    setFieldValue("type", 0);
                  }}
                />
              </div>
              <div className="form-radio">
                <Form.Check
                  custom
                  type={"radio"}
                  name="type"
                  id={`type-${FORMS.partner}`}
                  label={`НЕ являюсь членом кредитного кооператива`}
                  checked={showForm === FORMS.partner}
                  checked={values.type === 1}
                  onChange={(e) => {
                    resetForm()
                    setShowForm(FORMS.partner);
                    setFieldValue("type", 1);
                  }}
                />
              </div>
            </div>
            {FORMS.member === showForm ? (
              <FormMember
                currentUser={currentUser}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                isShow={FORMS.member === showForm}
                handleSubmit={handleSubmit}
                policy_slug={policy_slug}
              />
            ) : null}
            {FORMS.partner === showForm ? (
              <FormPartner
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                isShow={FORMS.partner === showForm}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                policy_slug={policy_slug}
              />
            ) : null}
          </div>
          {FORMS.stepTwo === showForm ? (
            <FormStepTwo
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              isShow={FORMS.stepTwo === showForm}
              backClick={backClick}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default LoanApplication;
