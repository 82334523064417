import React from 'react';
import Layout from '../views';
import ContainerFull from "../views/ContainerFull";
import { ConfirmEmail } from '../components/Auth/ConfirmEmail';


const ConfirmEmailPage = ({...props}) => {
  return (
    <Layout {...props}>
      <ContainerFull>
        <div className="blog-details">
          <div className="blog-content">
            <ConfirmEmail />
          </div>
        </div>
      </ContainerFull>
    </Layout>
  )
}

export default ConfirmEmailPage
