import React from 'react';
import { useStoreon } from 'storeon/react'
import { Modal } from '@garpix/component-system';
import { preventDefault } from '../../utils';
import { Authorization } from '../Auth';


const LoginModal = ({ isCloseModal }) => {
  const { dispatch } = useStoreon('showModal');

  const openRegistration = () => preventDefault(dispatch('modal/toggle', { modal: 'registration' }))

  return (
    <>
      <Modal.Header closeButton={!isCloseModal}>
        <Modal.Title>Вход</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Authorization />
        <Modal.Footer>
          <p><a href="#" onClick={openRegistration}>Регистрация</a></p>
        </Modal.Footer>
      </Modal.Body>
    </>
  )
}

export default LoginModal
