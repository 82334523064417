import React from 'react';
import { Modal, Button } from '@garpix/component-system';
import {ConfirmImage} from "../../images";

const SuccessSave = ({
 handleClose
}) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Сохранение</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-center">
          <img style={{ width: '75px' }} className="modal-iconok" src={ConfirmImage} alt="" />
          <div className="modal-text">
            Данные успешно сохранены
          </div>
          <div className="modal-end">
            <Button onClick={handleClose} className="modal-btn" data-dismiss="modal">
              Хорошо
            </Button>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

export default SuccessSave;