import React from "react";
import Layout from '../views';
import PartnerBlock from '../views/PartnerBlock';
import PartnersBtns from '../components/PartnersBtns';
import { getPlural, numberFormat } from '../utils';
import partnerImg from '../images/partner.png';
import logoImg from '../images/logo.png';

const LPParther = (props) => {
  const {
    contract,
    district,
    partner,
    proposals
  } = props;

  return (
    <Layout className='main--bg' {...props}>
      <section className="partner">
        <div className="container">
          <h2 className="partner-heading">Добро пожаловать на профсоюзную корпоративную экосистему «ПРОФМАРКЕТ»!</h2>
          <PartnerBlock
            partnerImg={partnerImg}
            blockHeading='О нас'
            logo={logoImg}>
            <div className="partner-block__text">
              <h3>Изучив официальный сайт нашей организации, вы наверняка успели получить достаточное количество информации о том, что представляет из себя <a href='https://xn--80aabfwcj3bcabdfofl4c2l4a.xn--p1ai/' target='_blank' rel="noopener noreferrer"><span>Краснодарская краевая организация Общероссийского Профсоюза образования</span></a></h3>
              <p>Это, прежде всего, реальные люди, жители нашего края - более 180 тысяч человек: учителя, воспитатели, преподаватели, административный и обслуживающий персонал, студенты вузов, колледжей и техникумов, объединённых общими профессиональными принципами, целями и задачами.</p>
            </div>
            <div className="partner-nums">
              <div className="partner-nums__item">
                <div className="partner-nums__count">{numberFormat(proposals)}</div>
                <div className="partner-nums__label">
                  {getPlural(proposals, 'предложение', 'предложения', 'предложений')}
                </div>
              </div>
              <div className="partner-nums__item">
                <div className="partner-nums__count">{numberFormat(partner)}</div>
                <div className="partner-nums__label">
                  {getPlural(proposals, 'партнёр уже с нами', 'партнёра уже с нами', 'партнёров уже с нами')}
                </div>
              </div>
              <div className="partner-nums__item">
                <div className="partner-nums__count">{numberFormat(district)}</div>
                <div className="partner-nums__label">
                  {getPlural(proposals, 'муниципальное образований', 'муниципальных образований', 'муниципальных образований')}
                </div>
              </div>
            </div>
            <PartnersBtns contract={contract} />
          </PartnerBlock>
          <PartnerBlock blockHeading='О проекте'>
            <div className="partner-block__text">
              <p>Интернет платформa «‎Профмаркет» — это не просто онлайн - сервисы, товары и услуги — это фактически социальный цифровой город, где для его жителей (работников образования) созданы все условия для жизни, а главное – экономии времени и денег.</p>
              <hr />
              <p className="blue">В «Профмаркете» действуют скидки, бонусы, льготное кредитование и другие преференции, поэтому вход, регистрация и деятельность для наших партнеров в этом «городе» осуществляется на безвозмездной основе.</p>
              <h3>Помощь нашим партнерам по широкому спектру направлений:</h3>
              <p>Также наши партнеры сами имеют право пользоваться всеми льготами и гарантиями, будь то товары и услуги других партнеров, или наравне с членами нашего профсоюза — эксклюзивное страхование имущества, жизни и здоровья, льготное лечение, оздоровление и программы по улучшению жилищных условий.</p>
              <h3>Уникальность</h3>
              <p>Уникальность нашей экосистемы еще и в том, что она рассчитана оказать цифровую поддержку малому и среднему бизнесу, фермерским хозяйствам на местах, в районах Краснодарского края путем предоставления интернет площадки с уже зарегистрированными в ней многотысячным количеством покупателей, это значительно облегчает работу и позволяет существенно сэкономить время.</p>
              <hr />
              <blockquote>Мы стремимся к долгосрочным взаимоотношениям с надежными партнерами. Вместе мы можем помочь друг другу реализовать много интересных проектов на благо системы образования Кубани!</blockquote>
            </div>
            <PartnersBtns contract={contract} />
          </PartnerBlock>
          <PartnerBlock blockHeading='Команда'>
            <div className="partner-block__text">
              <h3>Мы, руководство и аппарат краевой организации профсоюза – команда профессионалов, нацеленных на постоянное развитие организации, поиск новых форм и способов облегчить жизнь членов профсоюза, поднять профессиональный и социальный статус педагогов.</h3>
              <p>Наша команда выделяется инновационностью мышления, что позволяет нам быть лидерами в общероссийском конкурсе на лучшую организацию высокой социальной эффективности на протяжении последних четырех лет. Кредо нашей команды — быть на шаг впереди во всем, что мы делаем! И конечно же для нас важна экономическая сторона жизни, увеличение количества и качества мер дополнительной социальной поддержки работников образования, создание условий, различных социальных проектов для экономии тех небольших средств, которые они получают за свой нелегкий труд.</p>
            </div>
            <PartnersBtns contract={contract} />
          </PartnerBlock>
        </div>
      </section>
    </Layout>
  )
}

export default LPParther;
