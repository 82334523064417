import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from '@garpix/component-system';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";


export default ({
  children,
  title = 'Main title',
  description = '',
  header_menu,
  footer_menu,
  site_configuration,
  className = "",
  breadcrumbs = []
}) => {
  return (
    <>
      <Header menu={header_menu} site_configuration={site_configuration} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <main className={`main ${className}`}>
        {breadcrumbs.length > 0 ? (
          <section className="breadcrumbs">
            <Container>
              {breadcrumbs.map((item, index) => {
                if(index + 1 === breadcrumbs.length) {
                  return <span key={item.slug} className="breadcrumbs-link">
                    {item.title}
                  </span>
                }
                return (
                  <Link key={item.slug} to={item.slug} className="breadcrumbs-link">{item.title}</Link>
                )
              })}
            </Container>
          </section>
        ) : null}
        {children}
      </main>
      <Footer site_configuration={site_configuration} menu={footer_menu} />
    </>
  );
};
