import pageSerializer from './page';
import breadcrumbsSerializer from "./breadcrumbs";

const offerPartnerSerializer = item => ({
  id: item.id,
  target_blank: true,
  slug: item.url,
  title: item.url_title,
  description: item.description,
  value: `${item.discount ? item.discount : 0}%`
})

const partnerSerializer = page => {
  const data = pageSerializer(page);
  const { offers, page_info } = data;
  return {
    ...data,
    offers: offers.map(item => ({
      title: item.title,
      id: item.id,
      slug: item.slug,
      relatedData: {
        count: item.offer_set.count,
        next: item.offer_set.next,
        results: item.offer_set.results.map(offerPartnerSerializer)
      }
    })),
    breadcrumbs: breadcrumbsSerializer(page_info)
  }
}

export default partnerSerializer;

export {
  offerPartnerSerializer
}