import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import { dataStates } from '@garpix/fetcher';
import { Spinner } from '@garpix/component-system'
import api from '../../api';
import qs from 'query-string';


const ConfirmEmail = withRouter((props) => {
  const [ status, setStatus ] = useState(dataStates.loading);

  useEffect(() => {
    const { location } = props;
    const { search } = location;
    const { key } = qs.parse(search);
    api.sendConfirm({ key })
      .then(data => {
        if(data.status) {
          setStatus(dataStates.loaded)
        } else {
          setStatus(dataStates.failed)
        }
      }).catch(error => {
        setStatus(dataStates.failed)
      })

  }, [])
  return (
    <div>
      {status === dataStates.loading ? (
        <Spinner animation="border" role="status" />
      ) : null}
      <h1>
        {status === dataStates.loaded ? 'Email успешно подтвержден!' : ''}
        {status === dataStates.failed ? 'Ссылка не действительна' : ''}
      </h1>
    </div>
  )
})

export {
  ConfirmEmail
}
