import { legalFormOptions } from "../const";

const legalFormOptionsObj = legalFormOptions.reduce(
  (obj, item) => Object.assign(obj, { [item.value]: item.label }),
  {}
);

const preventDefault = cb => {
  return (event, ...others) => {
    event.preventDefault();
    cb(event, ...others);
  };
};

function isObject(val) {
  return typeof val === "object";
}

function currencyFormat(
  number,
  { maximumFractionDigits = 2, minimumFractionDigits = 0 } = {}
) {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits
  }).format(number);
}

function numberFormat(number) {
  const num = Number(number);
  return new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    useGrouping: true
  }).format(num);
}

function usernameFormat(user) {
  const { first_name, last_name, middle_name } = user;
  const fn = first_name ? first_name[0] : "";
  const mn = middle_name ? middle_name[0] : "";
  return `${last_name} ${fn}.${mn}.`;
}

function getLegalFormName(legal_form, abbreviation = false) {
  if (!legal_form) return "";
  if (!legalFormOptionsObj[legal_form]) return "";
  const title = legalFormOptionsObj[legal_form];
  if (abbreviation) {
    const upTitle = title.toUpperCase();
    const titleList = upTitle.split(" ");
    if (titleList.length === 1) {
      return upTitle;
    }
    return `${titleList[0][0]}${titleList[1][0]}`;
  }
  return title;
}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  try {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (e) {
    return null;
  }
}

function removeCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

function getPlural(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

function deleteArrayItem(arr = [], idx) {
  const rightArr = arr.slice(0, idx);
  const leftArr = arr.slice(idx + 1);
  const newArr = rightArr.concat(leftArr);
  return newArr;
}

const normalize = arr =>
  arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

const parserHtmlToString = (data = "") => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  const text = doc.body.innerText ? doc.body.innerText : "";
  return text;
};

const getLengthCKEditorData = (data = "") => {
  const text = parserHtmlToString(data);
  const length = text && text !== "" ? text.replace(/\s\s+/g, " ").length : 0;

  return length;
};

const executeScroll = ref => {
  if (ref) {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }
};

export {
  setCookie,
  getCookie,
  removeCookie,
  preventDefault,
  isObject,
  currencyFormat,
  numberFormat,
  usernameFormat,
  getLegalFormName,
  getPlural,
  normalize,
  deleteArrayItem,
  getLengthCKEditorData,
  parserHtmlToString,
  executeScroll
};
