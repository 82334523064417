import React, { useState, createRef, useEffect } from "react";
import {
  YMaps,
  Map,
  ZoomControl,
  TrafficControl,
  GeolocationControl,
  Placemark
} from "react-yandex-maps";
import api from "../../api";

const style = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const activeColor = "#F61459";
const placemarkColor = "#3873EE";

const MapFilter = ({ district = -1, handleFilter }) => {
  const [isShow, setShow] = useState(false);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    api.getDistricts().then(data => {
      const { results } = data;
      setDistricts(results);
    });
  }, []);

  const mapRef = createRef(null);
  const toggleShow = () => {
    setShow(!isShow);
    const container = mapRef.current.container;
    setTimeout(() => {
      if (container) container.fitToViewport();
    }, 300);
  };

  const handleClickPlacemark = item => {
    handleFilter(1, {
      producer__district: item.id,
      producer__district_label: item.title
    });
  };

  return (
    <div className={`catalog-map ${isShow ? "catalog-map--opened" : ""}`}>
      <div className="catalog-map__map">
        <div className="catalog-map__wrapper">
          <YMaps>
            <Map
              instanceRef={mapRef}
              style={style}
              defaultState={{
                center: [45.544904, 39.610422],
                zoom: 6,
                controls: []
              }}
            >
              <GeolocationControl options={{ float: "left" }} />
              <ZoomControl options={{ float: "right" }} />
              <TrafficControl options={{ float: "right" }} />
              {districts.map(item => (
                <Placemark
                  onClick={() => handleClickPlacemark(item)}
                  key={item.id}
                  options={{
                    iconColor:
                      item.id === Number(district)
                        ? activeColor
                        : placemarkColor
                  }}
                  geometry={[item.lat, item.lon]}
                />
              ))}
            </Map>
          </YMaps>
        </div>
      </div>
      <div type="button" onClick={toggleShow} className="catalog-map__overlay">
        <div className="catalog-map__intro">
          Выберите место на карте для поиска товаров или воспользуйтесь
          фильтрами
        </div>
      </div>
      <button
        type="button"
        onClick={toggleShow}
        className="catalog-map__show"
      ></button>
    </div>
  );
};

export default MapFilter;
